/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
:root {
  --blue: #484896;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #962A30;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #4E6E32;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #F3F2F3;
  --gray-dark: #343a40;
  --black: #000000;
  --green-light: #C7D1BD;
  --green-dark: #3E5B22;
  --primary: #484896;
  --secondary: #6c757d;
  --success: #4E6E32;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #962A30;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.8;
  color: #212529;
  text-align: left;
  background-color: #ffffff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #484896;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #2f2f62;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #ffffff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 721px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 1200px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1201px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #ffffff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #cccce2; }

.table-hover .table-primary:hover {
  background-color: #bcbcd9; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #bcbcd9; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #cdd6c6; }

.table-hover .table-success:hover {
  background-color: #c0cbb7; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #c0cbb7; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #e2c3c5; }

.table-hover .table-danger:hover {
  background-color: #dab2b4; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #dab2b4; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #ffffff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #ffffff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.8;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #ffffff;
    border-color: #9393cb;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(72, 72, 150, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.55rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #ffffff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.8; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.8;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #4E6E32; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(78, 110, 50, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #4E6E32; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #4E6E32;
    box-shadow: 0 0 0 0.2rem rgba(78, 110, 50, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #4E6E32; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #4E6E32; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #8db966; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #679142; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(78, 110, 50, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #4E6E32; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(78, 110, 50, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #962A30; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(150, 42, 48, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #962A30; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #962A30;
    box-shadow: 0 0 0 0.2rem rgba(150, 42, 48, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #962A30; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #962A30; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #d56a70; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #be353d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(150, 42, 48, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #962A30; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(150, 42, 48, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.3;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(72, 72, 150, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #ffffff;
  background-color: #484896;
  border-color: #484896; }
  .btn-primary:hover {
    color: #ffffff;
    background-color: #3c3c7c;
    border-color: #373774; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 72, 150, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #ffffff;
    background-color: #484896;
    border-color: #484896; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #373774;
    border-color: #33336b; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 72, 150, 0.5); }

.btn-secondary {
  color: #ffffff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #ffffff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #ffffff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-success {
  color: #ffffff;
  background-color: #4E6E32;
  border-color: #4E6E32; }
  .btn-success:hover {
    color: #ffffff;
    background-color: #3b5426;
    border-color: #354b22; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(78, 110, 50, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #ffffff;
    background-color: #4E6E32;
    border-color: #4E6E32; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #ffffff;
    background-color: #354b22;
    border-color: #2f421e; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(78, 110, 50, 0.5); }

.btn-info {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #ffffff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #ffffff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #ffffff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-danger {
  color: #ffffff;
  background-color: #962A30;
  border-color: #962A30; }
  .btn-danger:hover {
    color: #ffffff;
    background-color: #782226;
    border-color: #6e1f23; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(150, 42, 48, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #ffffff;
    background-color: #962A30;
    border-color: #962A30; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #6e1f23;
    border-color: #641c20; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(150, 42, 48, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-dark {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #ffffff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #ffffff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-primary {
  color: #484896;
  background-color: transparent;
  background-image: none;
  border-color: #484896; }
  .btn-outline-primary:hover {
    color: #ffffff;
    background-color: #484896;
    border-color: #484896; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 72, 150, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #484896;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #484896;
    border-color: #484896; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 72, 150, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #ffffff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #4E6E32;
  background-color: transparent;
  background-image: none;
  border-color: #4E6E32; }
  .btn-outline-success:hover {
    color: #ffffff;
    background-color: #4E6E32;
    border-color: #4E6E32; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(78, 110, 50, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #4E6E32;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #ffffff;
    background-color: #4E6E32;
    border-color: #4E6E32; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(78, 110, 50, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #ffffff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #ffffff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #962A30;
  background-color: transparent;
  background-image: none;
  border-color: #962A30; }
  .btn-outline-danger:hover {
    color: #ffffff;
    background-color: #962A30;
    border-color: #962A30; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(150, 42, 48, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #962A30;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #962A30;
    border-color: #962A30; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(150, 42, 48, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #ffffff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-blue {
  color: #ffffff;
  background-color: #484896;
  border-color: #484896; }
  .btn-blue:hover {
    color: #ffffff;
    background-color: #3c3c7c;
    border-color: #373774; }
  .btn-blue:focus, .btn-blue.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 72, 150, 0.5); }
  .btn-blue.disabled, .btn-blue:disabled {
    color: #ffffff;
    background-color: #484896;
    border-color: #484896; }
  .btn-blue:not(:disabled):not(.disabled):active, .btn-blue:not(:disabled):not(.disabled).active,
  .show > .btn-blue.dropdown-toggle {
    color: #ffffff;
    background-color: #373774;
    border-color: #33336b; }
    .btn-blue:not(:disabled):not(.disabled):active:focus, .btn-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 72, 150, 0.5); }

.btn-indigo {
  color: #ffffff;
  background-color: #6610f2;
  border-color: #6610f2; }
  .btn-indigo:hover {
    color: #ffffff;
    background-color: #560bd0;
    border-color: #510bc4; }
  .btn-indigo:focus, .btn-indigo.focus {
    box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.5); }
  .btn-indigo.disabled, .btn-indigo:disabled {
    color: #ffffff;
    background-color: #6610f2;
    border-color: #6610f2; }
  .btn-indigo:not(:disabled):not(.disabled):active, .btn-indigo:not(:disabled):not(.disabled).active,
  .show > .btn-indigo.dropdown-toggle {
    color: #ffffff;
    background-color: #510bc4;
    border-color: #4c0ab8; }
    .btn-indigo:not(:disabled):not(.disabled):active:focus, .btn-indigo:not(:disabled):not(.disabled).active:focus,
    .show > .btn-indigo.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.5); }

.btn-purple {
  color: #ffffff;
  background-color: #6f42c1;
  border-color: #6f42c1; }
  .btn-purple:hover {
    color: #ffffff;
    background-color: #5e37a6;
    border-color: #59339d; }
  .btn-purple:focus, .btn-purple.focus {
    box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.5); }
  .btn-purple.disabled, .btn-purple:disabled {
    color: #ffffff;
    background-color: #6f42c1;
    border-color: #6f42c1; }
  .btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active,
  .show > .btn-purple.dropdown-toggle {
    color: #ffffff;
    background-color: #59339d;
    border-color: #533093; }
    .btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.5); }

.btn-pink {
  color: #ffffff;
  background-color: #e83e8c;
  border-color: #e83e8c; }
  .btn-pink:hover {
    color: #ffffff;
    background-color: #e41c78;
    border-color: #d91a72; }
  .btn-pink:focus, .btn-pink.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.5); }
  .btn-pink.disabled, .btn-pink:disabled {
    color: #ffffff;
    background-color: #e83e8c;
    border-color: #e83e8c; }
  .btn-pink:not(:disabled):not(.disabled):active, .btn-pink:not(:disabled):not(.disabled).active,
  .show > .btn-pink.dropdown-toggle {
    color: #ffffff;
    background-color: #d91a72;
    border-color: #ce196c; }
    .btn-pink:not(:disabled):not(.disabled):active:focus, .btn-pink:not(:disabled):not(.disabled).active:focus,
    .show > .btn-pink.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.5); }

.btn-red {
  color: #ffffff;
  background-color: #962A30;
  border-color: #962A30; }
  .btn-red:hover {
    color: #ffffff;
    background-color: #782226;
    border-color: #6e1f23; }
  .btn-red:focus, .btn-red.focus {
    box-shadow: 0 0 0 0.2rem rgba(150, 42, 48, 0.5); }
  .btn-red.disabled, .btn-red:disabled {
    color: #ffffff;
    background-color: #962A30;
    border-color: #962A30; }
  .btn-red:not(:disabled):not(.disabled):active, .btn-red:not(:disabled):not(.disabled).active,
  .show > .btn-red.dropdown-toggle {
    color: #ffffff;
    background-color: #6e1f23;
    border-color: #641c20; }
    .btn-red:not(:disabled):not(.disabled):active:focus, .btn-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-red.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(150, 42, 48, 0.5); }

.btn-orange {
  color: #212529;
  background-color: #fd7e14;
  border-color: #fd7e14; }
  .btn-orange:hover {
    color: #ffffff;
    background-color: #e96b02;
    border-color: #dc6502; }
  .btn-orange:focus, .btn-orange.focus {
    box-shadow: 0 0 0 0.2rem rgba(253, 126, 20, 0.5); }
  .btn-orange.disabled, .btn-orange:disabled {
    color: #212529;
    background-color: #fd7e14;
    border-color: #fd7e14; }
  .btn-orange:not(:disabled):not(.disabled):active, .btn-orange:not(:disabled):not(.disabled).active,
  .show > .btn-orange.dropdown-toggle {
    color: #ffffff;
    background-color: #dc6502;
    border-color: #cf5f02; }
    .btn-orange:not(:disabled):not(.disabled):active:focus, .btn-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-orange.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(253, 126, 20, 0.5); }

.btn-yellow {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-yellow:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-yellow:focus, .btn-yellow.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-yellow.disabled, .btn-yellow:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-yellow:not(:disabled):not(.disabled):active, .btn-yellow:not(:disabled):not(.disabled).active,
  .show > .btn-yellow.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-yellow:not(:disabled):not(.disabled):active:focus, .btn-yellow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-yellow.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-green {
  color: #ffffff;
  background-color: #4E6E32;
  border-color: #4E6E32; }
  .btn-green:hover {
    color: #ffffff;
    background-color: #3b5426;
    border-color: #354b22; }
  .btn-green:focus, .btn-green.focus {
    box-shadow: 0 0 0 0.2rem rgba(78, 110, 50, 0.5); }
  .btn-green.disabled, .btn-green:disabled {
    color: #ffffff;
    background-color: #4E6E32;
    border-color: #4E6E32; }
  .btn-green:not(:disabled):not(.disabled):active, .btn-green:not(:disabled):not(.disabled).active,
  .show > .btn-green.dropdown-toggle {
    color: #ffffff;
    background-color: #354b22;
    border-color: #2f421e; }
    .btn-green:not(:disabled):not(.disabled):active:focus, .btn-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(78, 110, 50, 0.5); }

.btn-teal {
  color: #ffffff;
  background-color: #20c997;
  border-color: #20c997; }
  .btn-teal:hover {
    color: #ffffff;
    background-color: #1ba87e;
    border-color: #199d76; }
  .btn-teal:focus, .btn-teal.focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 201, 151, 0.5); }
  .btn-teal.disabled, .btn-teal:disabled {
    color: #ffffff;
    background-color: #20c997;
    border-color: #20c997; }
  .btn-teal:not(:disabled):not(.disabled):active, .btn-teal:not(:disabled):not(.disabled).active,
  .show > .btn-teal.dropdown-toggle {
    color: #ffffff;
    background-color: #199d76;
    border-color: #17926e; }
    .btn-teal:not(:disabled):not(.disabled):active:focus, .btn-teal:not(:disabled):not(.disabled).active:focus,
    .show > .btn-teal.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(32, 201, 151, 0.5); }

.btn-cyan {
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-cyan:hover {
    color: #ffffff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-cyan:focus, .btn-cyan.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-cyan.disabled, .btn-cyan:disabled {
    color: #ffffff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-cyan:not(:disabled):not(.disabled):active, .btn-cyan:not(:disabled):not(.disabled).active,
  .show > .btn-cyan.dropdown-toggle {
    color: #ffffff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-cyan:not(:disabled):not(.disabled):active:focus, .btn-cyan:not(:disabled):not(.disabled).active:focus,
    .show > .btn-cyan.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-white {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff; }
  .btn-white:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-white:focus, .btn-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-white.disabled, .btn-white:disabled {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
  .show > .btn-white.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-gray {
  color: #212529;
  background-color: #F3F2F3;
  border-color: #F3F2F3; }
  .btn-gray:hover {
    color: #212529;
    background-color: #e1dee1;
    border-color: #dbd7db; }
  .btn-gray:focus, .btn-gray.focus {
    box-shadow: 0 0 0 0.2rem rgba(243, 242, 243, 0.5); }
  .btn-gray.disabled, .btn-gray:disabled {
    color: #212529;
    background-color: #F3F2F3;
    border-color: #F3F2F3; }
  .btn-gray:not(:disabled):not(.disabled):active, .btn-gray:not(:disabled):not(.disabled).active,
  .show > .btn-gray.dropdown-toggle {
    color: #212529;
    background-color: #dbd7db;
    border-color: #d4d1d4; }
    .btn-gray:not(:disabled):not(.disabled):active:focus, .btn-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(243, 242, 243, 0.5); }

.btn-gray-dark {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-gray-dark:hover {
    color: #ffffff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-gray-dark:focus, .btn-gray-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-gray-dark.disabled, .btn-gray-dark:disabled {
    color: #ffffff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-gray-dark:not(:disabled):not(.disabled):active, .btn-gray-dark:not(:disabled):not(.disabled).active,
  .show > .btn-gray-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-gray-dark:not(:disabled):not(.disabled):active:focus, .btn-gray-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-black {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000; }
  .btn-black:hover {
    color: #ffffff;
    background-color: black;
    border-color: black; }
  .btn-black:focus, .btn-black.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }
  .btn-black.disabled, .btn-black:disabled {
    color: #ffffff;
    background-color: #000000;
    border-color: #000000; }
  .btn-black:not(:disabled):not(.disabled):active, .btn-black:not(:disabled):not(.disabled).active,
  .show > .btn-black.dropdown-toggle {
    color: #ffffff;
    background-color: black;
    border-color: black; }
    .btn-black:not(:disabled):not(.disabled):active:focus, .btn-black:not(:disabled):not(.disabled).active:focus,
    .show > .btn-black.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }

.btn-green-light {
  color: #212529;
  background-color: #C7D1BD;
  border-color: #C7D1BD; }
  .btn-green-light:hover {
    color: #212529;
    background-color: #b4c1a6;
    border-color: #aebc9f; }
  .btn-green-light:focus, .btn-green-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(199, 209, 189, 0.5); }
  .btn-green-light.disabled, .btn-green-light:disabled {
    color: #212529;
    background-color: #C7D1BD;
    border-color: #C7D1BD; }
  .btn-green-light:not(:disabled):not(.disabled):active, .btn-green-light:not(:disabled):not(.disabled).active,
  .show > .btn-green-light.dropdown-toggle {
    color: #212529;
    background-color: #aebc9f;
    border-color: #a7b797; }
    .btn-green-light:not(:disabled):not(.disabled):active:focus, .btn-green-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-green-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(199, 209, 189, 0.5); }

.btn-green-dark {
  color: #ffffff;
  background-color: #3E5B22;
  border-color: #3E5B22; }
  .btn-green-dark:hover {
    color: #ffffff;
    background-color: #2b3f18;
    border-color: #253614; }
  .btn-green-dark:focus, .btn-green-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(62, 91, 34, 0.5); }
  .btn-green-dark.disabled, .btn-green-dark:disabled {
    color: #ffffff;
    background-color: #3E5B22;
    border-color: #3E5B22; }
  .btn-green-dark:not(:disabled):not(.disabled):active, .btn-green-dark:not(:disabled):not(.disabled).active,
  .show > .btn-green-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #253614;
    border-color: #1e2d11; }
    .btn-green-dark:not(:disabled):not(.disabled):active:focus, .btn-green-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-green-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(62, 91, 34, 0.5); }

.btn-outline-blue {
  color: #484896;
  background-color: transparent;
  background-image: none;
  border-color: #484896; }
  .btn-outline-blue:hover {
    color: #ffffff;
    background-color: #484896;
    border-color: #484896; }
  .btn-outline-blue:focus, .btn-outline-blue.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 72, 150, 0.5); }
  .btn-outline-blue.disabled, .btn-outline-blue:disabled {
    color: #484896;
    background-color: transparent; }
  .btn-outline-blue:not(:disabled):not(.disabled):active, .btn-outline-blue:not(:disabled):not(.disabled).active,
  .show > .btn-outline-blue.dropdown-toggle {
    color: #ffffff;
    background-color: #484896;
    border-color: #484896; }
    .btn-outline-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 72, 150, 0.5); }

.btn-outline-indigo {
  color: #6610f2;
  background-color: transparent;
  background-image: none;
  border-color: #6610f2; }
  .btn-outline-indigo:hover {
    color: #ffffff;
    background-color: #6610f2;
    border-color: #6610f2; }
  .btn-outline-indigo:focus, .btn-outline-indigo.focus {
    box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.5); }
  .btn-outline-indigo.disabled, .btn-outline-indigo:disabled {
    color: #6610f2;
    background-color: transparent; }
  .btn-outline-indigo:not(:disabled):not(.disabled):active, .btn-outline-indigo:not(:disabled):not(.disabled).active,
  .show > .btn-outline-indigo.dropdown-toggle {
    color: #ffffff;
    background-color: #6610f2;
    border-color: #6610f2; }
    .btn-outline-indigo:not(:disabled):not(.disabled):active:focus, .btn-outline-indigo:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-indigo.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.5); }

.btn-outline-purple {
  color: #6f42c1;
  background-color: transparent;
  background-image: none;
  border-color: #6f42c1; }
  .btn-outline-purple:hover {
    color: #ffffff;
    background-color: #6f42c1;
    border-color: #6f42c1; }
  .btn-outline-purple:focus, .btn-outline-purple.focus {
    box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.5); }
  .btn-outline-purple.disabled, .btn-outline-purple:disabled {
    color: #6f42c1;
    background-color: transparent; }
  .btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled).active,
  .show > .btn-outline-purple.dropdown-toggle {
    color: #ffffff;
    background-color: #6f42c1;
    border-color: #6f42c1; }
    .btn-outline-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.5); }

.btn-outline-pink {
  color: #e83e8c;
  background-color: transparent;
  background-image: none;
  border-color: #e83e8c; }
  .btn-outline-pink:hover {
    color: #ffffff;
    background-color: #e83e8c;
    border-color: #e83e8c; }
  .btn-outline-pink:focus, .btn-outline-pink.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.5); }
  .btn-outline-pink.disabled, .btn-outline-pink:disabled {
    color: #e83e8c;
    background-color: transparent; }
  .btn-outline-pink:not(:disabled):not(.disabled):active, .btn-outline-pink:not(:disabled):not(.disabled).active,
  .show > .btn-outline-pink.dropdown-toggle {
    color: #ffffff;
    background-color: #e83e8c;
    border-color: #e83e8c; }
    .btn-outline-pink:not(:disabled):not(.disabled):active:focus, .btn-outline-pink:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-pink.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.5); }

.btn-outline-red {
  color: #962A30;
  background-color: transparent;
  background-image: none;
  border-color: #962A30; }
  .btn-outline-red:hover {
    color: #ffffff;
    background-color: #962A30;
    border-color: #962A30; }
  .btn-outline-red:focus, .btn-outline-red.focus {
    box-shadow: 0 0 0 0.2rem rgba(150, 42, 48, 0.5); }
  .btn-outline-red.disabled, .btn-outline-red:disabled {
    color: #962A30;
    background-color: transparent; }
  .btn-outline-red:not(:disabled):not(.disabled):active, .btn-outline-red:not(:disabled):not(.disabled).active,
  .show > .btn-outline-red.dropdown-toggle {
    color: #ffffff;
    background-color: #962A30;
    border-color: #962A30; }
    .btn-outline-red:not(:disabled):not(.disabled):active:focus, .btn-outline-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-red.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(150, 42, 48, 0.5); }

.btn-outline-orange {
  color: #fd7e14;
  background-color: transparent;
  background-image: none;
  border-color: #fd7e14; }
  .btn-outline-orange:hover {
    color: #212529;
    background-color: #fd7e14;
    border-color: #fd7e14; }
  .btn-outline-orange:focus, .btn-outline-orange.focus {
    box-shadow: 0 0 0 0.2rem rgba(253, 126, 20, 0.5); }
  .btn-outline-orange.disabled, .btn-outline-orange:disabled {
    color: #fd7e14;
    background-color: transparent; }
  .btn-outline-orange:not(:disabled):not(.disabled):active, .btn-outline-orange:not(:disabled):not(.disabled).active,
  .show > .btn-outline-orange.dropdown-toggle {
    color: #212529;
    background-color: #fd7e14;
    border-color: #fd7e14; }
    .btn-outline-orange:not(:disabled):not(.disabled):active:focus, .btn-outline-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-orange.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(253, 126, 20, 0.5); }

.btn-outline-yellow {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-yellow:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-yellow:focus, .btn-outline-yellow.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-yellow.disabled, .btn-outline-yellow:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-yellow:not(:disabled):not(.disabled):active, .btn-outline-yellow:not(:disabled):not(.disabled).active,
  .show > .btn-outline-yellow.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-yellow:not(:disabled):not(.disabled):active:focus, .btn-outline-yellow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-yellow.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-green {
  color: #4E6E32;
  background-color: transparent;
  background-image: none;
  border-color: #4E6E32; }
  .btn-outline-green:hover {
    color: #ffffff;
    background-color: #4E6E32;
    border-color: #4E6E32; }
  .btn-outline-green:focus, .btn-outline-green.focus {
    box-shadow: 0 0 0 0.2rem rgba(78, 110, 50, 0.5); }
  .btn-outline-green.disabled, .btn-outline-green:disabled {
    color: #4E6E32;
    background-color: transparent; }
  .btn-outline-green:not(:disabled):not(.disabled):active, .btn-outline-green:not(:disabled):not(.disabled).active,
  .show > .btn-outline-green.dropdown-toggle {
    color: #ffffff;
    background-color: #4E6E32;
    border-color: #4E6E32; }
    .btn-outline-green:not(:disabled):not(.disabled):active:focus, .btn-outline-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(78, 110, 50, 0.5); }

.btn-outline-teal {
  color: #20c997;
  background-color: transparent;
  background-image: none;
  border-color: #20c997; }
  .btn-outline-teal:hover {
    color: #ffffff;
    background-color: #20c997;
    border-color: #20c997; }
  .btn-outline-teal:focus, .btn-outline-teal.focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 201, 151, 0.5); }
  .btn-outline-teal.disabled, .btn-outline-teal:disabled {
    color: #20c997;
    background-color: transparent; }
  .btn-outline-teal:not(:disabled):not(.disabled):active, .btn-outline-teal:not(:disabled):not(.disabled).active,
  .show > .btn-outline-teal.dropdown-toggle {
    color: #ffffff;
    background-color: #20c997;
    border-color: #20c997; }
    .btn-outline-teal:not(:disabled):not(.disabled):active:focus, .btn-outline-teal:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-teal.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(32, 201, 151, 0.5); }

.btn-outline-cyan {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-cyan:hover {
    color: #ffffff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-cyan:focus, .btn-outline-cyan.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-cyan.disabled, .btn-outline-cyan:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-cyan:not(:disabled):not(.disabled):active, .btn-outline-cyan:not(:disabled):not(.disabled).active,
  .show > .btn-outline-cyan.dropdown-toggle {
    color: #ffffff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-cyan:not(:disabled):not(.disabled):active:focus, .btn-outline-cyan:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-cyan.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-white {
  color: #ffffff;
  background-color: transparent;
  background-image: none;
  border-color: #ffffff; }
  .btn-outline-white:hover {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-outline-white:focus, .btn-outline-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-white.disabled, .btn-outline-white:disabled {
    color: #ffffff;
    background-color: transparent; }
  .btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-white.dropdown-toggle {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff; }
    .btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-outline-gray {
  color: #F3F2F3;
  background-color: transparent;
  background-image: none;
  border-color: #F3F2F3; }
  .btn-outline-gray:hover {
    color: #212529;
    background-color: #F3F2F3;
    border-color: #F3F2F3; }
  .btn-outline-gray:focus, .btn-outline-gray.focus {
    box-shadow: 0 0 0 0.2rem rgba(243, 242, 243, 0.5); }
  .btn-outline-gray.disabled, .btn-outline-gray:disabled {
    color: #F3F2F3;
    background-color: transparent; }
  .btn-outline-gray:not(:disabled):not(.disabled):active, .btn-outline-gray:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray.dropdown-toggle {
    color: #212529;
    background-color: #F3F2F3;
    border-color: #F3F2F3; }
    .btn-outline-gray:not(:disabled):not(.disabled):active:focus, .btn-outline-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(243, 242, 243, 0.5); }

.btn-outline-gray-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-gray-dark:hover {
    color: #ffffff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-gray-dark:focus, .btn-outline-gray-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-gray-dark.disabled, .btn-outline-gray-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-gray-dark:not(:disabled):not(.disabled):active, .btn-outline-gray-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-gray-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-black {
  color: #000000;
  background-color: transparent;
  background-image: none;
  border-color: #000000; }
  .btn-outline-black:hover {
    color: #ffffff;
    background-color: #000000;
    border-color: #000000; }
  .btn-outline-black:focus, .btn-outline-black.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }
  .btn-outline-black.disabled, .btn-outline-black:disabled {
    color: #000000;
    background-color: transparent; }
  .btn-outline-black:not(:disabled):not(.disabled):active, .btn-outline-black:not(:disabled):not(.disabled).active,
  .show > .btn-outline-black.dropdown-toggle {
    color: #ffffff;
    background-color: #000000;
    border-color: #000000; }
    .btn-outline-black:not(:disabled):not(.disabled):active:focus, .btn-outline-black:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-black.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }

.btn-outline-green-light {
  color: #C7D1BD;
  background-color: transparent;
  background-image: none;
  border-color: #C7D1BD; }
  .btn-outline-green-light:hover {
    color: #212529;
    background-color: #C7D1BD;
    border-color: #C7D1BD; }
  .btn-outline-green-light:focus, .btn-outline-green-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(199, 209, 189, 0.5); }
  .btn-outline-green-light.disabled, .btn-outline-green-light:disabled {
    color: #C7D1BD;
    background-color: transparent; }
  .btn-outline-green-light:not(:disabled):not(.disabled):active, .btn-outline-green-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-green-light.dropdown-toggle {
    color: #212529;
    background-color: #C7D1BD;
    border-color: #C7D1BD; }
    .btn-outline-green-light:not(:disabled):not(.disabled):active:focus, .btn-outline-green-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-green-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(199, 209, 189, 0.5); }

.btn-outline-green-dark {
  color: #3E5B22;
  background-color: transparent;
  background-image: none;
  border-color: #3E5B22; }
  .btn-outline-green-dark:hover {
    color: #ffffff;
    background-color: #3E5B22;
    border-color: #3E5B22; }
  .btn-outline-green-dark:focus, .btn-outline-green-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(62, 91, 34, 0.5); }
  .btn-outline-green-dark.disabled, .btn-outline-green-dark:disabled {
    color: #3E5B22;
    background-color: transparent; }
  .btn-outline-green-dark:not(:disabled):not(.disabled):active, .btn-outline-green-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-green-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #3E5B22;
    border-color: #3E5B22; }
    .btn-outline-green-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-green-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-green-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(62, 91, 34, 0.5); }

.btn-link {
  font-weight: 400;
  color: #484896;
  background-color: transparent; }
  .btn-link:hover {
    color: #2f2f62;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.3;
  border-radius: 0; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.3;
  border-radius: 0; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.dropup,
.dropdown {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #ffffff;
    text-decoration: none;
    background-color: #484896; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after {
    margin-left: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control:focus,
    .input-group > .custom-select:focus,
    .input-group > .custom-file:focus {
      z-index: 3; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::before {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label,
    .input-group > .custom-file:not(:first-child) .custom-file-label::before {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.8;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.8rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #ffffff;
    background-color: #484896; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(72, 72, 150, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #ffffff;
    background-color: #b5b5db; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.4rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: #dee2e6; }
  .custom-control-label::after {
    position: absolute;
    top: 0.4rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #484896; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #484896; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23ffffff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(72, 72, 150, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(72, 72, 150, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #484896; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23ffffff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(72, 72, 150, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.55rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.8;
  color: #495057;
  vertical-align: middle;
  background: #ffffff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #9393cb;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(147, 147, 203, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #ffffff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.55rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.55rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-control {
    border-color: #9393cb;
    box-shadow: 0 0 0 0.2rem rgba(72, 72, 150, 0.25); }
    .custom-file-input:focus ~ .custom-file-control::before {
      border-color: #9393cb; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.55rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.8;
  color: #495057;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2.55rem + 2px) - 1px * 2);
    padding: 0.375rem 0.75rem;
    line-height: 1.8;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #ffffff;
    border-color: #dee2e6 #dee2e6 #ffffff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #484896; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.275rem;
  padding-bottom: 0.275rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #ffffff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #ffffff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #ffffff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #ffffff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #F3F2F3;
  border-radius: 0; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid #F3F2F3; }
  .card-header:first-child {
    border-radius: 0 0 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid #F3F2F3; }
  .card-footer:last-child {
    border-radius: 0 0 0 0; }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: 0; }

.card-img-top {
  width: 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 0; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0;
            border-top-right-radius: 0; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #6c757d;
  content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #484896;
  background-color: #ffffff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    color: #2f2f62;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(72, 72, 150, 0.25); }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #ffffff;
  background-color: #484896;
  border-color: #484896; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #ffffff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #ffffff;
  background-color: #484896; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #373774; }

.badge-secondary {
  color: #ffffff;
  background-color: #6c757d; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #545b62; }

.badge-success {
  color: #ffffff;
  background-color: #4E6E32; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #354b22; }

.badge-info {
  color: #ffffff;
  background-color: #17a2b8; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #117a8b; }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #d39e00; }

.badge-danger {
  color: #ffffff;
  background-color: #962A30; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #6e1f23; }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #dae0e5; }

.badge-dark {
  color: #ffffff;
  background-color: #343a40; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #1d2124; }

.badge-blue {
  color: #ffffff;
  background-color: #484896; }
  .badge-blue[href]:hover, .badge-blue[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #373774; }

.badge-indigo {
  color: #ffffff;
  background-color: #6610f2; }
  .badge-indigo[href]:hover, .badge-indigo[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #510bc4; }

.badge-purple {
  color: #ffffff;
  background-color: #6f42c1; }
  .badge-purple[href]:hover, .badge-purple[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #59339d; }

.badge-pink {
  color: #ffffff;
  background-color: #e83e8c; }
  .badge-pink[href]:hover, .badge-pink[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #d91a72; }

.badge-red {
  color: #ffffff;
  background-color: #962A30; }
  .badge-red[href]:hover, .badge-red[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #6e1f23; }

.badge-orange {
  color: #212529;
  background-color: #fd7e14; }
  .badge-orange[href]:hover, .badge-orange[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #dc6502; }

.badge-yellow {
  color: #212529;
  background-color: #ffc107; }
  .badge-yellow[href]:hover, .badge-yellow[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #d39e00; }

.badge-green {
  color: #ffffff;
  background-color: #4E6E32; }
  .badge-green[href]:hover, .badge-green[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #354b22; }

.badge-teal {
  color: #ffffff;
  background-color: #20c997; }
  .badge-teal[href]:hover, .badge-teal[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #199d76; }

.badge-cyan {
  color: #ffffff;
  background-color: #17a2b8; }
  .badge-cyan[href]:hover, .badge-cyan[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #117a8b; }

.badge-white {
  color: #212529;
  background-color: #ffffff; }
  .badge-white[href]:hover, .badge-white[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #e6e6e6; }

.badge-gray {
  color: #212529;
  background-color: #F3F2F3; }
  .badge-gray[href]:hover, .badge-gray[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #dbd7db; }

.badge-gray-dark {
  color: #ffffff;
  background-color: #343a40; }
  .badge-gray-dark[href]:hover, .badge-gray-dark[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #1d2124; }

.badge-black {
  color: #ffffff;
  background-color: #000000; }
  .badge-black[href]:hover, .badge-black[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: black; }

.badge-green-light {
  color: #212529;
  background-color: #C7D1BD; }
  .badge-green-light[href]:hover, .badge-green-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #aebc9f; }

.badge-green-dark {
  color: #ffffff;
  background-color: #3E5B22; }
  .badge-green-dark[href]:hover, .badge-green-dark[href]:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #253614; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: red; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #25254e;
  background-color: #dadaea;
  border-color: #cccce2; }
  .alert-primary hr {
    border-top-color: #bcbcd9; }
  .alert-primary .alert-link {
    color: #15152b; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #29391a;
  background-color: #dce2d6;
  border-color: #cdd6c6; }
  .alert-success hr {
    border-top-color: #c0cbb7; }
  .alert-success .alert-link {
    color: #10160a; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #4e1619;
  background-color: #ead4d6;
  border-color: #e2c3c5; }
  .alert-danger hr {
    border-top-color: #dab2b4; }
  .alert-danger .alert-link {
    color: #260b0c; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  text-align: center;
  background-color: #484896;
  transition: width 0.6s ease; }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    background-color: #ffffff; }
  .list-group-item.active {
    z-index: 2;
    color: #ffffff;
    background-color: #484896;
    border-color: #484896; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #25254e;
  background-color: #cccce2; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #25254e;
    background-color: #bcbcd9; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #25254e;
    border-color: #25254e; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #29391a;
  background-color: #cdd6c6; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #29391a;
    background-color: #c0cbb7; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #29391a;
    border-color: #29391a; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #4e1619;
  background-color: #e2c3c5; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #4e1619;
    background-color: #dab2b4; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #4e1619;
    border-color: #4e1619; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: .5; }
  .close:hover, .close:focus {
    color: #000000;
    text-decoration: none;
    opacity: .75; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.8; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.8;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #000000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.8;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #ffffff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #ffffff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #ffffff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #ffffff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #ffffff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #ffffff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #ffffff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #484896 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #373774 !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #4E6E32 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #354b22 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #962A30 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #6e1f23 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-blue {
  background-color: #484896 !important; }

a.bg-blue:hover, a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #373774 !important; }

.bg-indigo {
  background-color: #6610f2 !important; }

a.bg-indigo:hover, a.bg-indigo:focus,
button.bg-indigo:hover,
button.bg-indigo:focus {
  background-color: #510bc4 !important; }

.bg-purple {
  background-color: #6f42c1 !important; }

a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #59339d !important; }

.bg-pink {
  background-color: #e83e8c !important; }

a.bg-pink:hover, a.bg-pink:focus,
button.bg-pink:hover,
button.bg-pink:focus {
  background-color: #d91a72 !important; }

.bg-red {
  background-color: #962A30 !important; }

a.bg-red:hover, a.bg-red:focus,
button.bg-red:hover,
button.bg-red:focus {
  background-color: #6e1f23 !important; }

.bg-orange {
  background-color: #fd7e14 !important; }

a.bg-orange:hover, a.bg-orange:focus,
button.bg-orange:hover,
button.bg-orange:focus {
  background-color: #dc6502 !important; }

.bg-yellow {
  background-color: #ffc107 !important; }

a.bg-yellow:hover, a.bg-yellow:focus,
button.bg-yellow:hover,
button.bg-yellow:focus {
  background-color: #d39e00 !important; }

.bg-green {
  background-color: #4E6E32 !important; }

a.bg-green:hover, a.bg-green:focus,
button.bg-green:hover,
button.bg-green:focus {
  background-color: #354b22 !important; }

.bg-teal {
  background-color: #20c997 !important; }

a.bg-teal:hover, a.bg-teal:focus,
button.bg-teal:hover,
button.bg-teal:focus {
  background-color: #199d76 !important; }

.bg-cyan {
  background-color: #17a2b8 !important; }

a.bg-cyan:hover, a.bg-cyan:focus,
button.bg-cyan:hover,
button.bg-cyan:focus {
  background-color: #117a8b !important; }

.bg-white {
  background-color: #ffffff !important; }

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important; }

.bg-gray {
  background-color: #F3F2F3 !important; }

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #dbd7db !important; }

.bg-gray-dark {
  background-color: #343a40 !important; }

a.bg-gray-dark:hover, a.bg-gray-dark:focus,
button.bg-gray-dark:hover,
button.bg-gray-dark:focus {
  background-color: #1d2124 !important; }

.bg-black {
  background-color: #000000 !important; }

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: black !important; }

.bg-green-light {
  background-color: #C7D1BD !important; }

a.bg-green-light:hover, a.bg-green-light:focus,
button.bg-green-light:hover,
button.bg-green-light:focus {
  background-color: #aebc9f !important; }

.bg-green-dark {
  background-color: #3E5B22 !important; }

a.bg-green-dark:hover, a.bg-green-dark:focus,
button.bg-green-dark:hover,
button.bg-green-dark:focus {
  background-color: #253614 !important; }

.bg-white {
  background-color: #ffffff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #484896 !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #4E6E32 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #962A30 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #ffffff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0px !important; }

.mt-0,
.my-0 {
  margin-top: 0px !important; }

.mr-0,
.mx-0 {
  margin-right: 0px !important; }

.mb-0,
.my-0 {
  margin-bottom: 0px !important; }

.ml-0,
.mx-0 {
  margin-left: 0px !important; }

.m-1 {
  margin: 1px !important; }

.mt-1,
.my-1 {
  margin-top: 1px !important; }

.mr-1,
.mx-1 {
  margin-right: 1px !important; }

.mb-1,
.my-1 {
  margin-bottom: 1px !important; }

.ml-1,
.mx-1 {
  margin-left: 1px !important; }

.m-2 {
  margin: 2px !important; }

.mt-2,
.my-2 {
  margin-top: 2px !important; }

.mr-2,
.mx-2 {
  margin-right: 2px !important; }

.mb-2,
.my-2 {
  margin-bottom: 2px !important; }

.ml-2,
.mx-2 {
  margin-left: 2px !important; }

.m-3 {
  margin: 3px !important; }

.mt-3,
.my-3 {
  margin-top: 3px !important; }

.mr-3,
.mx-3 {
  margin-right: 3px !important; }

.mb-3,
.my-3 {
  margin-bottom: 3px !important; }

.ml-3,
.mx-3 {
  margin-left: 3px !important; }

.m-4 {
  margin: 4px !important; }

.mt-4,
.my-4 {
  margin-top: 4px !important; }

.mr-4,
.mx-4 {
  margin-right: 4px !important; }

.mb-4,
.my-4 {
  margin-bottom: 4px !important; }

.ml-4,
.mx-4 {
  margin-left: 4px !important; }

.m-5 {
  margin: 5px !important; }

.mt-5,
.my-5 {
  margin-top: 5px !important; }

.mr-5,
.mx-5 {
  margin-right: 5px !important; }

.mb-5,
.my-5 {
  margin-bottom: 5px !important; }

.ml-5,
.mx-5 {
  margin-left: 5px !important; }

.m-6 {
  margin: 6px !important; }

.mt-6,
.my-6 {
  margin-top: 6px !important; }

.mr-6,
.mx-6 {
  margin-right: 6px !important; }

.mb-6,
.my-6 {
  margin-bottom: 6px !important; }

.ml-6,
.mx-6 {
  margin-left: 6px !important; }

.m-7 {
  margin: 7px !important; }

.mt-7,
.my-7 {
  margin-top: 7px !important; }

.mr-7,
.mx-7 {
  margin-right: 7px !important; }

.mb-7,
.my-7 {
  margin-bottom: 7px !important; }

.ml-7,
.mx-7 {
  margin-left: 7px !important; }

.m-8 {
  margin: 8px !important; }

.mt-8,
.my-8 {
  margin-top: 8px !important; }

.mr-8,
.mx-8 {
  margin-right: 8px !important; }

.mb-8,
.my-8 {
  margin-bottom: 8px !important; }

.ml-8,
.mx-8 {
  margin-left: 8px !important; }

.m-9 {
  margin: 9px !important; }

.mt-9,
.my-9 {
  margin-top: 9px !important; }

.mr-9,
.mx-9 {
  margin-right: 9px !important; }

.mb-9,
.my-9 {
  margin-bottom: 9px !important; }

.ml-9,
.mx-9 {
  margin-left: 9px !important; }

.m-10 {
  margin: 10px !important; }

.mt-10,
.my-10 {
  margin-top: 10px !important; }

.mr-10,
.mx-10 {
  margin-right: 10px !important; }

.mb-10,
.my-10 {
  margin-bottom: 10px !important; }

.ml-10,
.mx-10 {
  margin-left: 10px !important; }

.m-11 {
  margin: 11px !important; }

.mt-11,
.my-11 {
  margin-top: 11px !important; }

.mr-11,
.mx-11 {
  margin-right: 11px !important; }

.mb-11,
.my-11 {
  margin-bottom: 11px !important; }

.ml-11,
.mx-11 {
  margin-left: 11px !important; }

.m-12 {
  margin: 12px !important; }

.mt-12,
.my-12 {
  margin-top: 12px !important; }

.mr-12,
.mx-12 {
  margin-right: 12px !important; }

.mb-12,
.my-12 {
  margin-bottom: 12px !important; }

.ml-12,
.mx-12 {
  margin-left: 12px !important; }

.m-13 {
  margin: 13px !important; }

.mt-13,
.my-13 {
  margin-top: 13px !important; }

.mr-13,
.mx-13 {
  margin-right: 13px !important; }

.mb-13,
.my-13 {
  margin-bottom: 13px !important; }

.ml-13,
.mx-13 {
  margin-left: 13px !important; }

.m-14 {
  margin: 14px !important; }

.mt-14,
.my-14 {
  margin-top: 14px !important; }

.mr-14,
.mx-14 {
  margin-right: 14px !important; }

.mb-14,
.my-14 {
  margin-bottom: 14px !important; }

.ml-14,
.mx-14 {
  margin-left: 14px !important; }

.m-15 {
  margin: 15px !important; }

.mt-15,
.my-15 {
  margin-top: 15px !important; }

.mr-15,
.mx-15 {
  margin-right: 15px !important; }

.mb-15,
.my-15 {
  margin-bottom: 15px !important; }

.ml-15,
.mx-15 {
  margin-left: 15px !important; }

.m-16 {
  margin: 16px !important; }

.mt-16,
.my-16 {
  margin-top: 16px !important; }

.mr-16,
.mx-16 {
  margin-right: 16px !important; }

.mb-16,
.my-16 {
  margin-bottom: 16px !important; }

.ml-16,
.mx-16 {
  margin-left: 16px !important; }

.m-17 {
  margin: 17px !important; }

.mt-17,
.my-17 {
  margin-top: 17px !important; }

.mr-17,
.mx-17 {
  margin-right: 17px !important; }

.mb-17,
.my-17 {
  margin-bottom: 17px !important; }

.ml-17,
.mx-17 {
  margin-left: 17px !important; }

.m-18 {
  margin: 18px !important; }

.mt-18,
.my-18 {
  margin-top: 18px !important; }

.mr-18,
.mx-18 {
  margin-right: 18px !important; }

.mb-18,
.my-18 {
  margin-bottom: 18px !important; }

.ml-18,
.mx-18 {
  margin-left: 18px !important; }

.m-19 {
  margin: 19px !important; }

.mt-19,
.my-19 {
  margin-top: 19px !important; }

.mr-19,
.mx-19 {
  margin-right: 19px !important; }

.mb-19,
.my-19 {
  margin-bottom: 19px !important; }

.ml-19,
.mx-19 {
  margin-left: 19px !important; }

.m-20 {
  margin: 20px !important; }

.mt-20,
.my-20 {
  margin-top: 20px !important; }

.mr-20,
.mx-20 {
  margin-right: 20px !important; }

.mb-20,
.my-20 {
  margin-bottom: 20px !important; }

.ml-20,
.mx-20 {
  margin-left: 20px !important; }

.m-25 {
  margin: 25px !important; }

.mt-25,
.my-25 {
  margin-top: 25px !important; }

.mr-25,
.mx-25 {
  margin-right: 25px !important; }

.mb-25,
.my-25 {
  margin-bottom: 25px !important; }

.ml-25,
.mx-25 {
  margin-left: 25px !important; }

.m-30 {
  margin: 30px !important; }

.mt-30,
.my-30 {
  margin-top: 30px !important; }

.mr-30,
.mx-30 {
  margin-right: 30px !important; }

.mb-30,
.my-30 {
  margin-bottom: 30px !important; }

.ml-30,
.mx-30 {
  margin-left: 30px !important; }

.m-35 {
  margin: 35px !important; }

.mt-35,
.my-35 {
  margin-top: 35px !important; }

.mr-35,
.mx-35 {
  margin-right: 35px !important; }

.mb-35,
.my-35 {
  margin-bottom: 35px !important; }

.ml-35,
.mx-35 {
  margin-left: 35px !important; }

.m-40 {
  margin: 40px !important; }

.mt-40,
.my-40 {
  margin-top: 40px !important; }

.mr-40,
.mx-40 {
  margin-right: 40px !important; }

.mb-40,
.my-40 {
  margin-bottom: 40px !important; }

.ml-40,
.mx-40 {
  margin-left: 40px !important; }

.m-45 {
  margin: 45px !important; }

.mt-45,
.my-45 {
  margin-top: 45px !important; }

.mr-45,
.mx-45 {
  margin-right: 45px !important; }

.mb-45,
.my-45 {
  margin-bottom: 45px !important; }

.ml-45,
.mx-45 {
  margin-left: 45px !important; }

.m-50 {
  margin: 50px !important; }

.mt-50,
.my-50 {
  margin-top: 50px !important; }

.mr-50,
.mx-50 {
  margin-right: 50px !important; }

.mb-50,
.my-50 {
  margin-bottom: 50px !important; }

.ml-50,
.mx-50 {
  margin-left: 50px !important; }

.m-55 {
  margin: 55px !important; }

.mt-55,
.my-55 {
  margin-top: 55px !important; }

.mr-55,
.mx-55 {
  margin-right: 55px !important; }

.mb-55,
.my-55 {
  margin-bottom: 55px !important; }

.ml-55,
.mx-55 {
  margin-left: 55px !important; }

.m-60 {
  margin: 60px !important; }

.mt-60,
.my-60 {
  margin-top: 60px !important; }

.mr-60,
.mx-60 {
  margin-right: 60px !important; }

.mb-60,
.my-60 {
  margin-bottom: 60px !important; }

.ml-60,
.mx-60 {
  margin-left: 60px !important; }

.m-65 {
  margin: 65px !important; }

.mt-65,
.my-65 {
  margin-top: 65px !important; }

.mr-65,
.mx-65 {
  margin-right: 65px !important; }

.mb-65,
.my-65 {
  margin-bottom: 65px !important; }

.ml-65,
.mx-65 {
  margin-left: 65px !important; }

.m-70 {
  margin: 70px !important; }

.mt-70,
.my-70 {
  margin-top: 70px !important; }

.mr-70,
.mx-70 {
  margin-right: 70px !important; }

.mb-70,
.my-70 {
  margin-bottom: 70px !important; }

.ml-70,
.mx-70 {
  margin-left: 70px !important; }

.m-75 {
  margin: 75px !important; }

.mt-75,
.my-75 {
  margin-top: 75px !important; }

.mr-75,
.mx-75 {
  margin-right: 75px !important; }

.mb-75,
.my-75 {
  margin-bottom: 75px !important; }

.ml-75,
.mx-75 {
  margin-left: 75px !important; }

.m-80 {
  margin: 80px !important; }

.mt-80,
.my-80 {
  margin-top: 80px !important; }

.mr-80,
.mx-80 {
  margin-right: 80px !important; }

.mb-80,
.my-80 {
  margin-bottom: 80px !important; }

.ml-80,
.mx-80 {
  margin-left: 80px !important; }

.m-85 {
  margin: 85px !important; }

.mt-85,
.my-85 {
  margin-top: 85px !important; }

.mr-85,
.mx-85 {
  margin-right: 85px !important; }

.mb-85,
.my-85 {
  margin-bottom: 85px !important; }

.ml-85,
.mx-85 {
  margin-left: 85px !important; }

.m-90 {
  margin: 90px !important; }

.mt-90,
.my-90 {
  margin-top: 90px !important; }

.mr-90,
.mx-90 {
  margin-right: 90px !important; }

.mb-90,
.my-90 {
  margin-bottom: 90px !important; }

.ml-90,
.mx-90 {
  margin-left: 90px !important; }

.m-95 {
  margin: 95px !important; }

.mt-95,
.my-95 {
  margin-top: 95px !important; }

.mr-95,
.mx-95 {
  margin-right: 95px !important; }

.mb-95,
.my-95 {
  margin-bottom: 95px !important; }

.ml-95,
.mx-95 {
  margin-left: 95px !important; }

.m-100 {
  margin: 100px !important; }

.mt-100,
.my-100 {
  margin-top: 100px !important; }

.mr-100,
.mx-100 {
  margin-right: 100px !important; }

.mb-100,
.my-100 {
  margin-bottom: 100px !important; }

.ml-100,
.mx-100 {
  margin-left: 100px !important; }

.m-110 {
  margin: 110px !important; }

.mt-110,
.my-110 {
  margin-top: 110px !important; }

.mr-110,
.mx-110 {
  margin-right: 110px !important; }

.mb-110,
.my-110 {
  margin-bottom: 110px !important; }

.ml-110,
.mx-110 {
  margin-left: 110px !important; }

.m-120 {
  margin: 120px !important; }

.mt-120,
.my-120 {
  margin-top: 120px !important; }

.mr-120,
.mx-120 {
  margin-right: 120px !important; }

.mb-120,
.my-120 {
  margin-bottom: 120px !important; }

.ml-120,
.mx-120 {
  margin-left: 120px !important; }

.m-130 {
  margin: 130px !important; }

.mt-130,
.my-130 {
  margin-top: 130px !important; }

.mr-130,
.mx-130 {
  margin-right: 130px !important; }

.mb-130,
.my-130 {
  margin-bottom: 130px !important; }

.ml-130,
.mx-130 {
  margin-left: 130px !important; }

.m-140 {
  margin: 140px !important; }

.mt-140,
.my-140 {
  margin-top: 140px !important; }

.mr-140,
.mx-140 {
  margin-right: 140px !important; }

.mb-140,
.my-140 {
  margin-bottom: 140px !important; }

.ml-140,
.mx-140 {
  margin-left: 140px !important; }

.m-150 {
  margin: 150px !important; }

.mt-150,
.my-150 {
  margin-top: 150px !important; }

.mr-150,
.mx-150 {
  margin-right: 150px !important; }

.mb-150,
.my-150 {
  margin-bottom: 150px !important; }

.ml-150,
.mx-150 {
  margin-left: 150px !important; }

.m-160 {
  margin: 160px !important; }

.mt-160,
.my-160 {
  margin-top: 160px !important; }

.mr-160,
.mx-160 {
  margin-right: 160px !important; }

.mb-160,
.my-160 {
  margin-bottom: 160px !important; }

.ml-160,
.mx-160 {
  margin-left: 160px !important; }

.m-170 {
  margin: 170px !important; }

.mt-170,
.my-170 {
  margin-top: 170px !important; }

.mr-170,
.mx-170 {
  margin-right: 170px !important; }

.mb-170,
.my-170 {
  margin-bottom: 170px !important; }

.ml-170,
.mx-170 {
  margin-left: 170px !important; }

.m-180 {
  margin: 180px !important; }

.mt-180,
.my-180 {
  margin-top: 180px !important; }

.mr-180,
.mx-180 {
  margin-right: 180px !important; }

.mb-180,
.my-180 {
  margin-bottom: 180px !important; }

.ml-180,
.mx-180 {
  margin-left: 180px !important; }

.m-190 {
  margin: 190px !important; }

.mt-190,
.my-190 {
  margin-top: 190px !important; }

.mr-190,
.mx-190 {
  margin-right: 190px !important; }

.mb-190,
.my-190 {
  margin-bottom: 190px !important; }

.ml-190,
.mx-190 {
  margin-left: 190px !important; }

.m-200 {
  margin: 200px !important; }

.mt-200,
.my-200 {
  margin-top: 200px !important; }

.mr-200,
.mx-200 {
  margin-right: 200px !important; }

.mb-200,
.my-200 {
  margin-bottom: 200px !important; }

.ml-200,
.mx-200 {
  margin-left: 200px !important; }

.p-0 {
  padding: 0px !important; }

.pt-0,
.py-0 {
  padding-top: 0px !important; }

.pr-0,
.px-0 {
  padding-right: 0px !important; }

.pb-0,
.py-0 {
  padding-bottom: 0px !important; }

.pl-0,
.px-0 {
  padding-left: 0px !important; }

.p-1 {
  padding: 1px !important; }

.pt-1,
.py-1 {
  padding-top: 1px !important; }

.pr-1,
.px-1 {
  padding-right: 1px !important; }

.pb-1,
.py-1 {
  padding-bottom: 1px !important; }

.pl-1,
.px-1 {
  padding-left: 1px !important; }

.p-2 {
  padding: 2px !important; }

.pt-2,
.py-2 {
  padding-top: 2px !important; }

.pr-2,
.px-2 {
  padding-right: 2px !important; }

.pb-2,
.py-2 {
  padding-bottom: 2px !important; }

.pl-2,
.px-2 {
  padding-left: 2px !important; }

.p-3 {
  padding: 3px !important; }

.pt-3,
.py-3 {
  padding-top: 3px !important; }

.pr-3,
.px-3 {
  padding-right: 3px !important; }

.pb-3,
.py-3 {
  padding-bottom: 3px !important; }

.pl-3,
.px-3 {
  padding-left: 3px !important; }

.p-4 {
  padding: 4px !important; }

.pt-4,
.py-4 {
  padding-top: 4px !important; }

.pr-4,
.px-4 {
  padding-right: 4px !important; }

.pb-4,
.py-4 {
  padding-bottom: 4px !important; }

.pl-4,
.px-4 {
  padding-left: 4px !important; }

.p-5 {
  padding: 5px !important; }

.pt-5,
.py-5 {
  padding-top: 5px !important; }

.pr-5,
.px-5 {
  padding-right: 5px !important; }

.pb-5,
.py-5 {
  padding-bottom: 5px !important; }

.pl-5,
.px-5 {
  padding-left: 5px !important; }

.p-6 {
  padding: 6px !important; }

.pt-6,
.py-6 {
  padding-top: 6px !important; }

.pr-6,
.px-6 {
  padding-right: 6px !important; }

.pb-6,
.py-6 {
  padding-bottom: 6px !important; }

.pl-6,
.px-6 {
  padding-left: 6px !important; }

.p-7 {
  padding: 7px !important; }

.pt-7,
.py-7 {
  padding-top: 7px !important; }

.pr-7,
.px-7 {
  padding-right: 7px !important; }

.pb-7,
.py-7 {
  padding-bottom: 7px !important; }

.pl-7,
.px-7 {
  padding-left: 7px !important; }

.p-8 {
  padding: 8px !important; }

.pt-8,
.py-8 {
  padding-top: 8px !important; }

.pr-8,
.px-8 {
  padding-right: 8px !important; }

.pb-8,
.py-8 {
  padding-bottom: 8px !important; }

.pl-8,
.px-8 {
  padding-left: 8px !important; }

.p-9 {
  padding: 9px !important; }

.pt-9,
.py-9 {
  padding-top: 9px !important; }

.pr-9,
.px-9 {
  padding-right: 9px !important; }

.pb-9,
.py-9 {
  padding-bottom: 9px !important; }

.pl-9,
.px-9 {
  padding-left: 9px !important; }

.p-10 {
  padding: 10px !important; }

.pt-10,
.py-10 {
  padding-top: 10px !important; }

.pr-10,
.px-10 {
  padding-right: 10px !important; }

.pb-10,
.py-10 {
  padding-bottom: 10px !important; }

.pl-10,
.px-10 {
  padding-left: 10px !important; }

.p-11 {
  padding: 11px !important; }

.pt-11,
.py-11 {
  padding-top: 11px !important; }

.pr-11,
.px-11 {
  padding-right: 11px !important; }

.pb-11,
.py-11 {
  padding-bottom: 11px !important; }

.pl-11,
.px-11 {
  padding-left: 11px !important; }

.p-12 {
  padding: 12px !important; }

.pt-12,
.py-12 {
  padding-top: 12px !important; }

.pr-12,
.px-12 {
  padding-right: 12px !important; }

.pb-12,
.py-12 {
  padding-bottom: 12px !important; }

.pl-12,
.px-12 {
  padding-left: 12px !important; }

.p-13 {
  padding: 13px !important; }

.pt-13,
.py-13 {
  padding-top: 13px !important; }

.pr-13,
.px-13 {
  padding-right: 13px !important; }

.pb-13,
.py-13 {
  padding-bottom: 13px !important; }

.pl-13,
.px-13 {
  padding-left: 13px !important; }

.p-14 {
  padding: 14px !important; }

.pt-14,
.py-14 {
  padding-top: 14px !important; }

.pr-14,
.px-14 {
  padding-right: 14px !important; }

.pb-14,
.py-14 {
  padding-bottom: 14px !important; }

.pl-14,
.px-14 {
  padding-left: 14px !important; }

.p-15 {
  padding: 15px !important; }

.pt-15,
.py-15 {
  padding-top: 15px !important; }

.pr-15,
.px-15 {
  padding-right: 15px !important; }

.pb-15,
.py-15 {
  padding-bottom: 15px !important; }

.pl-15,
.px-15 {
  padding-left: 15px !important; }

.p-16 {
  padding: 16px !important; }

.pt-16,
.py-16 {
  padding-top: 16px !important; }

.pr-16,
.px-16 {
  padding-right: 16px !important; }

.pb-16,
.py-16 {
  padding-bottom: 16px !important; }

.pl-16,
.px-16 {
  padding-left: 16px !important; }

.p-17 {
  padding: 17px !important; }

.pt-17,
.py-17 {
  padding-top: 17px !important; }

.pr-17,
.px-17 {
  padding-right: 17px !important; }

.pb-17,
.py-17 {
  padding-bottom: 17px !important; }

.pl-17,
.px-17 {
  padding-left: 17px !important; }

.p-18 {
  padding: 18px !important; }

.pt-18,
.py-18 {
  padding-top: 18px !important; }

.pr-18,
.px-18 {
  padding-right: 18px !important; }

.pb-18,
.py-18 {
  padding-bottom: 18px !important; }

.pl-18,
.px-18 {
  padding-left: 18px !important; }

.p-19 {
  padding: 19px !important; }

.pt-19,
.py-19 {
  padding-top: 19px !important; }

.pr-19,
.px-19 {
  padding-right: 19px !important; }

.pb-19,
.py-19 {
  padding-bottom: 19px !important; }

.pl-19,
.px-19 {
  padding-left: 19px !important; }

.p-20 {
  padding: 20px !important; }

.pt-20,
.py-20 {
  padding-top: 20px !important; }

.pr-20,
.px-20 {
  padding-right: 20px !important; }

.pb-20,
.py-20 {
  padding-bottom: 20px !important; }

.pl-20,
.px-20 {
  padding-left: 20px !important; }

.p-25 {
  padding: 25px !important; }

.pt-25,
.py-25 {
  padding-top: 25px !important; }

.pr-25,
.px-25 {
  padding-right: 25px !important; }

.pb-25,
.py-25 {
  padding-bottom: 25px !important; }

.pl-25,
.px-25 {
  padding-left: 25px !important; }

.p-30 {
  padding: 30px !important; }

.pt-30,
.py-30 {
  padding-top: 30px !important; }

.pr-30,
.px-30 {
  padding-right: 30px !important; }

.pb-30,
.py-30 {
  padding-bottom: 30px !important; }

.pl-30,
.px-30 {
  padding-left: 30px !important; }

.p-35 {
  padding: 35px !important; }

.pt-35,
.py-35 {
  padding-top: 35px !important; }

.pr-35,
.px-35 {
  padding-right: 35px !important; }

.pb-35,
.py-35 {
  padding-bottom: 35px !important; }

.pl-35,
.px-35 {
  padding-left: 35px !important; }

.p-40 {
  padding: 40px !important; }

.pt-40,
.py-40 {
  padding-top: 40px !important; }

.pr-40,
.px-40 {
  padding-right: 40px !important; }

.pb-40,
.py-40 {
  padding-bottom: 40px !important; }

.pl-40,
.px-40 {
  padding-left: 40px !important; }

.p-45 {
  padding: 45px !important; }

.pt-45,
.py-45 {
  padding-top: 45px !important; }

.pr-45,
.px-45 {
  padding-right: 45px !important; }

.pb-45,
.py-45 {
  padding-bottom: 45px !important; }

.pl-45,
.px-45 {
  padding-left: 45px !important; }

.p-50 {
  padding: 50px !important; }

.pt-50,
.py-50 {
  padding-top: 50px !important; }

.pr-50,
.px-50 {
  padding-right: 50px !important; }

.pb-50,
.py-50 {
  padding-bottom: 50px !important; }

.pl-50,
.px-50 {
  padding-left: 50px !important; }

.p-55 {
  padding: 55px !important; }

.pt-55,
.py-55 {
  padding-top: 55px !important; }

.pr-55,
.px-55 {
  padding-right: 55px !important; }

.pb-55,
.py-55 {
  padding-bottom: 55px !important; }

.pl-55,
.px-55 {
  padding-left: 55px !important; }

.p-60 {
  padding: 60px !important; }

.pt-60,
.py-60 {
  padding-top: 60px !important; }

.pr-60,
.px-60 {
  padding-right: 60px !important; }

.pb-60,
.py-60 {
  padding-bottom: 60px !important; }

.pl-60,
.px-60 {
  padding-left: 60px !important; }

.p-65 {
  padding: 65px !important; }

.pt-65,
.py-65 {
  padding-top: 65px !important; }

.pr-65,
.px-65 {
  padding-right: 65px !important; }

.pb-65,
.py-65 {
  padding-bottom: 65px !important; }

.pl-65,
.px-65 {
  padding-left: 65px !important; }

.p-70 {
  padding: 70px !important; }

.pt-70,
.py-70 {
  padding-top: 70px !important; }

.pr-70,
.px-70 {
  padding-right: 70px !important; }

.pb-70,
.py-70 {
  padding-bottom: 70px !important; }

.pl-70,
.px-70 {
  padding-left: 70px !important; }

.p-75 {
  padding: 75px !important; }

.pt-75,
.py-75 {
  padding-top: 75px !important; }

.pr-75,
.px-75 {
  padding-right: 75px !important; }

.pb-75,
.py-75 {
  padding-bottom: 75px !important; }

.pl-75,
.px-75 {
  padding-left: 75px !important; }

.p-80 {
  padding: 80px !important; }

.pt-80,
.py-80 {
  padding-top: 80px !important; }

.pr-80,
.px-80 {
  padding-right: 80px !important; }

.pb-80,
.py-80 {
  padding-bottom: 80px !important; }

.pl-80,
.px-80 {
  padding-left: 80px !important; }

.p-85 {
  padding: 85px !important; }

.pt-85,
.py-85 {
  padding-top: 85px !important; }

.pr-85,
.px-85 {
  padding-right: 85px !important; }

.pb-85,
.py-85 {
  padding-bottom: 85px !important; }

.pl-85,
.px-85 {
  padding-left: 85px !important; }

.p-90 {
  padding: 90px !important; }

.pt-90,
.py-90 {
  padding-top: 90px !important; }

.pr-90,
.px-90 {
  padding-right: 90px !important; }

.pb-90,
.py-90 {
  padding-bottom: 90px !important; }

.pl-90,
.px-90 {
  padding-left: 90px !important; }

.p-95 {
  padding: 95px !important; }

.pt-95,
.py-95 {
  padding-top: 95px !important; }

.pr-95,
.px-95 {
  padding-right: 95px !important; }

.pb-95,
.py-95 {
  padding-bottom: 95px !important; }

.pl-95,
.px-95 {
  padding-left: 95px !important; }

.p-100 {
  padding: 100px !important; }

.pt-100,
.py-100 {
  padding-top: 100px !important; }

.pr-100,
.px-100 {
  padding-right: 100px !important; }

.pb-100,
.py-100 {
  padding-bottom: 100px !important; }

.pl-100,
.px-100 {
  padding-left: 100px !important; }

.p-110 {
  padding: 110px !important; }

.pt-110,
.py-110 {
  padding-top: 110px !important; }

.pr-110,
.px-110 {
  padding-right: 110px !important; }

.pb-110,
.py-110 {
  padding-bottom: 110px !important; }

.pl-110,
.px-110 {
  padding-left: 110px !important; }

.p-120 {
  padding: 120px !important; }

.pt-120,
.py-120 {
  padding-top: 120px !important; }

.pr-120,
.px-120 {
  padding-right: 120px !important; }

.pb-120,
.py-120 {
  padding-bottom: 120px !important; }

.pl-120,
.px-120 {
  padding-left: 120px !important; }

.p-130 {
  padding: 130px !important; }

.pt-130,
.py-130 {
  padding-top: 130px !important; }

.pr-130,
.px-130 {
  padding-right: 130px !important; }

.pb-130,
.py-130 {
  padding-bottom: 130px !important; }

.pl-130,
.px-130 {
  padding-left: 130px !important; }

.p-140 {
  padding: 140px !important; }

.pt-140,
.py-140 {
  padding-top: 140px !important; }

.pr-140,
.px-140 {
  padding-right: 140px !important; }

.pb-140,
.py-140 {
  padding-bottom: 140px !important; }

.pl-140,
.px-140 {
  padding-left: 140px !important; }

.p-150 {
  padding: 150px !important; }

.pt-150,
.py-150 {
  padding-top: 150px !important; }

.pr-150,
.px-150 {
  padding-right: 150px !important; }

.pb-150,
.py-150 {
  padding-bottom: 150px !important; }

.pl-150,
.px-150 {
  padding-left: 150px !important; }

.p-160 {
  padding: 160px !important; }

.pt-160,
.py-160 {
  padding-top: 160px !important; }

.pr-160,
.px-160 {
  padding-right: 160px !important; }

.pb-160,
.py-160 {
  padding-bottom: 160px !important; }

.pl-160,
.px-160 {
  padding-left: 160px !important; }

.p-170 {
  padding: 170px !important; }

.pt-170,
.py-170 {
  padding-top: 170px !important; }

.pr-170,
.px-170 {
  padding-right: 170px !important; }

.pb-170,
.py-170 {
  padding-bottom: 170px !important; }

.pl-170,
.px-170 {
  padding-left: 170px !important; }

.p-180 {
  padding: 180px !important; }

.pt-180,
.py-180 {
  padding-top: 180px !important; }

.pr-180,
.px-180 {
  padding-right: 180px !important; }

.pb-180,
.py-180 {
  padding-bottom: 180px !important; }

.pl-180,
.px-180 {
  padding-left: 180px !important; }

.p-190 {
  padding: 190px !important; }

.pt-190,
.py-190 {
  padding-top: 190px !important; }

.pr-190,
.px-190 {
  padding-right: 190px !important; }

.pb-190,
.py-190 {
  padding-bottom: 190px !important; }

.pl-190,
.px-190 {
  padding-left: 190px !important; }

.p-200 {
  padding: 200px !important; }

.pt-200,
.py-200 {
  padding-top: 200px !important; }

.pr-200,
.px-200 {
  padding-right: 200px !important; }

.pb-200,
.py-200 {
  padding-bottom: 200px !important; }

.pl-200,
.px-200 {
  padding-left: 200px !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0px !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0px !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0px !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0px !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0px !important; }
  .m-sm-1 {
    margin: 1px !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 1px !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 1px !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 1px !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 1px !important; }
  .m-sm-2 {
    margin: 2px !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 2px !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 2px !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 2px !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 2px !important; }
  .m-sm-3 {
    margin: 3px !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 3px !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 3px !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 3px !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 3px !important; }
  .m-sm-4 {
    margin: 4px !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 4px !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 4px !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 4px !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 4px !important; }
  .m-sm-5 {
    margin: 5px !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 5px !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 5px !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 5px !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 5px !important; }
  .m-sm-6 {
    margin: 6px !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 6px !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 6px !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 6px !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 6px !important; }
  .m-sm-7 {
    margin: 7px !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 7px !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 7px !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 7px !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 7px !important; }
  .m-sm-8 {
    margin: 8px !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 8px !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 8px !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 8px !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 8px !important; }
  .m-sm-9 {
    margin: 9px !important; }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 9px !important; }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 9px !important; }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 9px !important; }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 9px !important; }
  .m-sm-10 {
    margin: 10px !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 10px !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 10px !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 10px !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 10px !important; }
  .m-sm-11 {
    margin: 11px !important; }
  .mt-sm-11,
  .my-sm-11 {
    margin-top: 11px !important; }
  .mr-sm-11,
  .mx-sm-11 {
    margin-right: 11px !important; }
  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 11px !important; }
  .ml-sm-11,
  .mx-sm-11 {
    margin-left: 11px !important; }
  .m-sm-12 {
    margin: 12px !important; }
  .mt-sm-12,
  .my-sm-12 {
    margin-top: 12px !important; }
  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 12px !important; }
  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 12px !important; }
  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 12px !important; }
  .m-sm-13 {
    margin: 13px !important; }
  .mt-sm-13,
  .my-sm-13 {
    margin-top: 13px !important; }
  .mr-sm-13,
  .mx-sm-13 {
    margin-right: 13px !important; }
  .mb-sm-13,
  .my-sm-13 {
    margin-bottom: 13px !important; }
  .ml-sm-13,
  .mx-sm-13 {
    margin-left: 13px !important; }
  .m-sm-14 {
    margin: 14px !important; }
  .mt-sm-14,
  .my-sm-14 {
    margin-top: 14px !important; }
  .mr-sm-14,
  .mx-sm-14 {
    margin-right: 14px !important; }
  .mb-sm-14,
  .my-sm-14 {
    margin-bottom: 14px !important; }
  .ml-sm-14,
  .mx-sm-14 {
    margin-left: 14px !important; }
  .m-sm-15 {
    margin: 15px !important; }
  .mt-sm-15,
  .my-sm-15 {
    margin-top: 15px !important; }
  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 15px !important; }
  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 15px !important; }
  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 15px !important; }
  .m-sm-16 {
    margin: 16px !important; }
  .mt-sm-16,
  .my-sm-16 {
    margin-top: 16px !important; }
  .mr-sm-16,
  .mx-sm-16 {
    margin-right: 16px !important; }
  .mb-sm-16,
  .my-sm-16 {
    margin-bottom: 16px !important; }
  .ml-sm-16,
  .mx-sm-16 {
    margin-left: 16px !important; }
  .m-sm-17 {
    margin: 17px !important; }
  .mt-sm-17,
  .my-sm-17 {
    margin-top: 17px !important; }
  .mr-sm-17,
  .mx-sm-17 {
    margin-right: 17px !important; }
  .mb-sm-17,
  .my-sm-17 {
    margin-bottom: 17px !important; }
  .ml-sm-17,
  .mx-sm-17 {
    margin-left: 17px !important; }
  .m-sm-18 {
    margin: 18px !important; }
  .mt-sm-18,
  .my-sm-18 {
    margin-top: 18px !important; }
  .mr-sm-18,
  .mx-sm-18 {
    margin-right: 18px !important; }
  .mb-sm-18,
  .my-sm-18 {
    margin-bottom: 18px !important; }
  .ml-sm-18,
  .mx-sm-18 {
    margin-left: 18px !important; }
  .m-sm-19 {
    margin: 19px !important; }
  .mt-sm-19,
  .my-sm-19 {
    margin-top: 19px !important; }
  .mr-sm-19,
  .mx-sm-19 {
    margin-right: 19px !important; }
  .mb-sm-19,
  .my-sm-19 {
    margin-bottom: 19px !important; }
  .ml-sm-19,
  .mx-sm-19 {
    margin-left: 19px !important; }
  .m-sm-20 {
    margin: 20px !important; }
  .mt-sm-20,
  .my-sm-20 {
    margin-top: 20px !important; }
  .mr-sm-20,
  .mx-sm-20 {
    margin-right: 20px !important; }
  .mb-sm-20,
  .my-sm-20 {
    margin-bottom: 20px !important; }
  .ml-sm-20,
  .mx-sm-20 {
    margin-left: 20px !important; }
  .m-sm-25 {
    margin: 25px !important; }
  .mt-sm-25,
  .my-sm-25 {
    margin-top: 25px !important; }
  .mr-sm-25,
  .mx-sm-25 {
    margin-right: 25px !important; }
  .mb-sm-25,
  .my-sm-25 {
    margin-bottom: 25px !important; }
  .ml-sm-25,
  .mx-sm-25 {
    margin-left: 25px !important; }
  .m-sm-30 {
    margin: 30px !important; }
  .mt-sm-30,
  .my-sm-30 {
    margin-top: 30px !important; }
  .mr-sm-30,
  .mx-sm-30 {
    margin-right: 30px !important; }
  .mb-sm-30,
  .my-sm-30 {
    margin-bottom: 30px !important; }
  .ml-sm-30,
  .mx-sm-30 {
    margin-left: 30px !important; }
  .m-sm-35 {
    margin: 35px !important; }
  .mt-sm-35,
  .my-sm-35 {
    margin-top: 35px !important; }
  .mr-sm-35,
  .mx-sm-35 {
    margin-right: 35px !important; }
  .mb-sm-35,
  .my-sm-35 {
    margin-bottom: 35px !important; }
  .ml-sm-35,
  .mx-sm-35 {
    margin-left: 35px !important; }
  .m-sm-40 {
    margin: 40px !important; }
  .mt-sm-40,
  .my-sm-40 {
    margin-top: 40px !important; }
  .mr-sm-40,
  .mx-sm-40 {
    margin-right: 40px !important; }
  .mb-sm-40,
  .my-sm-40 {
    margin-bottom: 40px !important; }
  .ml-sm-40,
  .mx-sm-40 {
    margin-left: 40px !important; }
  .m-sm-45 {
    margin: 45px !important; }
  .mt-sm-45,
  .my-sm-45 {
    margin-top: 45px !important; }
  .mr-sm-45,
  .mx-sm-45 {
    margin-right: 45px !important; }
  .mb-sm-45,
  .my-sm-45 {
    margin-bottom: 45px !important; }
  .ml-sm-45,
  .mx-sm-45 {
    margin-left: 45px !important; }
  .m-sm-50 {
    margin: 50px !important; }
  .mt-sm-50,
  .my-sm-50 {
    margin-top: 50px !important; }
  .mr-sm-50,
  .mx-sm-50 {
    margin-right: 50px !important; }
  .mb-sm-50,
  .my-sm-50 {
    margin-bottom: 50px !important; }
  .ml-sm-50,
  .mx-sm-50 {
    margin-left: 50px !important; }
  .m-sm-55 {
    margin: 55px !important; }
  .mt-sm-55,
  .my-sm-55 {
    margin-top: 55px !important; }
  .mr-sm-55,
  .mx-sm-55 {
    margin-right: 55px !important; }
  .mb-sm-55,
  .my-sm-55 {
    margin-bottom: 55px !important; }
  .ml-sm-55,
  .mx-sm-55 {
    margin-left: 55px !important; }
  .m-sm-60 {
    margin: 60px !important; }
  .mt-sm-60,
  .my-sm-60 {
    margin-top: 60px !important; }
  .mr-sm-60,
  .mx-sm-60 {
    margin-right: 60px !important; }
  .mb-sm-60,
  .my-sm-60 {
    margin-bottom: 60px !important; }
  .ml-sm-60,
  .mx-sm-60 {
    margin-left: 60px !important; }
  .m-sm-65 {
    margin: 65px !important; }
  .mt-sm-65,
  .my-sm-65 {
    margin-top: 65px !important; }
  .mr-sm-65,
  .mx-sm-65 {
    margin-right: 65px !important; }
  .mb-sm-65,
  .my-sm-65 {
    margin-bottom: 65px !important; }
  .ml-sm-65,
  .mx-sm-65 {
    margin-left: 65px !important; }
  .m-sm-70 {
    margin: 70px !important; }
  .mt-sm-70,
  .my-sm-70 {
    margin-top: 70px !important; }
  .mr-sm-70,
  .mx-sm-70 {
    margin-right: 70px !important; }
  .mb-sm-70,
  .my-sm-70 {
    margin-bottom: 70px !important; }
  .ml-sm-70,
  .mx-sm-70 {
    margin-left: 70px !important; }
  .m-sm-75 {
    margin: 75px !important; }
  .mt-sm-75,
  .my-sm-75 {
    margin-top: 75px !important; }
  .mr-sm-75,
  .mx-sm-75 {
    margin-right: 75px !important; }
  .mb-sm-75,
  .my-sm-75 {
    margin-bottom: 75px !important; }
  .ml-sm-75,
  .mx-sm-75 {
    margin-left: 75px !important; }
  .m-sm-80 {
    margin: 80px !important; }
  .mt-sm-80,
  .my-sm-80 {
    margin-top: 80px !important; }
  .mr-sm-80,
  .mx-sm-80 {
    margin-right: 80px !important; }
  .mb-sm-80,
  .my-sm-80 {
    margin-bottom: 80px !important; }
  .ml-sm-80,
  .mx-sm-80 {
    margin-left: 80px !important; }
  .m-sm-85 {
    margin: 85px !important; }
  .mt-sm-85,
  .my-sm-85 {
    margin-top: 85px !important; }
  .mr-sm-85,
  .mx-sm-85 {
    margin-right: 85px !important; }
  .mb-sm-85,
  .my-sm-85 {
    margin-bottom: 85px !important; }
  .ml-sm-85,
  .mx-sm-85 {
    margin-left: 85px !important; }
  .m-sm-90 {
    margin: 90px !important; }
  .mt-sm-90,
  .my-sm-90 {
    margin-top: 90px !important; }
  .mr-sm-90,
  .mx-sm-90 {
    margin-right: 90px !important; }
  .mb-sm-90,
  .my-sm-90 {
    margin-bottom: 90px !important; }
  .ml-sm-90,
  .mx-sm-90 {
    margin-left: 90px !important; }
  .m-sm-95 {
    margin: 95px !important; }
  .mt-sm-95,
  .my-sm-95 {
    margin-top: 95px !important; }
  .mr-sm-95,
  .mx-sm-95 {
    margin-right: 95px !important; }
  .mb-sm-95,
  .my-sm-95 {
    margin-bottom: 95px !important; }
  .ml-sm-95,
  .mx-sm-95 {
    margin-left: 95px !important; }
  .m-sm-100 {
    margin: 100px !important; }
  .mt-sm-100,
  .my-sm-100 {
    margin-top: 100px !important; }
  .mr-sm-100,
  .mx-sm-100 {
    margin-right: 100px !important; }
  .mb-sm-100,
  .my-sm-100 {
    margin-bottom: 100px !important; }
  .ml-sm-100,
  .mx-sm-100 {
    margin-left: 100px !important; }
  .m-sm-110 {
    margin: 110px !important; }
  .mt-sm-110,
  .my-sm-110 {
    margin-top: 110px !important; }
  .mr-sm-110,
  .mx-sm-110 {
    margin-right: 110px !important; }
  .mb-sm-110,
  .my-sm-110 {
    margin-bottom: 110px !important; }
  .ml-sm-110,
  .mx-sm-110 {
    margin-left: 110px !important; }
  .m-sm-120 {
    margin: 120px !important; }
  .mt-sm-120,
  .my-sm-120 {
    margin-top: 120px !important; }
  .mr-sm-120,
  .mx-sm-120 {
    margin-right: 120px !important; }
  .mb-sm-120,
  .my-sm-120 {
    margin-bottom: 120px !important; }
  .ml-sm-120,
  .mx-sm-120 {
    margin-left: 120px !important; }
  .m-sm-130 {
    margin: 130px !important; }
  .mt-sm-130,
  .my-sm-130 {
    margin-top: 130px !important; }
  .mr-sm-130,
  .mx-sm-130 {
    margin-right: 130px !important; }
  .mb-sm-130,
  .my-sm-130 {
    margin-bottom: 130px !important; }
  .ml-sm-130,
  .mx-sm-130 {
    margin-left: 130px !important; }
  .m-sm-140 {
    margin: 140px !important; }
  .mt-sm-140,
  .my-sm-140 {
    margin-top: 140px !important; }
  .mr-sm-140,
  .mx-sm-140 {
    margin-right: 140px !important; }
  .mb-sm-140,
  .my-sm-140 {
    margin-bottom: 140px !important; }
  .ml-sm-140,
  .mx-sm-140 {
    margin-left: 140px !important; }
  .m-sm-150 {
    margin: 150px !important; }
  .mt-sm-150,
  .my-sm-150 {
    margin-top: 150px !important; }
  .mr-sm-150,
  .mx-sm-150 {
    margin-right: 150px !important; }
  .mb-sm-150,
  .my-sm-150 {
    margin-bottom: 150px !important; }
  .ml-sm-150,
  .mx-sm-150 {
    margin-left: 150px !important; }
  .m-sm-160 {
    margin: 160px !important; }
  .mt-sm-160,
  .my-sm-160 {
    margin-top: 160px !important; }
  .mr-sm-160,
  .mx-sm-160 {
    margin-right: 160px !important; }
  .mb-sm-160,
  .my-sm-160 {
    margin-bottom: 160px !important; }
  .ml-sm-160,
  .mx-sm-160 {
    margin-left: 160px !important; }
  .m-sm-170 {
    margin: 170px !important; }
  .mt-sm-170,
  .my-sm-170 {
    margin-top: 170px !important; }
  .mr-sm-170,
  .mx-sm-170 {
    margin-right: 170px !important; }
  .mb-sm-170,
  .my-sm-170 {
    margin-bottom: 170px !important; }
  .ml-sm-170,
  .mx-sm-170 {
    margin-left: 170px !important; }
  .m-sm-180 {
    margin: 180px !important; }
  .mt-sm-180,
  .my-sm-180 {
    margin-top: 180px !important; }
  .mr-sm-180,
  .mx-sm-180 {
    margin-right: 180px !important; }
  .mb-sm-180,
  .my-sm-180 {
    margin-bottom: 180px !important; }
  .ml-sm-180,
  .mx-sm-180 {
    margin-left: 180px !important; }
  .m-sm-190 {
    margin: 190px !important; }
  .mt-sm-190,
  .my-sm-190 {
    margin-top: 190px !important; }
  .mr-sm-190,
  .mx-sm-190 {
    margin-right: 190px !important; }
  .mb-sm-190,
  .my-sm-190 {
    margin-bottom: 190px !important; }
  .ml-sm-190,
  .mx-sm-190 {
    margin-left: 190px !important; }
  .m-sm-200 {
    margin: 200px !important; }
  .mt-sm-200,
  .my-sm-200 {
    margin-top: 200px !important; }
  .mr-sm-200,
  .mx-sm-200 {
    margin-right: 200px !important; }
  .mb-sm-200,
  .my-sm-200 {
    margin-bottom: 200px !important; }
  .ml-sm-200,
  .mx-sm-200 {
    margin-left: 200px !important; }
  .p-sm-0 {
    padding: 0px !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0px !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0px !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0px !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0px !important; }
  .p-sm-1 {
    padding: 1px !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 1px !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 1px !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 1px !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 1px !important; }
  .p-sm-2 {
    padding: 2px !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 2px !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 2px !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 2px !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 2px !important; }
  .p-sm-3 {
    padding: 3px !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 3px !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 3px !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 3px !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 3px !important; }
  .p-sm-4 {
    padding: 4px !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 4px !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 4px !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 4px !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 4px !important; }
  .p-sm-5 {
    padding: 5px !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 5px !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 5px !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 5px !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 5px !important; }
  .p-sm-6 {
    padding: 6px !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 6px !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 6px !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 6px !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 6px !important; }
  .p-sm-7 {
    padding: 7px !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 7px !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 7px !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 7px !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 7px !important; }
  .p-sm-8 {
    padding: 8px !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 8px !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 8px !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 8px !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 8px !important; }
  .p-sm-9 {
    padding: 9px !important; }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 9px !important; }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 9px !important; }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 9px !important; }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 9px !important; }
  .p-sm-10 {
    padding: 10px !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 10px !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 10px !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 10px !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 10px !important; }
  .p-sm-11 {
    padding: 11px !important; }
  .pt-sm-11,
  .py-sm-11 {
    padding-top: 11px !important; }
  .pr-sm-11,
  .px-sm-11 {
    padding-right: 11px !important; }
  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 11px !important; }
  .pl-sm-11,
  .px-sm-11 {
    padding-left: 11px !important; }
  .p-sm-12 {
    padding: 12px !important; }
  .pt-sm-12,
  .py-sm-12 {
    padding-top: 12px !important; }
  .pr-sm-12,
  .px-sm-12 {
    padding-right: 12px !important; }
  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 12px !important; }
  .pl-sm-12,
  .px-sm-12 {
    padding-left: 12px !important; }
  .p-sm-13 {
    padding: 13px !important; }
  .pt-sm-13,
  .py-sm-13 {
    padding-top: 13px !important; }
  .pr-sm-13,
  .px-sm-13 {
    padding-right: 13px !important; }
  .pb-sm-13,
  .py-sm-13 {
    padding-bottom: 13px !important; }
  .pl-sm-13,
  .px-sm-13 {
    padding-left: 13px !important; }
  .p-sm-14 {
    padding: 14px !important; }
  .pt-sm-14,
  .py-sm-14 {
    padding-top: 14px !important; }
  .pr-sm-14,
  .px-sm-14 {
    padding-right: 14px !important; }
  .pb-sm-14,
  .py-sm-14 {
    padding-bottom: 14px !important; }
  .pl-sm-14,
  .px-sm-14 {
    padding-left: 14px !important; }
  .p-sm-15 {
    padding: 15px !important; }
  .pt-sm-15,
  .py-sm-15 {
    padding-top: 15px !important; }
  .pr-sm-15,
  .px-sm-15 {
    padding-right: 15px !important; }
  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 15px !important; }
  .pl-sm-15,
  .px-sm-15 {
    padding-left: 15px !important; }
  .p-sm-16 {
    padding: 16px !important; }
  .pt-sm-16,
  .py-sm-16 {
    padding-top: 16px !important; }
  .pr-sm-16,
  .px-sm-16 {
    padding-right: 16px !important; }
  .pb-sm-16,
  .py-sm-16 {
    padding-bottom: 16px !important; }
  .pl-sm-16,
  .px-sm-16 {
    padding-left: 16px !important; }
  .p-sm-17 {
    padding: 17px !important; }
  .pt-sm-17,
  .py-sm-17 {
    padding-top: 17px !important; }
  .pr-sm-17,
  .px-sm-17 {
    padding-right: 17px !important; }
  .pb-sm-17,
  .py-sm-17 {
    padding-bottom: 17px !important; }
  .pl-sm-17,
  .px-sm-17 {
    padding-left: 17px !important; }
  .p-sm-18 {
    padding: 18px !important; }
  .pt-sm-18,
  .py-sm-18 {
    padding-top: 18px !important; }
  .pr-sm-18,
  .px-sm-18 {
    padding-right: 18px !important; }
  .pb-sm-18,
  .py-sm-18 {
    padding-bottom: 18px !important; }
  .pl-sm-18,
  .px-sm-18 {
    padding-left: 18px !important; }
  .p-sm-19 {
    padding: 19px !important; }
  .pt-sm-19,
  .py-sm-19 {
    padding-top: 19px !important; }
  .pr-sm-19,
  .px-sm-19 {
    padding-right: 19px !important; }
  .pb-sm-19,
  .py-sm-19 {
    padding-bottom: 19px !important; }
  .pl-sm-19,
  .px-sm-19 {
    padding-left: 19px !important; }
  .p-sm-20 {
    padding: 20px !important; }
  .pt-sm-20,
  .py-sm-20 {
    padding-top: 20px !important; }
  .pr-sm-20,
  .px-sm-20 {
    padding-right: 20px !important; }
  .pb-sm-20,
  .py-sm-20 {
    padding-bottom: 20px !important; }
  .pl-sm-20,
  .px-sm-20 {
    padding-left: 20px !important; }
  .p-sm-25 {
    padding: 25px !important; }
  .pt-sm-25,
  .py-sm-25 {
    padding-top: 25px !important; }
  .pr-sm-25,
  .px-sm-25 {
    padding-right: 25px !important; }
  .pb-sm-25,
  .py-sm-25 {
    padding-bottom: 25px !important; }
  .pl-sm-25,
  .px-sm-25 {
    padding-left: 25px !important; }
  .p-sm-30 {
    padding: 30px !important; }
  .pt-sm-30,
  .py-sm-30 {
    padding-top: 30px !important; }
  .pr-sm-30,
  .px-sm-30 {
    padding-right: 30px !important; }
  .pb-sm-30,
  .py-sm-30 {
    padding-bottom: 30px !important; }
  .pl-sm-30,
  .px-sm-30 {
    padding-left: 30px !important; }
  .p-sm-35 {
    padding: 35px !important; }
  .pt-sm-35,
  .py-sm-35 {
    padding-top: 35px !important; }
  .pr-sm-35,
  .px-sm-35 {
    padding-right: 35px !important; }
  .pb-sm-35,
  .py-sm-35 {
    padding-bottom: 35px !important; }
  .pl-sm-35,
  .px-sm-35 {
    padding-left: 35px !important; }
  .p-sm-40 {
    padding: 40px !important; }
  .pt-sm-40,
  .py-sm-40 {
    padding-top: 40px !important; }
  .pr-sm-40,
  .px-sm-40 {
    padding-right: 40px !important; }
  .pb-sm-40,
  .py-sm-40 {
    padding-bottom: 40px !important; }
  .pl-sm-40,
  .px-sm-40 {
    padding-left: 40px !important; }
  .p-sm-45 {
    padding: 45px !important; }
  .pt-sm-45,
  .py-sm-45 {
    padding-top: 45px !important; }
  .pr-sm-45,
  .px-sm-45 {
    padding-right: 45px !important; }
  .pb-sm-45,
  .py-sm-45 {
    padding-bottom: 45px !important; }
  .pl-sm-45,
  .px-sm-45 {
    padding-left: 45px !important; }
  .p-sm-50 {
    padding: 50px !important; }
  .pt-sm-50,
  .py-sm-50 {
    padding-top: 50px !important; }
  .pr-sm-50,
  .px-sm-50 {
    padding-right: 50px !important; }
  .pb-sm-50,
  .py-sm-50 {
    padding-bottom: 50px !important; }
  .pl-sm-50,
  .px-sm-50 {
    padding-left: 50px !important; }
  .p-sm-55 {
    padding: 55px !important; }
  .pt-sm-55,
  .py-sm-55 {
    padding-top: 55px !important; }
  .pr-sm-55,
  .px-sm-55 {
    padding-right: 55px !important; }
  .pb-sm-55,
  .py-sm-55 {
    padding-bottom: 55px !important; }
  .pl-sm-55,
  .px-sm-55 {
    padding-left: 55px !important; }
  .p-sm-60 {
    padding: 60px !important; }
  .pt-sm-60,
  .py-sm-60 {
    padding-top: 60px !important; }
  .pr-sm-60,
  .px-sm-60 {
    padding-right: 60px !important; }
  .pb-sm-60,
  .py-sm-60 {
    padding-bottom: 60px !important; }
  .pl-sm-60,
  .px-sm-60 {
    padding-left: 60px !important; }
  .p-sm-65 {
    padding: 65px !important; }
  .pt-sm-65,
  .py-sm-65 {
    padding-top: 65px !important; }
  .pr-sm-65,
  .px-sm-65 {
    padding-right: 65px !important; }
  .pb-sm-65,
  .py-sm-65 {
    padding-bottom: 65px !important; }
  .pl-sm-65,
  .px-sm-65 {
    padding-left: 65px !important; }
  .p-sm-70 {
    padding: 70px !important; }
  .pt-sm-70,
  .py-sm-70 {
    padding-top: 70px !important; }
  .pr-sm-70,
  .px-sm-70 {
    padding-right: 70px !important; }
  .pb-sm-70,
  .py-sm-70 {
    padding-bottom: 70px !important; }
  .pl-sm-70,
  .px-sm-70 {
    padding-left: 70px !important; }
  .p-sm-75 {
    padding: 75px !important; }
  .pt-sm-75,
  .py-sm-75 {
    padding-top: 75px !important; }
  .pr-sm-75,
  .px-sm-75 {
    padding-right: 75px !important; }
  .pb-sm-75,
  .py-sm-75 {
    padding-bottom: 75px !important; }
  .pl-sm-75,
  .px-sm-75 {
    padding-left: 75px !important; }
  .p-sm-80 {
    padding: 80px !important; }
  .pt-sm-80,
  .py-sm-80 {
    padding-top: 80px !important; }
  .pr-sm-80,
  .px-sm-80 {
    padding-right: 80px !important; }
  .pb-sm-80,
  .py-sm-80 {
    padding-bottom: 80px !important; }
  .pl-sm-80,
  .px-sm-80 {
    padding-left: 80px !important; }
  .p-sm-85 {
    padding: 85px !important; }
  .pt-sm-85,
  .py-sm-85 {
    padding-top: 85px !important; }
  .pr-sm-85,
  .px-sm-85 {
    padding-right: 85px !important; }
  .pb-sm-85,
  .py-sm-85 {
    padding-bottom: 85px !important; }
  .pl-sm-85,
  .px-sm-85 {
    padding-left: 85px !important; }
  .p-sm-90 {
    padding: 90px !important; }
  .pt-sm-90,
  .py-sm-90 {
    padding-top: 90px !important; }
  .pr-sm-90,
  .px-sm-90 {
    padding-right: 90px !important; }
  .pb-sm-90,
  .py-sm-90 {
    padding-bottom: 90px !important; }
  .pl-sm-90,
  .px-sm-90 {
    padding-left: 90px !important; }
  .p-sm-95 {
    padding: 95px !important; }
  .pt-sm-95,
  .py-sm-95 {
    padding-top: 95px !important; }
  .pr-sm-95,
  .px-sm-95 {
    padding-right: 95px !important; }
  .pb-sm-95,
  .py-sm-95 {
    padding-bottom: 95px !important; }
  .pl-sm-95,
  .px-sm-95 {
    padding-left: 95px !important; }
  .p-sm-100 {
    padding: 100px !important; }
  .pt-sm-100,
  .py-sm-100 {
    padding-top: 100px !important; }
  .pr-sm-100,
  .px-sm-100 {
    padding-right: 100px !important; }
  .pb-sm-100,
  .py-sm-100 {
    padding-bottom: 100px !important; }
  .pl-sm-100,
  .px-sm-100 {
    padding-left: 100px !important; }
  .p-sm-110 {
    padding: 110px !important; }
  .pt-sm-110,
  .py-sm-110 {
    padding-top: 110px !important; }
  .pr-sm-110,
  .px-sm-110 {
    padding-right: 110px !important; }
  .pb-sm-110,
  .py-sm-110 {
    padding-bottom: 110px !important; }
  .pl-sm-110,
  .px-sm-110 {
    padding-left: 110px !important; }
  .p-sm-120 {
    padding: 120px !important; }
  .pt-sm-120,
  .py-sm-120 {
    padding-top: 120px !important; }
  .pr-sm-120,
  .px-sm-120 {
    padding-right: 120px !important; }
  .pb-sm-120,
  .py-sm-120 {
    padding-bottom: 120px !important; }
  .pl-sm-120,
  .px-sm-120 {
    padding-left: 120px !important; }
  .p-sm-130 {
    padding: 130px !important; }
  .pt-sm-130,
  .py-sm-130 {
    padding-top: 130px !important; }
  .pr-sm-130,
  .px-sm-130 {
    padding-right: 130px !important; }
  .pb-sm-130,
  .py-sm-130 {
    padding-bottom: 130px !important; }
  .pl-sm-130,
  .px-sm-130 {
    padding-left: 130px !important; }
  .p-sm-140 {
    padding: 140px !important; }
  .pt-sm-140,
  .py-sm-140 {
    padding-top: 140px !important; }
  .pr-sm-140,
  .px-sm-140 {
    padding-right: 140px !important; }
  .pb-sm-140,
  .py-sm-140 {
    padding-bottom: 140px !important; }
  .pl-sm-140,
  .px-sm-140 {
    padding-left: 140px !important; }
  .p-sm-150 {
    padding: 150px !important; }
  .pt-sm-150,
  .py-sm-150 {
    padding-top: 150px !important; }
  .pr-sm-150,
  .px-sm-150 {
    padding-right: 150px !important; }
  .pb-sm-150,
  .py-sm-150 {
    padding-bottom: 150px !important; }
  .pl-sm-150,
  .px-sm-150 {
    padding-left: 150px !important; }
  .p-sm-160 {
    padding: 160px !important; }
  .pt-sm-160,
  .py-sm-160 {
    padding-top: 160px !important; }
  .pr-sm-160,
  .px-sm-160 {
    padding-right: 160px !important; }
  .pb-sm-160,
  .py-sm-160 {
    padding-bottom: 160px !important; }
  .pl-sm-160,
  .px-sm-160 {
    padding-left: 160px !important; }
  .p-sm-170 {
    padding: 170px !important; }
  .pt-sm-170,
  .py-sm-170 {
    padding-top: 170px !important; }
  .pr-sm-170,
  .px-sm-170 {
    padding-right: 170px !important; }
  .pb-sm-170,
  .py-sm-170 {
    padding-bottom: 170px !important; }
  .pl-sm-170,
  .px-sm-170 {
    padding-left: 170px !important; }
  .p-sm-180 {
    padding: 180px !important; }
  .pt-sm-180,
  .py-sm-180 {
    padding-top: 180px !important; }
  .pr-sm-180,
  .px-sm-180 {
    padding-right: 180px !important; }
  .pb-sm-180,
  .py-sm-180 {
    padding-bottom: 180px !important; }
  .pl-sm-180,
  .px-sm-180 {
    padding-left: 180px !important; }
  .p-sm-190 {
    padding: 190px !important; }
  .pt-sm-190,
  .py-sm-190 {
    padding-top: 190px !important; }
  .pr-sm-190,
  .px-sm-190 {
    padding-right: 190px !important; }
  .pb-sm-190,
  .py-sm-190 {
    padding-bottom: 190px !important; }
  .pl-sm-190,
  .px-sm-190 {
    padding-left: 190px !important; }
  .p-sm-200 {
    padding: 200px !important; }
  .pt-sm-200,
  .py-sm-200 {
    padding-top: 200px !important; }
  .pr-sm-200,
  .px-sm-200 {
    padding-right: 200px !important; }
  .pb-sm-200,
  .py-sm-200 {
    padding-bottom: 200px !important; }
  .pl-sm-200,
  .px-sm-200 {
    padding-left: 200px !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0px !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0px !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0px !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0px !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0px !important; }
  .m-md-1 {
    margin: 1px !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 1px !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 1px !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 1px !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 1px !important; }
  .m-md-2 {
    margin: 2px !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 2px !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 2px !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 2px !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 2px !important; }
  .m-md-3 {
    margin: 3px !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 3px !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 3px !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 3px !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 3px !important; }
  .m-md-4 {
    margin: 4px !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 4px !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 4px !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 4px !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 4px !important; }
  .m-md-5 {
    margin: 5px !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 5px !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 5px !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 5px !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 5px !important; }
  .m-md-6 {
    margin: 6px !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 6px !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 6px !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 6px !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 6px !important; }
  .m-md-7 {
    margin: 7px !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 7px !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 7px !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 7px !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 7px !important; }
  .m-md-8 {
    margin: 8px !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 8px !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 8px !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 8px !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 8px !important; }
  .m-md-9 {
    margin: 9px !important; }
  .mt-md-9,
  .my-md-9 {
    margin-top: 9px !important; }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 9px !important; }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 9px !important; }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 9px !important; }
  .m-md-10 {
    margin: 10px !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 10px !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 10px !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 10px !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 10px !important; }
  .m-md-11 {
    margin: 11px !important; }
  .mt-md-11,
  .my-md-11 {
    margin-top: 11px !important; }
  .mr-md-11,
  .mx-md-11 {
    margin-right: 11px !important; }
  .mb-md-11,
  .my-md-11 {
    margin-bottom: 11px !important; }
  .ml-md-11,
  .mx-md-11 {
    margin-left: 11px !important; }
  .m-md-12 {
    margin: 12px !important; }
  .mt-md-12,
  .my-md-12 {
    margin-top: 12px !important; }
  .mr-md-12,
  .mx-md-12 {
    margin-right: 12px !important; }
  .mb-md-12,
  .my-md-12 {
    margin-bottom: 12px !important; }
  .ml-md-12,
  .mx-md-12 {
    margin-left: 12px !important; }
  .m-md-13 {
    margin: 13px !important; }
  .mt-md-13,
  .my-md-13 {
    margin-top: 13px !important; }
  .mr-md-13,
  .mx-md-13 {
    margin-right: 13px !important; }
  .mb-md-13,
  .my-md-13 {
    margin-bottom: 13px !important; }
  .ml-md-13,
  .mx-md-13 {
    margin-left: 13px !important; }
  .m-md-14 {
    margin: 14px !important; }
  .mt-md-14,
  .my-md-14 {
    margin-top: 14px !important; }
  .mr-md-14,
  .mx-md-14 {
    margin-right: 14px !important; }
  .mb-md-14,
  .my-md-14 {
    margin-bottom: 14px !important; }
  .ml-md-14,
  .mx-md-14 {
    margin-left: 14px !important; }
  .m-md-15 {
    margin: 15px !important; }
  .mt-md-15,
  .my-md-15 {
    margin-top: 15px !important; }
  .mr-md-15,
  .mx-md-15 {
    margin-right: 15px !important; }
  .mb-md-15,
  .my-md-15 {
    margin-bottom: 15px !important; }
  .ml-md-15,
  .mx-md-15 {
    margin-left: 15px !important; }
  .m-md-16 {
    margin: 16px !important; }
  .mt-md-16,
  .my-md-16 {
    margin-top: 16px !important; }
  .mr-md-16,
  .mx-md-16 {
    margin-right: 16px !important; }
  .mb-md-16,
  .my-md-16 {
    margin-bottom: 16px !important; }
  .ml-md-16,
  .mx-md-16 {
    margin-left: 16px !important; }
  .m-md-17 {
    margin: 17px !important; }
  .mt-md-17,
  .my-md-17 {
    margin-top: 17px !important; }
  .mr-md-17,
  .mx-md-17 {
    margin-right: 17px !important; }
  .mb-md-17,
  .my-md-17 {
    margin-bottom: 17px !important; }
  .ml-md-17,
  .mx-md-17 {
    margin-left: 17px !important; }
  .m-md-18 {
    margin: 18px !important; }
  .mt-md-18,
  .my-md-18 {
    margin-top: 18px !important; }
  .mr-md-18,
  .mx-md-18 {
    margin-right: 18px !important; }
  .mb-md-18,
  .my-md-18 {
    margin-bottom: 18px !important; }
  .ml-md-18,
  .mx-md-18 {
    margin-left: 18px !important; }
  .m-md-19 {
    margin: 19px !important; }
  .mt-md-19,
  .my-md-19 {
    margin-top: 19px !important; }
  .mr-md-19,
  .mx-md-19 {
    margin-right: 19px !important; }
  .mb-md-19,
  .my-md-19 {
    margin-bottom: 19px !important; }
  .ml-md-19,
  .mx-md-19 {
    margin-left: 19px !important; }
  .m-md-20 {
    margin: 20px !important; }
  .mt-md-20,
  .my-md-20 {
    margin-top: 20px !important; }
  .mr-md-20,
  .mx-md-20 {
    margin-right: 20px !important; }
  .mb-md-20,
  .my-md-20 {
    margin-bottom: 20px !important; }
  .ml-md-20,
  .mx-md-20 {
    margin-left: 20px !important; }
  .m-md-25 {
    margin: 25px !important; }
  .mt-md-25,
  .my-md-25 {
    margin-top: 25px !important; }
  .mr-md-25,
  .mx-md-25 {
    margin-right: 25px !important; }
  .mb-md-25,
  .my-md-25 {
    margin-bottom: 25px !important; }
  .ml-md-25,
  .mx-md-25 {
    margin-left: 25px !important; }
  .m-md-30 {
    margin: 30px !important; }
  .mt-md-30,
  .my-md-30 {
    margin-top: 30px !important; }
  .mr-md-30,
  .mx-md-30 {
    margin-right: 30px !important; }
  .mb-md-30,
  .my-md-30 {
    margin-bottom: 30px !important; }
  .ml-md-30,
  .mx-md-30 {
    margin-left: 30px !important; }
  .m-md-35 {
    margin: 35px !important; }
  .mt-md-35,
  .my-md-35 {
    margin-top: 35px !important; }
  .mr-md-35,
  .mx-md-35 {
    margin-right: 35px !important; }
  .mb-md-35,
  .my-md-35 {
    margin-bottom: 35px !important; }
  .ml-md-35,
  .mx-md-35 {
    margin-left: 35px !important; }
  .m-md-40 {
    margin: 40px !important; }
  .mt-md-40,
  .my-md-40 {
    margin-top: 40px !important; }
  .mr-md-40,
  .mx-md-40 {
    margin-right: 40px !important; }
  .mb-md-40,
  .my-md-40 {
    margin-bottom: 40px !important; }
  .ml-md-40,
  .mx-md-40 {
    margin-left: 40px !important; }
  .m-md-45 {
    margin: 45px !important; }
  .mt-md-45,
  .my-md-45 {
    margin-top: 45px !important; }
  .mr-md-45,
  .mx-md-45 {
    margin-right: 45px !important; }
  .mb-md-45,
  .my-md-45 {
    margin-bottom: 45px !important; }
  .ml-md-45,
  .mx-md-45 {
    margin-left: 45px !important; }
  .m-md-50 {
    margin: 50px !important; }
  .mt-md-50,
  .my-md-50 {
    margin-top: 50px !important; }
  .mr-md-50,
  .mx-md-50 {
    margin-right: 50px !important; }
  .mb-md-50,
  .my-md-50 {
    margin-bottom: 50px !important; }
  .ml-md-50,
  .mx-md-50 {
    margin-left: 50px !important; }
  .m-md-55 {
    margin: 55px !important; }
  .mt-md-55,
  .my-md-55 {
    margin-top: 55px !important; }
  .mr-md-55,
  .mx-md-55 {
    margin-right: 55px !important; }
  .mb-md-55,
  .my-md-55 {
    margin-bottom: 55px !important; }
  .ml-md-55,
  .mx-md-55 {
    margin-left: 55px !important; }
  .m-md-60 {
    margin: 60px !important; }
  .mt-md-60,
  .my-md-60 {
    margin-top: 60px !important; }
  .mr-md-60,
  .mx-md-60 {
    margin-right: 60px !important; }
  .mb-md-60,
  .my-md-60 {
    margin-bottom: 60px !important; }
  .ml-md-60,
  .mx-md-60 {
    margin-left: 60px !important; }
  .m-md-65 {
    margin: 65px !important; }
  .mt-md-65,
  .my-md-65 {
    margin-top: 65px !important; }
  .mr-md-65,
  .mx-md-65 {
    margin-right: 65px !important; }
  .mb-md-65,
  .my-md-65 {
    margin-bottom: 65px !important; }
  .ml-md-65,
  .mx-md-65 {
    margin-left: 65px !important; }
  .m-md-70 {
    margin: 70px !important; }
  .mt-md-70,
  .my-md-70 {
    margin-top: 70px !important; }
  .mr-md-70,
  .mx-md-70 {
    margin-right: 70px !important; }
  .mb-md-70,
  .my-md-70 {
    margin-bottom: 70px !important; }
  .ml-md-70,
  .mx-md-70 {
    margin-left: 70px !important; }
  .m-md-75 {
    margin: 75px !important; }
  .mt-md-75,
  .my-md-75 {
    margin-top: 75px !important; }
  .mr-md-75,
  .mx-md-75 {
    margin-right: 75px !important; }
  .mb-md-75,
  .my-md-75 {
    margin-bottom: 75px !important; }
  .ml-md-75,
  .mx-md-75 {
    margin-left: 75px !important; }
  .m-md-80 {
    margin: 80px !important; }
  .mt-md-80,
  .my-md-80 {
    margin-top: 80px !important; }
  .mr-md-80,
  .mx-md-80 {
    margin-right: 80px !important; }
  .mb-md-80,
  .my-md-80 {
    margin-bottom: 80px !important; }
  .ml-md-80,
  .mx-md-80 {
    margin-left: 80px !important; }
  .m-md-85 {
    margin: 85px !important; }
  .mt-md-85,
  .my-md-85 {
    margin-top: 85px !important; }
  .mr-md-85,
  .mx-md-85 {
    margin-right: 85px !important; }
  .mb-md-85,
  .my-md-85 {
    margin-bottom: 85px !important; }
  .ml-md-85,
  .mx-md-85 {
    margin-left: 85px !important; }
  .m-md-90 {
    margin: 90px !important; }
  .mt-md-90,
  .my-md-90 {
    margin-top: 90px !important; }
  .mr-md-90,
  .mx-md-90 {
    margin-right: 90px !important; }
  .mb-md-90,
  .my-md-90 {
    margin-bottom: 90px !important; }
  .ml-md-90,
  .mx-md-90 {
    margin-left: 90px !important; }
  .m-md-95 {
    margin: 95px !important; }
  .mt-md-95,
  .my-md-95 {
    margin-top: 95px !important; }
  .mr-md-95,
  .mx-md-95 {
    margin-right: 95px !important; }
  .mb-md-95,
  .my-md-95 {
    margin-bottom: 95px !important; }
  .ml-md-95,
  .mx-md-95 {
    margin-left: 95px !important; }
  .m-md-100 {
    margin: 100px !important; }
  .mt-md-100,
  .my-md-100 {
    margin-top: 100px !important; }
  .mr-md-100,
  .mx-md-100 {
    margin-right: 100px !important; }
  .mb-md-100,
  .my-md-100 {
    margin-bottom: 100px !important; }
  .ml-md-100,
  .mx-md-100 {
    margin-left: 100px !important; }
  .m-md-110 {
    margin: 110px !important; }
  .mt-md-110,
  .my-md-110 {
    margin-top: 110px !important; }
  .mr-md-110,
  .mx-md-110 {
    margin-right: 110px !important; }
  .mb-md-110,
  .my-md-110 {
    margin-bottom: 110px !important; }
  .ml-md-110,
  .mx-md-110 {
    margin-left: 110px !important; }
  .m-md-120 {
    margin: 120px !important; }
  .mt-md-120,
  .my-md-120 {
    margin-top: 120px !important; }
  .mr-md-120,
  .mx-md-120 {
    margin-right: 120px !important; }
  .mb-md-120,
  .my-md-120 {
    margin-bottom: 120px !important; }
  .ml-md-120,
  .mx-md-120 {
    margin-left: 120px !important; }
  .m-md-130 {
    margin: 130px !important; }
  .mt-md-130,
  .my-md-130 {
    margin-top: 130px !important; }
  .mr-md-130,
  .mx-md-130 {
    margin-right: 130px !important; }
  .mb-md-130,
  .my-md-130 {
    margin-bottom: 130px !important; }
  .ml-md-130,
  .mx-md-130 {
    margin-left: 130px !important; }
  .m-md-140 {
    margin: 140px !important; }
  .mt-md-140,
  .my-md-140 {
    margin-top: 140px !important; }
  .mr-md-140,
  .mx-md-140 {
    margin-right: 140px !important; }
  .mb-md-140,
  .my-md-140 {
    margin-bottom: 140px !important; }
  .ml-md-140,
  .mx-md-140 {
    margin-left: 140px !important; }
  .m-md-150 {
    margin: 150px !important; }
  .mt-md-150,
  .my-md-150 {
    margin-top: 150px !important; }
  .mr-md-150,
  .mx-md-150 {
    margin-right: 150px !important; }
  .mb-md-150,
  .my-md-150 {
    margin-bottom: 150px !important; }
  .ml-md-150,
  .mx-md-150 {
    margin-left: 150px !important; }
  .m-md-160 {
    margin: 160px !important; }
  .mt-md-160,
  .my-md-160 {
    margin-top: 160px !important; }
  .mr-md-160,
  .mx-md-160 {
    margin-right: 160px !important; }
  .mb-md-160,
  .my-md-160 {
    margin-bottom: 160px !important; }
  .ml-md-160,
  .mx-md-160 {
    margin-left: 160px !important; }
  .m-md-170 {
    margin: 170px !important; }
  .mt-md-170,
  .my-md-170 {
    margin-top: 170px !important; }
  .mr-md-170,
  .mx-md-170 {
    margin-right: 170px !important; }
  .mb-md-170,
  .my-md-170 {
    margin-bottom: 170px !important; }
  .ml-md-170,
  .mx-md-170 {
    margin-left: 170px !important; }
  .m-md-180 {
    margin: 180px !important; }
  .mt-md-180,
  .my-md-180 {
    margin-top: 180px !important; }
  .mr-md-180,
  .mx-md-180 {
    margin-right: 180px !important; }
  .mb-md-180,
  .my-md-180 {
    margin-bottom: 180px !important; }
  .ml-md-180,
  .mx-md-180 {
    margin-left: 180px !important; }
  .m-md-190 {
    margin: 190px !important; }
  .mt-md-190,
  .my-md-190 {
    margin-top: 190px !important; }
  .mr-md-190,
  .mx-md-190 {
    margin-right: 190px !important; }
  .mb-md-190,
  .my-md-190 {
    margin-bottom: 190px !important; }
  .ml-md-190,
  .mx-md-190 {
    margin-left: 190px !important; }
  .m-md-200 {
    margin: 200px !important; }
  .mt-md-200,
  .my-md-200 {
    margin-top: 200px !important; }
  .mr-md-200,
  .mx-md-200 {
    margin-right: 200px !important; }
  .mb-md-200,
  .my-md-200 {
    margin-bottom: 200px !important; }
  .ml-md-200,
  .mx-md-200 {
    margin-left: 200px !important; }
  .p-md-0 {
    padding: 0px !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0px !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0px !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0px !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0px !important; }
  .p-md-1 {
    padding: 1px !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 1px !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 1px !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 1px !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 1px !important; }
  .p-md-2 {
    padding: 2px !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 2px !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 2px !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 2px !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 2px !important; }
  .p-md-3 {
    padding: 3px !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 3px !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 3px !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 3px !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 3px !important; }
  .p-md-4 {
    padding: 4px !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 4px !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 4px !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 4px !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 4px !important; }
  .p-md-5 {
    padding: 5px !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 5px !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 5px !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 5px !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 5px !important; }
  .p-md-6 {
    padding: 6px !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 6px !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 6px !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 6px !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 6px !important; }
  .p-md-7 {
    padding: 7px !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 7px !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 7px !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 7px !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 7px !important; }
  .p-md-8 {
    padding: 8px !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 8px !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 8px !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 8px !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 8px !important; }
  .p-md-9 {
    padding: 9px !important; }
  .pt-md-9,
  .py-md-9 {
    padding-top: 9px !important; }
  .pr-md-9,
  .px-md-9 {
    padding-right: 9px !important; }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 9px !important; }
  .pl-md-9,
  .px-md-9 {
    padding-left: 9px !important; }
  .p-md-10 {
    padding: 10px !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 10px !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 10px !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 10px !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 10px !important; }
  .p-md-11 {
    padding: 11px !important; }
  .pt-md-11,
  .py-md-11 {
    padding-top: 11px !important; }
  .pr-md-11,
  .px-md-11 {
    padding-right: 11px !important; }
  .pb-md-11,
  .py-md-11 {
    padding-bottom: 11px !important; }
  .pl-md-11,
  .px-md-11 {
    padding-left: 11px !important; }
  .p-md-12 {
    padding: 12px !important; }
  .pt-md-12,
  .py-md-12 {
    padding-top: 12px !important; }
  .pr-md-12,
  .px-md-12 {
    padding-right: 12px !important; }
  .pb-md-12,
  .py-md-12 {
    padding-bottom: 12px !important; }
  .pl-md-12,
  .px-md-12 {
    padding-left: 12px !important; }
  .p-md-13 {
    padding: 13px !important; }
  .pt-md-13,
  .py-md-13 {
    padding-top: 13px !important; }
  .pr-md-13,
  .px-md-13 {
    padding-right: 13px !important; }
  .pb-md-13,
  .py-md-13 {
    padding-bottom: 13px !important; }
  .pl-md-13,
  .px-md-13 {
    padding-left: 13px !important; }
  .p-md-14 {
    padding: 14px !important; }
  .pt-md-14,
  .py-md-14 {
    padding-top: 14px !important; }
  .pr-md-14,
  .px-md-14 {
    padding-right: 14px !important; }
  .pb-md-14,
  .py-md-14 {
    padding-bottom: 14px !important; }
  .pl-md-14,
  .px-md-14 {
    padding-left: 14px !important; }
  .p-md-15 {
    padding: 15px !important; }
  .pt-md-15,
  .py-md-15 {
    padding-top: 15px !important; }
  .pr-md-15,
  .px-md-15 {
    padding-right: 15px !important; }
  .pb-md-15,
  .py-md-15 {
    padding-bottom: 15px !important; }
  .pl-md-15,
  .px-md-15 {
    padding-left: 15px !important; }
  .p-md-16 {
    padding: 16px !important; }
  .pt-md-16,
  .py-md-16 {
    padding-top: 16px !important; }
  .pr-md-16,
  .px-md-16 {
    padding-right: 16px !important; }
  .pb-md-16,
  .py-md-16 {
    padding-bottom: 16px !important; }
  .pl-md-16,
  .px-md-16 {
    padding-left: 16px !important; }
  .p-md-17 {
    padding: 17px !important; }
  .pt-md-17,
  .py-md-17 {
    padding-top: 17px !important; }
  .pr-md-17,
  .px-md-17 {
    padding-right: 17px !important; }
  .pb-md-17,
  .py-md-17 {
    padding-bottom: 17px !important; }
  .pl-md-17,
  .px-md-17 {
    padding-left: 17px !important; }
  .p-md-18 {
    padding: 18px !important; }
  .pt-md-18,
  .py-md-18 {
    padding-top: 18px !important; }
  .pr-md-18,
  .px-md-18 {
    padding-right: 18px !important; }
  .pb-md-18,
  .py-md-18 {
    padding-bottom: 18px !important; }
  .pl-md-18,
  .px-md-18 {
    padding-left: 18px !important; }
  .p-md-19 {
    padding: 19px !important; }
  .pt-md-19,
  .py-md-19 {
    padding-top: 19px !important; }
  .pr-md-19,
  .px-md-19 {
    padding-right: 19px !important; }
  .pb-md-19,
  .py-md-19 {
    padding-bottom: 19px !important; }
  .pl-md-19,
  .px-md-19 {
    padding-left: 19px !important; }
  .p-md-20 {
    padding: 20px !important; }
  .pt-md-20,
  .py-md-20 {
    padding-top: 20px !important; }
  .pr-md-20,
  .px-md-20 {
    padding-right: 20px !important; }
  .pb-md-20,
  .py-md-20 {
    padding-bottom: 20px !important; }
  .pl-md-20,
  .px-md-20 {
    padding-left: 20px !important; }
  .p-md-25 {
    padding: 25px !important; }
  .pt-md-25,
  .py-md-25 {
    padding-top: 25px !important; }
  .pr-md-25,
  .px-md-25 {
    padding-right: 25px !important; }
  .pb-md-25,
  .py-md-25 {
    padding-bottom: 25px !important; }
  .pl-md-25,
  .px-md-25 {
    padding-left: 25px !important; }
  .p-md-30 {
    padding: 30px !important; }
  .pt-md-30,
  .py-md-30 {
    padding-top: 30px !important; }
  .pr-md-30,
  .px-md-30 {
    padding-right: 30px !important; }
  .pb-md-30,
  .py-md-30 {
    padding-bottom: 30px !important; }
  .pl-md-30,
  .px-md-30 {
    padding-left: 30px !important; }
  .p-md-35 {
    padding: 35px !important; }
  .pt-md-35,
  .py-md-35 {
    padding-top: 35px !important; }
  .pr-md-35,
  .px-md-35 {
    padding-right: 35px !important; }
  .pb-md-35,
  .py-md-35 {
    padding-bottom: 35px !important; }
  .pl-md-35,
  .px-md-35 {
    padding-left: 35px !important; }
  .p-md-40 {
    padding: 40px !important; }
  .pt-md-40,
  .py-md-40 {
    padding-top: 40px !important; }
  .pr-md-40,
  .px-md-40 {
    padding-right: 40px !important; }
  .pb-md-40,
  .py-md-40 {
    padding-bottom: 40px !important; }
  .pl-md-40,
  .px-md-40 {
    padding-left: 40px !important; }
  .p-md-45 {
    padding: 45px !important; }
  .pt-md-45,
  .py-md-45 {
    padding-top: 45px !important; }
  .pr-md-45,
  .px-md-45 {
    padding-right: 45px !important; }
  .pb-md-45,
  .py-md-45 {
    padding-bottom: 45px !important; }
  .pl-md-45,
  .px-md-45 {
    padding-left: 45px !important; }
  .p-md-50 {
    padding: 50px !important; }
  .pt-md-50,
  .py-md-50 {
    padding-top: 50px !important; }
  .pr-md-50,
  .px-md-50 {
    padding-right: 50px !important; }
  .pb-md-50,
  .py-md-50 {
    padding-bottom: 50px !important; }
  .pl-md-50,
  .px-md-50 {
    padding-left: 50px !important; }
  .p-md-55 {
    padding: 55px !important; }
  .pt-md-55,
  .py-md-55 {
    padding-top: 55px !important; }
  .pr-md-55,
  .px-md-55 {
    padding-right: 55px !important; }
  .pb-md-55,
  .py-md-55 {
    padding-bottom: 55px !important; }
  .pl-md-55,
  .px-md-55 {
    padding-left: 55px !important; }
  .p-md-60 {
    padding: 60px !important; }
  .pt-md-60,
  .py-md-60 {
    padding-top: 60px !important; }
  .pr-md-60,
  .px-md-60 {
    padding-right: 60px !important; }
  .pb-md-60,
  .py-md-60 {
    padding-bottom: 60px !important; }
  .pl-md-60,
  .px-md-60 {
    padding-left: 60px !important; }
  .p-md-65 {
    padding: 65px !important; }
  .pt-md-65,
  .py-md-65 {
    padding-top: 65px !important; }
  .pr-md-65,
  .px-md-65 {
    padding-right: 65px !important; }
  .pb-md-65,
  .py-md-65 {
    padding-bottom: 65px !important; }
  .pl-md-65,
  .px-md-65 {
    padding-left: 65px !important; }
  .p-md-70 {
    padding: 70px !important; }
  .pt-md-70,
  .py-md-70 {
    padding-top: 70px !important; }
  .pr-md-70,
  .px-md-70 {
    padding-right: 70px !important; }
  .pb-md-70,
  .py-md-70 {
    padding-bottom: 70px !important; }
  .pl-md-70,
  .px-md-70 {
    padding-left: 70px !important; }
  .p-md-75 {
    padding: 75px !important; }
  .pt-md-75,
  .py-md-75 {
    padding-top: 75px !important; }
  .pr-md-75,
  .px-md-75 {
    padding-right: 75px !important; }
  .pb-md-75,
  .py-md-75 {
    padding-bottom: 75px !important; }
  .pl-md-75,
  .px-md-75 {
    padding-left: 75px !important; }
  .p-md-80 {
    padding: 80px !important; }
  .pt-md-80,
  .py-md-80 {
    padding-top: 80px !important; }
  .pr-md-80,
  .px-md-80 {
    padding-right: 80px !important; }
  .pb-md-80,
  .py-md-80 {
    padding-bottom: 80px !important; }
  .pl-md-80,
  .px-md-80 {
    padding-left: 80px !important; }
  .p-md-85 {
    padding: 85px !important; }
  .pt-md-85,
  .py-md-85 {
    padding-top: 85px !important; }
  .pr-md-85,
  .px-md-85 {
    padding-right: 85px !important; }
  .pb-md-85,
  .py-md-85 {
    padding-bottom: 85px !important; }
  .pl-md-85,
  .px-md-85 {
    padding-left: 85px !important; }
  .p-md-90 {
    padding: 90px !important; }
  .pt-md-90,
  .py-md-90 {
    padding-top: 90px !important; }
  .pr-md-90,
  .px-md-90 {
    padding-right: 90px !important; }
  .pb-md-90,
  .py-md-90 {
    padding-bottom: 90px !important; }
  .pl-md-90,
  .px-md-90 {
    padding-left: 90px !important; }
  .p-md-95 {
    padding: 95px !important; }
  .pt-md-95,
  .py-md-95 {
    padding-top: 95px !important; }
  .pr-md-95,
  .px-md-95 {
    padding-right: 95px !important; }
  .pb-md-95,
  .py-md-95 {
    padding-bottom: 95px !important; }
  .pl-md-95,
  .px-md-95 {
    padding-left: 95px !important; }
  .p-md-100 {
    padding: 100px !important; }
  .pt-md-100,
  .py-md-100 {
    padding-top: 100px !important; }
  .pr-md-100,
  .px-md-100 {
    padding-right: 100px !important; }
  .pb-md-100,
  .py-md-100 {
    padding-bottom: 100px !important; }
  .pl-md-100,
  .px-md-100 {
    padding-left: 100px !important; }
  .p-md-110 {
    padding: 110px !important; }
  .pt-md-110,
  .py-md-110 {
    padding-top: 110px !important; }
  .pr-md-110,
  .px-md-110 {
    padding-right: 110px !important; }
  .pb-md-110,
  .py-md-110 {
    padding-bottom: 110px !important; }
  .pl-md-110,
  .px-md-110 {
    padding-left: 110px !important; }
  .p-md-120 {
    padding: 120px !important; }
  .pt-md-120,
  .py-md-120 {
    padding-top: 120px !important; }
  .pr-md-120,
  .px-md-120 {
    padding-right: 120px !important; }
  .pb-md-120,
  .py-md-120 {
    padding-bottom: 120px !important; }
  .pl-md-120,
  .px-md-120 {
    padding-left: 120px !important; }
  .p-md-130 {
    padding: 130px !important; }
  .pt-md-130,
  .py-md-130 {
    padding-top: 130px !important; }
  .pr-md-130,
  .px-md-130 {
    padding-right: 130px !important; }
  .pb-md-130,
  .py-md-130 {
    padding-bottom: 130px !important; }
  .pl-md-130,
  .px-md-130 {
    padding-left: 130px !important; }
  .p-md-140 {
    padding: 140px !important; }
  .pt-md-140,
  .py-md-140 {
    padding-top: 140px !important; }
  .pr-md-140,
  .px-md-140 {
    padding-right: 140px !important; }
  .pb-md-140,
  .py-md-140 {
    padding-bottom: 140px !important; }
  .pl-md-140,
  .px-md-140 {
    padding-left: 140px !important; }
  .p-md-150 {
    padding: 150px !important; }
  .pt-md-150,
  .py-md-150 {
    padding-top: 150px !important; }
  .pr-md-150,
  .px-md-150 {
    padding-right: 150px !important; }
  .pb-md-150,
  .py-md-150 {
    padding-bottom: 150px !important; }
  .pl-md-150,
  .px-md-150 {
    padding-left: 150px !important; }
  .p-md-160 {
    padding: 160px !important; }
  .pt-md-160,
  .py-md-160 {
    padding-top: 160px !important; }
  .pr-md-160,
  .px-md-160 {
    padding-right: 160px !important; }
  .pb-md-160,
  .py-md-160 {
    padding-bottom: 160px !important; }
  .pl-md-160,
  .px-md-160 {
    padding-left: 160px !important; }
  .p-md-170 {
    padding: 170px !important; }
  .pt-md-170,
  .py-md-170 {
    padding-top: 170px !important; }
  .pr-md-170,
  .px-md-170 {
    padding-right: 170px !important; }
  .pb-md-170,
  .py-md-170 {
    padding-bottom: 170px !important; }
  .pl-md-170,
  .px-md-170 {
    padding-left: 170px !important; }
  .p-md-180 {
    padding: 180px !important; }
  .pt-md-180,
  .py-md-180 {
    padding-top: 180px !important; }
  .pr-md-180,
  .px-md-180 {
    padding-right: 180px !important; }
  .pb-md-180,
  .py-md-180 {
    padding-bottom: 180px !important; }
  .pl-md-180,
  .px-md-180 {
    padding-left: 180px !important; }
  .p-md-190 {
    padding: 190px !important; }
  .pt-md-190,
  .py-md-190 {
    padding-top: 190px !important; }
  .pr-md-190,
  .px-md-190 {
    padding-right: 190px !important; }
  .pb-md-190,
  .py-md-190 {
    padding-bottom: 190px !important; }
  .pl-md-190,
  .px-md-190 {
    padding-left: 190px !important; }
  .p-md-200 {
    padding: 200px !important; }
  .pt-md-200,
  .py-md-200 {
    padding-top: 200px !important; }
  .pr-md-200,
  .px-md-200 {
    padding-right: 200px !important; }
  .pb-md-200,
  .py-md-200 {
    padding-bottom: 200px !important; }
  .pl-md-200,
  .px-md-200 {
    padding-left: 200px !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0px !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0px !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0px !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0px !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0px !important; }
  .m-lg-1 {
    margin: 1px !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 1px !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 1px !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 1px !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 1px !important; }
  .m-lg-2 {
    margin: 2px !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 2px !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 2px !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 2px !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 2px !important; }
  .m-lg-3 {
    margin: 3px !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 3px !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 3px !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 3px !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 3px !important; }
  .m-lg-4 {
    margin: 4px !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 4px !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 4px !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 4px !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 4px !important; }
  .m-lg-5 {
    margin: 5px !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 5px !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 5px !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 5px !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 5px !important; }
  .m-lg-6 {
    margin: 6px !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 6px !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 6px !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 6px !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 6px !important; }
  .m-lg-7 {
    margin: 7px !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 7px !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 7px !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 7px !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 7px !important; }
  .m-lg-8 {
    margin: 8px !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 8px !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 8px !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 8px !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 8px !important; }
  .m-lg-9 {
    margin: 9px !important; }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 9px !important; }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 9px !important; }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 9px !important; }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 9px !important; }
  .m-lg-10 {
    margin: 10px !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 10px !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 10px !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 10px !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 10px !important; }
  .m-lg-11 {
    margin: 11px !important; }
  .mt-lg-11,
  .my-lg-11 {
    margin-top: 11px !important; }
  .mr-lg-11,
  .mx-lg-11 {
    margin-right: 11px !important; }
  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 11px !important; }
  .ml-lg-11,
  .mx-lg-11 {
    margin-left: 11px !important; }
  .m-lg-12 {
    margin: 12px !important; }
  .mt-lg-12,
  .my-lg-12 {
    margin-top: 12px !important; }
  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 12px !important; }
  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 12px !important; }
  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 12px !important; }
  .m-lg-13 {
    margin: 13px !important; }
  .mt-lg-13,
  .my-lg-13 {
    margin-top: 13px !important; }
  .mr-lg-13,
  .mx-lg-13 {
    margin-right: 13px !important; }
  .mb-lg-13,
  .my-lg-13 {
    margin-bottom: 13px !important; }
  .ml-lg-13,
  .mx-lg-13 {
    margin-left: 13px !important; }
  .m-lg-14 {
    margin: 14px !important; }
  .mt-lg-14,
  .my-lg-14 {
    margin-top: 14px !important; }
  .mr-lg-14,
  .mx-lg-14 {
    margin-right: 14px !important; }
  .mb-lg-14,
  .my-lg-14 {
    margin-bottom: 14px !important; }
  .ml-lg-14,
  .mx-lg-14 {
    margin-left: 14px !important; }
  .m-lg-15 {
    margin: 15px !important; }
  .mt-lg-15,
  .my-lg-15 {
    margin-top: 15px !important; }
  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 15px !important; }
  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 15px !important; }
  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 15px !important; }
  .m-lg-16 {
    margin: 16px !important; }
  .mt-lg-16,
  .my-lg-16 {
    margin-top: 16px !important; }
  .mr-lg-16,
  .mx-lg-16 {
    margin-right: 16px !important; }
  .mb-lg-16,
  .my-lg-16 {
    margin-bottom: 16px !important; }
  .ml-lg-16,
  .mx-lg-16 {
    margin-left: 16px !important; }
  .m-lg-17 {
    margin: 17px !important; }
  .mt-lg-17,
  .my-lg-17 {
    margin-top: 17px !important; }
  .mr-lg-17,
  .mx-lg-17 {
    margin-right: 17px !important; }
  .mb-lg-17,
  .my-lg-17 {
    margin-bottom: 17px !important; }
  .ml-lg-17,
  .mx-lg-17 {
    margin-left: 17px !important; }
  .m-lg-18 {
    margin: 18px !important; }
  .mt-lg-18,
  .my-lg-18 {
    margin-top: 18px !important; }
  .mr-lg-18,
  .mx-lg-18 {
    margin-right: 18px !important; }
  .mb-lg-18,
  .my-lg-18 {
    margin-bottom: 18px !important; }
  .ml-lg-18,
  .mx-lg-18 {
    margin-left: 18px !important; }
  .m-lg-19 {
    margin: 19px !important; }
  .mt-lg-19,
  .my-lg-19 {
    margin-top: 19px !important; }
  .mr-lg-19,
  .mx-lg-19 {
    margin-right: 19px !important; }
  .mb-lg-19,
  .my-lg-19 {
    margin-bottom: 19px !important; }
  .ml-lg-19,
  .mx-lg-19 {
    margin-left: 19px !important; }
  .m-lg-20 {
    margin: 20px !important; }
  .mt-lg-20,
  .my-lg-20 {
    margin-top: 20px !important; }
  .mr-lg-20,
  .mx-lg-20 {
    margin-right: 20px !important; }
  .mb-lg-20,
  .my-lg-20 {
    margin-bottom: 20px !important; }
  .ml-lg-20,
  .mx-lg-20 {
    margin-left: 20px !important; }
  .m-lg-25 {
    margin: 25px !important; }
  .mt-lg-25,
  .my-lg-25 {
    margin-top: 25px !important; }
  .mr-lg-25,
  .mx-lg-25 {
    margin-right: 25px !important; }
  .mb-lg-25,
  .my-lg-25 {
    margin-bottom: 25px !important; }
  .ml-lg-25,
  .mx-lg-25 {
    margin-left: 25px !important; }
  .m-lg-30 {
    margin: 30px !important; }
  .mt-lg-30,
  .my-lg-30 {
    margin-top: 30px !important; }
  .mr-lg-30,
  .mx-lg-30 {
    margin-right: 30px !important; }
  .mb-lg-30,
  .my-lg-30 {
    margin-bottom: 30px !important; }
  .ml-lg-30,
  .mx-lg-30 {
    margin-left: 30px !important; }
  .m-lg-35 {
    margin: 35px !important; }
  .mt-lg-35,
  .my-lg-35 {
    margin-top: 35px !important; }
  .mr-lg-35,
  .mx-lg-35 {
    margin-right: 35px !important; }
  .mb-lg-35,
  .my-lg-35 {
    margin-bottom: 35px !important; }
  .ml-lg-35,
  .mx-lg-35 {
    margin-left: 35px !important; }
  .m-lg-40 {
    margin: 40px !important; }
  .mt-lg-40,
  .my-lg-40 {
    margin-top: 40px !important; }
  .mr-lg-40,
  .mx-lg-40 {
    margin-right: 40px !important; }
  .mb-lg-40,
  .my-lg-40 {
    margin-bottom: 40px !important; }
  .ml-lg-40,
  .mx-lg-40 {
    margin-left: 40px !important; }
  .m-lg-45 {
    margin: 45px !important; }
  .mt-lg-45,
  .my-lg-45 {
    margin-top: 45px !important; }
  .mr-lg-45,
  .mx-lg-45 {
    margin-right: 45px !important; }
  .mb-lg-45,
  .my-lg-45 {
    margin-bottom: 45px !important; }
  .ml-lg-45,
  .mx-lg-45 {
    margin-left: 45px !important; }
  .m-lg-50 {
    margin: 50px !important; }
  .mt-lg-50,
  .my-lg-50 {
    margin-top: 50px !important; }
  .mr-lg-50,
  .mx-lg-50 {
    margin-right: 50px !important; }
  .mb-lg-50,
  .my-lg-50 {
    margin-bottom: 50px !important; }
  .ml-lg-50,
  .mx-lg-50 {
    margin-left: 50px !important; }
  .m-lg-55 {
    margin: 55px !important; }
  .mt-lg-55,
  .my-lg-55 {
    margin-top: 55px !important; }
  .mr-lg-55,
  .mx-lg-55 {
    margin-right: 55px !important; }
  .mb-lg-55,
  .my-lg-55 {
    margin-bottom: 55px !important; }
  .ml-lg-55,
  .mx-lg-55 {
    margin-left: 55px !important; }
  .m-lg-60 {
    margin: 60px !important; }
  .mt-lg-60,
  .my-lg-60 {
    margin-top: 60px !important; }
  .mr-lg-60,
  .mx-lg-60 {
    margin-right: 60px !important; }
  .mb-lg-60,
  .my-lg-60 {
    margin-bottom: 60px !important; }
  .ml-lg-60,
  .mx-lg-60 {
    margin-left: 60px !important; }
  .m-lg-65 {
    margin: 65px !important; }
  .mt-lg-65,
  .my-lg-65 {
    margin-top: 65px !important; }
  .mr-lg-65,
  .mx-lg-65 {
    margin-right: 65px !important; }
  .mb-lg-65,
  .my-lg-65 {
    margin-bottom: 65px !important; }
  .ml-lg-65,
  .mx-lg-65 {
    margin-left: 65px !important; }
  .m-lg-70 {
    margin: 70px !important; }
  .mt-lg-70,
  .my-lg-70 {
    margin-top: 70px !important; }
  .mr-lg-70,
  .mx-lg-70 {
    margin-right: 70px !important; }
  .mb-lg-70,
  .my-lg-70 {
    margin-bottom: 70px !important; }
  .ml-lg-70,
  .mx-lg-70 {
    margin-left: 70px !important; }
  .m-lg-75 {
    margin: 75px !important; }
  .mt-lg-75,
  .my-lg-75 {
    margin-top: 75px !important; }
  .mr-lg-75,
  .mx-lg-75 {
    margin-right: 75px !important; }
  .mb-lg-75,
  .my-lg-75 {
    margin-bottom: 75px !important; }
  .ml-lg-75,
  .mx-lg-75 {
    margin-left: 75px !important; }
  .m-lg-80 {
    margin: 80px !important; }
  .mt-lg-80,
  .my-lg-80 {
    margin-top: 80px !important; }
  .mr-lg-80,
  .mx-lg-80 {
    margin-right: 80px !important; }
  .mb-lg-80,
  .my-lg-80 {
    margin-bottom: 80px !important; }
  .ml-lg-80,
  .mx-lg-80 {
    margin-left: 80px !important; }
  .m-lg-85 {
    margin: 85px !important; }
  .mt-lg-85,
  .my-lg-85 {
    margin-top: 85px !important; }
  .mr-lg-85,
  .mx-lg-85 {
    margin-right: 85px !important; }
  .mb-lg-85,
  .my-lg-85 {
    margin-bottom: 85px !important; }
  .ml-lg-85,
  .mx-lg-85 {
    margin-left: 85px !important; }
  .m-lg-90 {
    margin: 90px !important; }
  .mt-lg-90,
  .my-lg-90 {
    margin-top: 90px !important; }
  .mr-lg-90,
  .mx-lg-90 {
    margin-right: 90px !important; }
  .mb-lg-90,
  .my-lg-90 {
    margin-bottom: 90px !important; }
  .ml-lg-90,
  .mx-lg-90 {
    margin-left: 90px !important; }
  .m-lg-95 {
    margin: 95px !important; }
  .mt-lg-95,
  .my-lg-95 {
    margin-top: 95px !important; }
  .mr-lg-95,
  .mx-lg-95 {
    margin-right: 95px !important; }
  .mb-lg-95,
  .my-lg-95 {
    margin-bottom: 95px !important; }
  .ml-lg-95,
  .mx-lg-95 {
    margin-left: 95px !important; }
  .m-lg-100 {
    margin: 100px !important; }
  .mt-lg-100,
  .my-lg-100 {
    margin-top: 100px !important; }
  .mr-lg-100,
  .mx-lg-100 {
    margin-right: 100px !important; }
  .mb-lg-100,
  .my-lg-100 {
    margin-bottom: 100px !important; }
  .ml-lg-100,
  .mx-lg-100 {
    margin-left: 100px !important; }
  .m-lg-110 {
    margin: 110px !important; }
  .mt-lg-110,
  .my-lg-110 {
    margin-top: 110px !important; }
  .mr-lg-110,
  .mx-lg-110 {
    margin-right: 110px !important; }
  .mb-lg-110,
  .my-lg-110 {
    margin-bottom: 110px !important; }
  .ml-lg-110,
  .mx-lg-110 {
    margin-left: 110px !important; }
  .m-lg-120 {
    margin: 120px !important; }
  .mt-lg-120,
  .my-lg-120 {
    margin-top: 120px !important; }
  .mr-lg-120,
  .mx-lg-120 {
    margin-right: 120px !important; }
  .mb-lg-120,
  .my-lg-120 {
    margin-bottom: 120px !important; }
  .ml-lg-120,
  .mx-lg-120 {
    margin-left: 120px !important; }
  .m-lg-130 {
    margin: 130px !important; }
  .mt-lg-130,
  .my-lg-130 {
    margin-top: 130px !important; }
  .mr-lg-130,
  .mx-lg-130 {
    margin-right: 130px !important; }
  .mb-lg-130,
  .my-lg-130 {
    margin-bottom: 130px !important; }
  .ml-lg-130,
  .mx-lg-130 {
    margin-left: 130px !important; }
  .m-lg-140 {
    margin: 140px !important; }
  .mt-lg-140,
  .my-lg-140 {
    margin-top: 140px !important; }
  .mr-lg-140,
  .mx-lg-140 {
    margin-right: 140px !important; }
  .mb-lg-140,
  .my-lg-140 {
    margin-bottom: 140px !important; }
  .ml-lg-140,
  .mx-lg-140 {
    margin-left: 140px !important; }
  .m-lg-150 {
    margin: 150px !important; }
  .mt-lg-150,
  .my-lg-150 {
    margin-top: 150px !important; }
  .mr-lg-150,
  .mx-lg-150 {
    margin-right: 150px !important; }
  .mb-lg-150,
  .my-lg-150 {
    margin-bottom: 150px !important; }
  .ml-lg-150,
  .mx-lg-150 {
    margin-left: 150px !important; }
  .m-lg-160 {
    margin: 160px !important; }
  .mt-lg-160,
  .my-lg-160 {
    margin-top: 160px !important; }
  .mr-lg-160,
  .mx-lg-160 {
    margin-right: 160px !important; }
  .mb-lg-160,
  .my-lg-160 {
    margin-bottom: 160px !important; }
  .ml-lg-160,
  .mx-lg-160 {
    margin-left: 160px !important; }
  .m-lg-170 {
    margin: 170px !important; }
  .mt-lg-170,
  .my-lg-170 {
    margin-top: 170px !important; }
  .mr-lg-170,
  .mx-lg-170 {
    margin-right: 170px !important; }
  .mb-lg-170,
  .my-lg-170 {
    margin-bottom: 170px !important; }
  .ml-lg-170,
  .mx-lg-170 {
    margin-left: 170px !important; }
  .m-lg-180 {
    margin: 180px !important; }
  .mt-lg-180,
  .my-lg-180 {
    margin-top: 180px !important; }
  .mr-lg-180,
  .mx-lg-180 {
    margin-right: 180px !important; }
  .mb-lg-180,
  .my-lg-180 {
    margin-bottom: 180px !important; }
  .ml-lg-180,
  .mx-lg-180 {
    margin-left: 180px !important; }
  .m-lg-190 {
    margin: 190px !important; }
  .mt-lg-190,
  .my-lg-190 {
    margin-top: 190px !important; }
  .mr-lg-190,
  .mx-lg-190 {
    margin-right: 190px !important; }
  .mb-lg-190,
  .my-lg-190 {
    margin-bottom: 190px !important; }
  .ml-lg-190,
  .mx-lg-190 {
    margin-left: 190px !important; }
  .m-lg-200 {
    margin: 200px !important; }
  .mt-lg-200,
  .my-lg-200 {
    margin-top: 200px !important; }
  .mr-lg-200,
  .mx-lg-200 {
    margin-right: 200px !important; }
  .mb-lg-200,
  .my-lg-200 {
    margin-bottom: 200px !important; }
  .ml-lg-200,
  .mx-lg-200 {
    margin-left: 200px !important; }
  .p-lg-0 {
    padding: 0px !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0px !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0px !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0px !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0px !important; }
  .p-lg-1 {
    padding: 1px !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 1px !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 1px !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 1px !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 1px !important; }
  .p-lg-2 {
    padding: 2px !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 2px !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 2px !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 2px !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 2px !important; }
  .p-lg-3 {
    padding: 3px !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 3px !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 3px !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 3px !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 3px !important; }
  .p-lg-4 {
    padding: 4px !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 4px !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 4px !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 4px !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 4px !important; }
  .p-lg-5 {
    padding: 5px !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 5px !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 5px !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 5px !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 5px !important; }
  .p-lg-6 {
    padding: 6px !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 6px !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 6px !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 6px !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 6px !important; }
  .p-lg-7 {
    padding: 7px !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 7px !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 7px !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 7px !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 7px !important; }
  .p-lg-8 {
    padding: 8px !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 8px !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 8px !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 8px !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 8px !important; }
  .p-lg-9 {
    padding: 9px !important; }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 9px !important; }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 9px !important; }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 9px !important; }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 9px !important; }
  .p-lg-10 {
    padding: 10px !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 10px !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 10px !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 10px !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 10px !important; }
  .p-lg-11 {
    padding: 11px !important; }
  .pt-lg-11,
  .py-lg-11 {
    padding-top: 11px !important; }
  .pr-lg-11,
  .px-lg-11 {
    padding-right: 11px !important; }
  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 11px !important; }
  .pl-lg-11,
  .px-lg-11 {
    padding-left: 11px !important; }
  .p-lg-12 {
    padding: 12px !important; }
  .pt-lg-12,
  .py-lg-12 {
    padding-top: 12px !important; }
  .pr-lg-12,
  .px-lg-12 {
    padding-right: 12px !important; }
  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 12px !important; }
  .pl-lg-12,
  .px-lg-12 {
    padding-left: 12px !important; }
  .p-lg-13 {
    padding: 13px !important; }
  .pt-lg-13,
  .py-lg-13 {
    padding-top: 13px !important; }
  .pr-lg-13,
  .px-lg-13 {
    padding-right: 13px !important; }
  .pb-lg-13,
  .py-lg-13 {
    padding-bottom: 13px !important; }
  .pl-lg-13,
  .px-lg-13 {
    padding-left: 13px !important; }
  .p-lg-14 {
    padding: 14px !important; }
  .pt-lg-14,
  .py-lg-14 {
    padding-top: 14px !important; }
  .pr-lg-14,
  .px-lg-14 {
    padding-right: 14px !important; }
  .pb-lg-14,
  .py-lg-14 {
    padding-bottom: 14px !important; }
  .pl-lg-14,
  .px-lg-14 {
    padding-left: 14px !important; }
  .p-lg-15 {
    padding: 15px !important; }
  .pt-lg-15,
  .py-lg-15 {
    padding-top: 15px !important; }
  .pr-lg-15,
  .px-lg-15 {
    padding-right: 15px !important; }
  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 15px !important; }
  .pl-lg-15,
  .px-lg-15 {
    padding-left: 15px !important; }
  .p-lg-16 {
    padding: 16px !important; }
  .pt-lg-16,
  .py-lg-16 {
    padding-top: 16px !important; }
  .pr-lg-16,
  .px-lg-16 {
    padding-right: 16px !important; }
  .pb-lg-16,
  .py-lg-16 {
    padding-bottom: 16px !important; }
  .pl-lg-16,
  .px-lg-16 {
    padding-left: 16px !important; }
  .p-lg-17 {
    padding: 17px !important; }
  .pt-lg-17,
  .py-lg-17 {
    padding-top: 17px !important; }
  .pr-lg-17,
  .px-lg-17 {
    padding-right: 17px !important; }
  .pb-lg-17,
  .py-lg-17 {
    padding-bottom: 17px !important; }
  .pl-lg-17,
  .px-lg-17 {
    padding-left: 17px !important; }
  .p-lg-18 {
    padding: 18px !important; }
  .pt-lg-18,
  .py-lg-18 {
    padding-top: 18px !important; }
  .pr-lg-18,
  .px-lg-18 {
    padding-right: 18px !important; }
  .pb-lg-18,
  .py-lg-18 {
    padding-bottom: 18px !important; }
  .pl-lg-18,
  .px-lg-18 {
    padding-left: 18px !important; }
  .p-lg-19 {
    padding: 19px !important; }
  .pt-lg-19,
  .py-lg-19 {
    padding-top: 19px !important; }
  .pr-lg-19,
  .px-lg-19 {
    padding-right: 19px !important; }
  .pb-lg-19,
  .py-lg-19 {
    padding-bottom: 19px !important; }
  .pl-lg-19,
  .px-lg-19 {
    padding-left: 19px !important; }
  .p-lg-20 {
    padding: 20px !important; }
  .pt-lg-20,
  .py-lg-20 {
    padding-top: 20px !important; }
  .pr-lg-20,
  .px-lg-20 {
    padding-right: 20px !important; }
  .pb-lg-20,
  .py-lg-20 {
    padding-bottom: 20px !important; }
  .pl-lg-20,
  .px-lg-20 {
    padding-left: 20px !important; }
  .p-lg-25 {
    padding: 25px !important; }
  .pt-lg-25,
  .py-lg-25 {
    padding-top: 25px !important; }
  .pr-lg-25,
  .px-lg-25 {
    padding-right: 25px !important; }
  .pb-lg-25,
  .py-lg-25 {
    padding-bottom: 25px !important; }
  .pl-lg-25,
  .px-lg-25 {
    padding-left: 25px !important; }
  .p-lg-30 {
    padding: 30px !important; }
  .pt-lg-30,
  .py-lg-30 {
    padding-top: 30px !important; }
  .pr-lg-30,
  .px-lg-30 {
    padding-right: 30px !important; }
  .pb-lg-30,
  .py-lg-30 {
    padding-bottom: 30px !important; }
  .pl-lg-30,
  .px-lg-30 {
    padding-left: 30px !important; }
  .p-lg-35 {
    padding: 35px !important; }
  .pt-lg-35,
  .py-lg-35 {
    padding-top: 35px !important; }
  .pr-lg-35,
  .px-lg-35 {
    padding-right: 35px !important; }
  .pb-lg-35,
  .py-lg-35 {
    padding-bottom: 35px !important; }
  .pl-lg-35,
  .px-lg-35 {
    padding-left: 35px !important; }
  .p-lg-40 {
    padding: 40px !important; }
  .pt-lg-40,
  .py-lg-40 {
    padding-top: 40px !important; }
  .pr-lg-40,
  .px-lg-40 {
    padding-right: 40px !important; }
  .pb-lg-40,
  .py-lg-40 {
    padding-bottom: 40px !important; }
  .pl-lg-40,
  .px-lg-40 {
    padding-left: 40px !important; }
  .p-lg-45 {
    padding: 45px !important; }
  .pt-lg-45,
  .py-lg-45 {
    padding-top: 45px !important; }
  .pr-lg-45,
  .px-lg-45 {
    padding-right: 45px !important; }
  .pb-lg-45,
  .py-lg-45 {
    padding-bottom: 45px !important; }
  .pl-lg-45,
  .px-lg-45 {
    padding-left: 45px !important; }
  .p-lg-50 {
    padding: 50px !important; }
  .pt-lg-50,
  .py-lg-50 {
    padding-top: 50px !important; }
  .pr-lg-50,
  .px-lg-50 {
    padding-right: 50px !important; }
  .pb-lg-50,
  .py-lg-50 {
    padding-bottom: 50px !important; }
  .pl-lg-50,
  .px-lg-50 {
    padding-left: 50px !important; }
  .p-lg-55 {
    padding: 55px !important; }
  .pt-lg-55,
  .py-lg-55 {
    padding-top: 55px !important; }
  .pr-lg-55,
  .px-lg-55 {
    padding-right: 55px !important; }
  .pb-lg-55,
  .py-lg-55 {
    padding-bottom: 55px !important; }
  .pl-lg-55,
  .px-lg-55 {
    padding-left: 55px !important; }
  .p-lg-60 {
    padding: 60px !important; }
  .pt-lg-60,
  .py-lg-60 {
    padding-top: 60px !important; }
  .pr-lg-60,
  .px-lg-60 {
    padding-right: 60px !important; }
  .pb-lg-60,
  .py-lg-60 {
    padding-bottom: 60px !important; }
  .pl-lg-60,
  .px-lg-60 {
    padding-left: 60px !important; }
  .p-lg-65 {
    padding: 65px !important; }
  .pt-lg-65,
  .py-lg-65 {
    padding-top: 65px !important; }
  .pr-lg-65,
  .px-lg-65 {
    padding-right: 65px !important; }
  .pb-lg-65,
  .py-lg-65 {
    padding-bottom: 65px !important; }
  .pl-lg-65,
  .px-lg-65 {
    padding-left: 65px !important; }
  .p-lg-70 {
    padding: 70px !important; }
  .pt-lg-70,
  .py-lg-70 {
    padding-top: 70px !important; }
  .pr-lg-70,
  .px-lg-70 {
    padding-right: 70px !important; }
  .pb-lg-70,
  .py-lg-70 {
    padding-bottom: 70px !important; }
  .pl-lg-70,
  .px-lg-70 {
    padding-left: 70px !important; }
  .p-lg-75 {
    padding: 75px !important; }
  .pt-lg-75,
  .py-lg-75 {
    padding-top: 75px !important; }
  .pr-lg-75,
  .px-lg-75 {
    padding-right: 75px !important; }
  .pb-lg-75,
  .py-lg-75 {
    padding-bottom: 75px !important; }
  .pl-lg-75,
  .px-lg-75 {
    padding-left: 75px !important; }
  .p-lg-80 {
    padding: 80px !important; }
  .pt-lg-80,
  .py-lg-80 {
    padding-top: 80px !important; }
  .pr-lg-80,
  .px-lg-80 {
    padding-right: 80px !important; }
  .pb-lg-80,
  .py-lg-80 {
    padding-bottom: 80px !important; }
  .pl-lg-80,
  .px-lg-80 {
    padding-left: 80px !important; }
  .p-lg-85 {
    padding: 85px !important; }
  .pt-lg-85,
  .py-lg-85 {
    padding-top: 85px !important; }
  .pr-lg-85,
  .px-lg-85 {
    padding-right: 85px !important; }
  .pb-lg-85,
  .py-lg-85 {
    padding-bottom: 85px !important; }
  .pl-lg-85,
  .px-lg-85 {
    padding-left: 85px !important; }
  .p-lg-90 {
    padding: 90px !important; }
  .pt-lg-90,
  .py-lg-90 {
    padding-top: 90px !important; }
  .pr-lg-90,
  .px-lg-90 {
    padding-right: 90px !important; }
  .pb-lg-90,
  .py-lg-90 {
    padding-bottom: 90px !important; }
  .pl-lg-90,
  .px-lg-90 {
    padding-left: 90px !important; }
  .p-lg-95 {
    padding: 95px !important; }
  .pt-lg-95,
  .py-lg-95 {
    padding-top: 95px !important; }
  .pr-lg-95,
  .px-lg-95 {
    padding-right: 95px !important; }
  .pb-lg-95,
  .py-lg-95 {
    padding-bottom: 95px !important; }
  .pl-lg-95,
  .px-lg-95 {
    padding-left: 95px !important; }
  .p-lg-100 {
    padding: 100px !important; }
  .pt-lg-100,
  .py-lg-100 {
    padding-top: 100px !important; }
  .pr-lg-100,
  .px-lg-100 {
    padding-right: 100px !important; }
  .pb-lg-100,
  .py-lg-100 {
    padding-bottom: 100px !important; }
  .pl-lg-100,
  .px-lg-100 {
    padding-left: 100px !important; }
  .p-lg-110 {
    padding: 110px !important; }
  .pt-lg-110,
  .py-lg-110 {
    padding-top: 110px !important; }
  .pr-lg-110,
  .px-lg-110 {
    padding-right: 110px !important; }
  .pb-lg-110,
  .py-lg-110 {
    padding-bottom: 110px !important; }
  .pl-lg-110,
  .px-lg-110 {
    padding-left: 110px !important; }
  .p-lg-120 {
    padding: 120px !important; }
  .pt-lg-120,
  .py-lg-120 {
    padding-top: 120px !important; }
  .pr-lg-120,
  .px-lg-120 {
    padding-right: 120px !important; }
  .pb-lg-120,
  .py-lg-120 {
    padding-bottom: 120px !important; }
  .pl-lg-120,
  .px-lg-120 {
    padding-left: 120px !important; }
  .p-lg-130 {
    padding: 130px !important; }
  .pt-lg-130,
  .py-lg-130 {
    padding-top: 130px !important; }
  .pr-lg-130,
  .px-lg-130 {
    padding-right: 130px !important; }
  .pb-lg-130,
  .py-lg-130 {
    padding-bottom: 130px !important; }
  .pl-lg-130,
  .px-lg-130 {
    padding-left: 130px !important; }
  .p-lg-140 {
    padding: 140px !important; }
  .pt-lg-140,
  .py-lg-140 {
    padding-top: 140px !important; }
  .pr-lg-140,
  .px-lg-140 {
    padding-right: 140px !important; }
  .pb-lg-140,
  .py-lg-140 {
    padding-bottom: 140px !important; }
  .pl-lg-140,
  .px-lg-140 {
    padding-left: 140px !important; }
  .p-lg-150 {
    padding: 150px !important; }
  .pt-lg-150,
  .py-lg-150 {
    padding-top: 150px !important; }
  .pr-lg-150,
  .px-lg-150 {
    padding-right: 150px !important; }
  .pb-lg-150,
  .py-lg-150 {
    padding-bottom: 150px !important; }
  .pl-lg-150,
  .px-lg-150 {
    padding-left: 150px !important; }
  .p-lg-160 {
    padding: 160px !important; }
  .pt-lg-160,
  .py-lg-160 {
    padding-top: 160px !important; }
  .pr-lg-160,
  .px-lg-160 {
    padding-right: 160px !important; }
  .pb-lg-160,
  .py-lg-160 {
    padding-bottom: 160px !important; }
  .pl-lg-160,
  .px-lg-160 {
    padding-left: 160px !important; }
  .p-lg-170 {
    padding: 170px !important; }
  .pt-lg-170,
  .py-lg-170 {
    padding-top: 170px !important; }
  .pr-lg-170,
  .px-lg-170 {
    padding-right: 170px !important; }
  .pb-lg-170,
  .py-lg-170 {
    padding-bottom: 170px !important; }
  .pl-lg-170,
  .px-lg-170 {
    padding-left: 170px !important; }
  .p-lg-180 {
    padding: 180px !important; }
  .pt-lg-180,
  .py-lg-180 {
    padding-top: 180px !important; }
  .pr-lg-180,
  .px-lg-180 {
    padding-right: 180px !important; }
  .pb-lg-180,
  .py-lg-180 {
    padding-bottom: 180px !important; }
  .pl-lg-180,
  .px-lg-180 {
    padding-left: 180px !important; }
  .p-lg-190 {
    padding: 190px !important; }
  .pt-lg-190,
  .py-lg-190 {
    padding-top: 190px !important; }
  .pr-lg-190,
  .px-lg-190 {
    padding-right: 190px !important; }
  .pb-lg-190,
  .py-lg-190 {
    padding-bottom: 190px !important; }
  .pl-lg-190,
  .px-lg-190 {
    padding-left: 190px !important; }
  .p-lg-200 {
    padding: 200px !important; }
  .pt-lg-200,
  .py-lg-200 {
    padding-top: 200px !important; }
  .pr-lg-200,
  .px-lg-200 {
    padding-right: 200px !important; }
  .pb-lg-200,
  .py-lg-200 {
    padding-bottom: 200px !important; }
  .pl-lg-200,
  .px-lg-200 {
    padding-left: 200px !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0px !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0px !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0px !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0px !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0px !important; }
  .m-xl-1 {
    margin: 1px !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 1px !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 1px !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 1px !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 1px !important; }
  .m-xl-2 {
    margin: 2px !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 2px !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 2px !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 2px !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 2px !important; }
  .m-xl-3 {
    margin: 3px !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 3px !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 3px !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 3px !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 3px !important; }
  .m-xl-4 {
    margin: 4px !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 4px !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 4px !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 4px !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 4px !important; }
  .m-xl-5 {
    margin: 5px !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 5px !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 5px !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 5px !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 5px !important; }
  .m-xl-6 {
    margin: 6px !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 6px !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 6px !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 6px !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 6px !important; }
  .m-xl-7 {
    margin: 7px !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 7px !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 7px !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 7px !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 7px !important; }
  .m-xl-8 {
    margin: 8px !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 8px !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 8px !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 8px !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 8px !important; }
  .m-xl-9 {
    margin: 9px !important; }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 9px !important; }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 9px !important; }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 9px !important; }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 9px !important; }
  .m-xl-10 {
    margin: 10px !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 10px !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 10px !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 10px !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 10px !important; }
  .m-xl-11 {
    margin: 11px !important; }
  .mt-xl-11,
  .my-xl-11 {
    margin-top: 11px !important; }
  .mr-xl-11,
  .mx-xl-11 {
    margin-right: 11px !important; }
  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 11px !important; }
  .ml-xl-11,
  .mx-xl-11 {
    margin-left: 11px !important; }
  .m-xl-12 {
    margin: 12px !important; }
  .mt-xl-12,
  .my-xl-12 {
    margin-top: 12px !important; }
  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 12px !important; }
  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 12px !important; }
  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 12px !important; }
  .m-xl-13 {
    margin: 13px !important; }
  .mt-xl-13,
  .my-xl-13 {
    margin-top: 13px !important; }
  .mr-xl-13,
  .mx-xl-13 {
    margin-right: 13px !important; }
  .mb-xl-13,
  .my-xl-13 {
    margin-bottom: 13px !important; }
  .ml-xl-13,
  .mx-xl-13 {
    margin-left: 13px !important; }
  .m-xl-14 {
    margin: 14px !important; }
  .mt-xl-14,
  .my-xl-14 {
    margin-top: 14px !important; }
  .mr-xl-14,
  .mx-xl-14 {
    margin-right: 14px !important; }
  .mb-xl-14,
  .my-xl-14 {
    margin-bottom: 14px !important; }
  .ml-xl-14,
  .mx-xl-14 {
    margin-left: 14px !important; }
  .m-xl-15 {
    margin: 15px !important; }
  .mt-xl-15,
  .my-xl-15 {
    margin-top: 15px !important; }
  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 15px !important; }
  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 15px !important; }
  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 15px !important; }
  .m-xl-16 {
    margin: 16px !important; }
  .mt-xl-16,
  .my-xl-16 {
    margin-top: 16px !important; }
  .mr-xl-16,
  .mx-xl-16 {
    margin-right: 16px !important; }
  .mb-xl-16,
  .my-xl-16 {
    margin-bottom: 16px !important; }
  .ml-xl-16,
  .mx-xl-16 {
    margin-left: 16px !important; }
  .m-xl-17 {
    margin: 17px !important; }
  .mt-xl-17,
  .my-xl-17 {
    margin-top: 17px !important; }
  .mr-xl-17,
  .mx-xl-17 {
    margin-right: 17px !important; }
  .mb-xl-17,
  .my-xl-17 {
    margin-bottom: 17px !important; }
  .ml-xl-17,
  .mx-xl-17 {
    margin-left: 17px !important; }
  .m-xl-18 {
    margin: 18px !important; }
  .mt-xl-18,
  .my-xl-18 {
    margin-top: 18px !important; }
  .mr-xl-18,
  .mx-xl-18 {
    margin-right: 18px !important; }
  .mb-xl-18,
  .my-xl-18 {
    margin-bottom: 18px !important; }
  .ml-xl-18,
  .mx-xl-18 {
    margin-left: 18px !important; }
  .m-xl-19 {
    margin: 19px !important; }
  .mt-xl-19,
  .my-xl-19 {
    margin-top: 19px !important; }
  .mr-xl-19,
  .mx-xl-19 {
    margin-right: 19px !important; }
  .mb-xl-19,
  .my-xl-19 {
    margin-bottom: 19px !important; }
  .ml-xl-19,
  .mx-xl-19 {
    margin-left: 19px !important; }
  .m-xl-20 {
    margin: 20px !important; }
  .mt-xl-20,
  .my-xl-20 {
    margin-top: 20px !important; }
  .mr-xl-20,
  .mx-xl-20 {
    margin-right: 20px !important; }
  .mb-xl-20,
  .my-xl-20 {
    margin-bottom: 20px !important; }
  .ml-xl-20,
  .mx-xl-20 {
    margin-left: 20px !important; }
  .m-xl-25 {
    margin: 25px !important; }
  .mt-xl-25,
  .my-xl-25 {
    margin-top: 25px !important; }
  .mr-xl-25,
  .mx-xl-25 {
    margin-right: 25px !important; }
  .mb-xl-25,
  .my-xl-25 {
    margin-bottom: 25px !important; }
  .ml-xl-25,
  .mx-xl-25 {
    margin-left: 25px !important; }
  .m-xl-30 {
    margin: 30px !important; }
  .mt-xl-30,
  .my-xl-30 {
    margin-top: 30px !important; }
  .mr-xl-30,
  .mx-xl-30 {
    margin-right: 30px !important; }
  .mb-xl-30,
  .my-xl-30 {
    margin-bottom: 30px !important; }
  .ml-xl-30,
  .mx-xl-30 {
    margin-left: 30px !important; }
  .m-xl-35 {
    margin: 35px !important; }
  .mt-xl-35,
  .my-xl-35 {
    margin-top: 35px !important; }
  .mr-xl-35,
  .mx-xl-35 {
    margin-right: 35px !important; }
  .mb-xl-35,
  .my-xl-35 {
    margin-bottom: 35px !important; }
  .ml-xl-35,
  .mx-xl-35 {
    margin-left: 35px !important; }
  .m-xl-40 {
    margin: 40px !important; }
  .mt-xl-40,
  .my-xl-40 {
    margin-top: 40px !important; }
  .mr-xl-40,
  .mx-xl-40 {
    margin-right: 40px !important; }
  .mb-xl-40,
  .my-xl-40 {
    margin-bottom: 40px !important; }
  .ml-xl-40,
  .mx-xl-40 {
    margin-left: 40px !important; }
  .m-xl-45 {
    margin: 45px !important; }
  .mt-xl-45,
  .my-xl-45 {
    margin-top: 45px !important; }
  .mr-xl-45,
  .mx-xl-45 {
    margin-right: 45px !important; }
  .mb-xl-45,
  .my-xl-45 {
    margin-bottom: 45px !important; }
  .ml-xl-45,
  .mx-xl-45 {
    margin-left: 45px !important; }
  .m-xl-50 {
    margin: 50px !important; }
  .mt-xl-50,
  .my-xl-50 {
    margin-top: 50px !important; }
  .mr-xl-50,
  .mx-xl-50 {
    margin-right: 50px !important; }
  .mb-xl-50,
  .my-xl-50 {
    margin-bottom: 50px !important; }
  .ml-xl-50,
  .mx-xl-50 {
    margin-left: 50px !important; }
  .m-xl-55 {
    margin: 55px !important; }
  .mt-xl-55,
  .my-xl-55 {
    margin-top: 55px !important; }
  .mr-xl-55,
  .mx-xl-55 {
    margin-right: 55px !important; }
  .mb-xl-55,
  .my-xl-55 {
    margin-bottom: 55px !important; }
  .ml-xl-55,
  .mx-xl-55 {
    margin-left: 55px !important; }
  .m-xl-60 {
    margin: 60px !important; }
  .mt-xl-60,
  .my-xl-60 {
    margin-top: 60px !important; }
  .mr-xl-60,
  .mx-xl-60 {
    margin-right: 60px !important; }
  .mb-xl-60,
  .my-xl-60 {
    margin-bottom: 60px !important; }
  .ml-xl-60,
  .mx-xl-60 {
    margin-left: 60px !important; }
  .m-xl-65 {
    margin: 65px !important; }
  .mt-xl-65,
  .my-xl-65 {
    margin-top: 65px !important; }
  .mr-xl-65,
  .mx-xl-65 {
    margin-right: 65px !important; }
  .mb-xl-65,
  .my-xl-65 {
    margin-bottom: 65px !important; }
  .ml-xl-65,
  .mx-xl-65 {
    margin-left: 65px !important; }
  .m-xl-70 {
    margin: 70px !important; }
  .mt-xl-70,
  .my-xl-70 {
    margin-top: 70px !important; }
  .mr-xl-70,
  .mx-xl-70 {
    margin-right: 70px !important; }
  .mb-xl-70,
  .my-xl-70 {
    margin-bottom: 70px !important; }
  .ml-xl-70,
  .mx-xl-70 {
    margin-left: 70px !important; }
  .m-xl-75 {
    margin: 75px !important; }
  .mt-xl-75,
  .my-xl-75 {
    margin-top: 75px !important; }
  .mr-xl-75,
  .mx-xl-75 {
    margin-right: 75px !important; }
  .mb-xl-75,
  .my-xl-75 {
    margin-bottom: 75px !important; }
  .ml-xl-75,
  .mx-xl-75 {
    margin-left: 75px !important; }
  .m-xl-80 {
    margin: 80px !important; }
  .mt-xl-80,
  .my-xl-80 {
    margin-top: 80px !important; }
  .mr-xl-80,
  .mx-xl-80 {
    margin-right: 80px !important; }
  .mb-xl-80,
  .my-xl-80 {
    margin-bottom: 80px !important; }
  .ml-xl-80,
  .mx-xl-80 {
    margin-left: 80px !important; }
  .m-xl-85 {
    margin: 85px !important; }
  .mt-xl-85,
  .my-xl-85 {
    margin-top: 85px !important; }
  .mr-xl-85,
  .mx-xl-85 {
    margin-right: 85px !important; }
  .mb-xl-85,
  .my-xl-85 {
    margin-bottom: 85px !important; }
  .ml-xl-85,
  .mx-xl-85 {
    margin-left: 85px !important; }
  .m-xl-90 {
    margin: 90px !important; }
  .mt-xl-90,
  .my-xl-90 {
    margin-top: 90px !important; }
  .mr-xl-90,
  .mx-xl-90 {
    margin-right: 90px !important; }
  .mb-xl-90,
  .my-xl-90 {
    margin-bottom: 90px !important; }
  .ml-xl-90,
  .mx-xl-90 {
    margin-left: 90px !important; }
  .m-xl-95 {
    margin: 95px !important; }
  .mt-xl-95,
  .my-xl-95 {
    margin-top: 95px !important; }
  .mr-xl-95,
  .mx-xl-95 {
    margin-right: 95px !important; }
  .mb-xl-95,
  .my-xl-95 {
    margin-bottom: 95px !important; }
  .ml-xl-95,
  .mx-xl-95 {
    margin-left: 95px !important; }
  .m-xl-100 {
    margin: 100px !important; }
  .mt-xl-100,
  .my-xl-100 {
    margin-top: 100px !important; }
  .mr-xl-100,
  .mx-xl-100 {
    margin-right: 100px !important; }
  .mb-xl-100,
  .my-xl-100 {
    margin-bottom: 100px !important; }
  .ml-xl-100,
  .mx-xl-100 {
    margin-left: 100px !important; }
  .m-xl-110 {
    margin: 110px !important; }
  .mt-xl-110,
  .my-xl-110 {
    margin-top: 110px !important; }
  .mr-xl-110,
  .mx-xl-110 {
    margin-right: 110px !important; }
  .mb-xl-110,
  .my-xl-110 {
    margin-bottom: 110px !important; }
  .ml-xl-110,
  .mx-xl-110 {
    margin-left: 110px !important; }
  .m-xl-120 {
    margin: 120px !important; }
  .mt-xl-120,
  .my-xl-120 {
    margin-top: 120px !important; }
  .mr-xl-120,
  .mx-xl-120 {
    margin-right: 120px !important; }
  .mb-xl-120,
  .my-xl-120 {
    margin-bottom: 120px !important; }
  .ml-xl-120,
  .mx-xl-120 {
    margin-left: 120px !important; }
  .m-xl-130 {
    margin: 130px !important; }
  .mt-xl-130,
  .my-xl-130 {
    margin-top: 130px !important; }
  .mr-xl-130,
  .mx-xl-130 {
    margin-right: 130px !important; }
  .mb-xl-130,
  .my-xl-130 {
    margin-bottom: 130px !important; }
  .ml-xl-130,
  .mx-xl-130 {
    margin-left: 130px !important; }
  .m-xl-140 {
    margin: 140px !important; }
  .mt-xl-140,
  .my-xl-140 {
    margin-top: 140px !important; }
  .mr-xl-140,
  .mx-xl-140 {
    margin-right: 140px !important; }
  .mb-xl-140,
  .my-xl-140 {
    margin-bottom: 140px !important; }
  .ml-xl-140,
  .mx-xl-140 {
    margin-left: 140px !important; }
  .m-xl-150 {
    margin: 150px !important; }
  .mt-xl-150,
  .my-xl-150 {
    margin-top: 150px !important; }
  .mr-xl-150,
  .mx-xl-150 {
    margin-right: 150px !important; }
  .mb-xl-150,
  .my-xl-150 {
    margin-bottom: 150px !important; }
  .ml-xl-150,
  .mx-xl-150 {
    margin-left: 150px !important; }
  .m-xl-160 {
    margin: 160px !important; }
  .mt-xl-160,
  .my-xl-160 {
    margin-top: 160px !important; }
  .mr-xl-160,
  .mx-xl-160 {
    margin-right: 160px !important; }
  .mb-xl-160,
  .my-xl-160 {
    margin-bottom: 160px !important; }
  .ml-xl-160,
  .mx-xl-160 {
    margin-left: 160px !important; }
  .m-xl-170 {
    margin: 170px !important; }
  .mt-xl-170,
  .my-xl-170 {
    margin-top: 170px !important; }
  .mr-xl-170,
  .mx-xl-170 {
    margin-right: 170px !important; }
  .mb-xl-170,
  .my-xl-170 {
    margin-bottom: 170px !important; }
  .ml-xl-170,
  .mx-xl-170 {
    margin-left: 170px !important; }
  .m-xl-180 {
    margin: 180px !important; }
  .mt-xl-180,
  .my-xl-180 {
    margin-top: 180px !important; }
  .mr-xl-180,
  .mx-xl-180 {
    margin-right: 180px !important; }
  .mb-xl-180,
  .my-xl-180 {
    margin-bottom: 180px !important; }
  .ml-xl-180,
  .mx-xl-180 {
    margin-left: 180px !important; }
  .m-xl-190 {
    margin: 190px !important; }
  .mt-xl-190,
  .my-xl-190 {
    margin-top: 190px !important; }
  .mr-xl-190,
  .mx-xl-190 {
    margin-right: 190px !important; }
  .mb-xl-190,
  .my-xl-190 {
    margin-bottom: 190px !important; }
  .ml-xl-190,
  .mx-xl-190 {
    margin-left: 190px !important; }
  .m-xl-200 {
    margin: 200px !important; }
  .mt-xl-200,
  .my-xl-200 {
    margin-top: 200px !important; }
  .mr-xl-200,
  .mx-xl-200 {
    margin-right: 200px !important; }
  .mb-xl-200,
  .my-xl-200 {
    margin-bottom: 200px !important; }
  .ml-xl-200,
  .mx-xl-200 {
    margin-left: 200px !important; }
  .p-xl-0 {
    padding: 0px !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0px !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0px !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0px !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0px !important; }
  .p-xl-1 {
    padding: 1px !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 1px !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 1px !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 1px !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 1px !important; }
  .p-xl-2 {
    padding: 2px !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 2px !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 2px !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 2px !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 2px !important; }
  .p-xl-3 {
    padding: 3px !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 3px !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 3px !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 3px !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 3px !important; }
  .p-xl-4 {
    padding: 4px !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 4px !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 4px !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 4px !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 4px !important; }
  .p-xl-5 {
    padding: 5px !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 5px !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 5px !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 5px !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 5px !important; }
  .p-xl-6 {
    padding: 6px !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 6px !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 6px !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 6px !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 6px !important; }
  .p-xl-7 {
    padding: 7px !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 7px !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 7px !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 7px !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 7px !important; }
  .p-xl-8 {
    padding: 8px !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 8px !important; }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 8px !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 8px !important; }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 8px !important; }
  .p-xl-9 {
    padding: 9px !important; }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 9px !important; }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 9px !important; }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 9px !important; }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 9px !important; }
  .p-xl-10 {
    padding: 10px !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 10px !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 10px !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 10px !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 10px !important; }
  .p-xl-11 {
    padding: 11px !important; }
  .pt-xl-11,
  .py-xl-11 {
    padding-top: 11px !important; }
  .pr-xl-11,
  .px-xl-11 {
    padding-right: 11px !important; }
  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 11px !important; }
  .pl-xl-11,
  .px-xl-11 {
    padding-left: 11px !important; }
  .p-xl-12 {
    padding: 12px !important; }
  .pt-xl-12,
  .py-xl-12 {
    padding-top: 12px !important; }
  .pr-xl-12,
  .px-xl-12 {
    padding-right: 12px !important; }
  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 12px !important; }
  .pl-xl-12,
  .px-xl-12 {
    padding-left: 12px !important; }
  .p-xl-13 {
    padding: 13px !important; }
  .pt-xl-13,
  .py-xl-13 {
    padding-top: 13px !important; }
  .pr-xl-13,
  .px-xl-13 {
    padding-right: 13px !important; }
  .pb-xl-13,
  .py-xl-13 {
    padding-bottom: 13px !important; }
  .pl-xl-13,
  .px-xl-13 {
    padding-left: 13px !important; }
  .p-xl-14 {
    padding: 14px !important; }
  .pt-xl-14,
  .py-xl-14 {
    padding-top: 14px !important; }
  .pr-xl-14,
  .px-xl-14 {
    padding-right: 14px !important; }
  .pb-xl-14,
  .py-xl-14 {
    padding-bottom: 14px !important; }
  .pl-xl-14,
  .px-xl-14 {
    padding-left: 14px !important; }
  .p-xl-15 {
    padding: 15px !important; }
  .pt-xl-15,
  .py-xl-15 {
    padding-top: 15px !important; }
  .pr-xl-15,
  .px-xl-15 {
    padding-right: 15px !important; }
  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 15px !important; }
  .pl-xl-15,
  .px-xl-15 {
    padding-left: 15px !important; }
  .p-xl-16 {
    padding: 16px !important; }
  .pt-xl-16,
  .py-xl-16 {
    padding-top: 16px !important; }
  .pr-xl-16,
  .px-xl-16 {
    padding-right: 16px !important; }
  .pb-xl-16,
  .py-xl-16 {
    padding-bottom: 16px !important; }
  .pl-xl-16,
  .px-xl-16 {
    padding-left: 16px !important; }
  .p-xl-17 {
    padding: 17px !important; }
  .pt-xl-17,
  .py-xl-17 {
    padding-top: 17px !important; }
  .pr-xl-17,
  .px-xl-17 {
    padding-right: 17px !important; }
  .pb-xl-17,
  .py-xl-17 {
    padding-bottom: 17px !important; }
  .pl-xl-17,
  .px-xl-17 {
    padding-left: 17px !important; }
  .p-xl-18 {
    padding: 18px !important; }
  .pt-xl-18,
  .py-xl-18 {
    padding-top: 18px !important; }
  .pr-xl-18,
  .px-xl-18 {
    padding-right: 18px !important; }
  .pb-xl-18,
  .py-xl-18 {
    padding-bottom: 18px !important; }
  .pl-xl-18,
  .px-xl-18 {
    padding-left: 18px !important; }
  .p-xl-19 {
    padding: 19px !important; }
  .pt-xl-19,
  .py-xl-19 {
    padding-top: 19px !important; }
  .pr-xl-19,
  .px-xl-19 {
    padding-right: 19px !important; }
  .pb-xl-19,
  .py-xl-19 {
    padding-bottom: 19px !important; }
  .pl-xl-19,
  .px-xl-19 {
    padding-left: 19px !important; }
  .p-xl-20 {
    padding: 20px !important; }
  .pt-xl-20,
  .py-xl-20 {
    padding-top: 20px !important; }
  .pr-xl-20,
  .px-xl-20 {
    padding-right: 20px !important; }
  .pb-xl-20,
  .py-xl-20 {
    padding-bottom: 20px !important; }
  .pl-xl-20,
  .px-xl-20 {
    padding-left: 20px !important; }
  .p-xl-25 {
    padding: 25px !important; }
  .pt-xl-25,
  .py-xl-25 {
    padding-top: 25px !important; }
  .pr-xl-25,
  .px-xl-25 {
    padding-right: 25px !important; }
  .pb-xl-25,
  .py-xl-25 {
    padding-bottom: 25px !important; }
  .pl-xl-25,
  .px-xl-25 {
    padding-left: 25px !important; }
  .p-xl-30 {
    padding: 30px !important; }
  .pt-xl-30,
  .py-xl-30 {
    padding-top: 30px !important; }
  .pr-xl-30,
  .px-xl-30 {
    padding-right: 30px !important; }
  .pb-xl-30,
  .py-xl-30 {
    padding-bottom: 30px !important; }
  .pl-xl-30,
  .px-xl-30 {
    padding-left: 30px !important; }
  .p-xl-35 {
    padding: 35px !important; }
  .pt-xl-35,
  .py-xl-35 {
    padding-top: 35px !important; }
  .pr-xl-35,
  .px-xl-35 {
    padding-right: 35px !important; }
  .pb-xl-35,
  .py-xl-35 {
    padding-bottom: 35px !important; }
  .pl-xl-35,
  .px-xl-35 {
    padding-left: 35px !important; }
  .p-xl-40 {
    padding: 40px !important; }
  .pt-xl-40,
  .py-xl-40 {
    padding-top: 40px !important; }
  .pr-xl-40,
  .px-xl-40 {
    padding-right: 40px !important; }
  .pb-xl-40,
  .py-xl-40 {
    padding-bottom: 40px !important; }
  .pl-xl-40,
  .px-xl-40 {
    padding-left: 40px !important; }
  .p-xl-45 {
    padding: 45px !important; }
  .pt-xl-45,
  .py-xl-45 {
    padding-top: 45px !important; }
  .pr-xl-45,
  .px-xl-45 {
    padding-right: 45px !important; }
  .pb-xl-45,
  .py-xl-45 {
    padding-bottom: 45px !important; }
  .pl-xl-45,
  .px-xl-45 {
    padding-left: 45px !important; }
  .p-xl-50 {
    padding: 50px !important; }
  .pt-xl-50,
  .py-xl-50 {
    padding-top: 50px !important; }
  .pr-xl-50,
  .px-xl-50 {
    padding-right: 50px !important; }
  .pb-xl-50,
  .py-xl-50 {
    padding-bottom: 50px !important; }
  .pl-xl-50,
  .px-xl-50 {
    padding-left: 50px !important; }
  .p-xl-55 {
    padding: 55px !important; }
  .pt-xl-55,
  .py-xl-55 {
    padding-top: 55px !important; }
  .pr-xl-55,
  .px-xl-55 {
    padding-right: 55px !important; }
  .pb-xl-55,
  .py-xl-55 {
    padding-bottom: 55px !important; }
  .pl-xl-55,
  .px-xl-55 {
    padding-left: 55px !important; }
  .p-xl-60 {
    padding: 60px !important; }
  .pt-xl-60,
  .py-xl-60 {
    padding-top: 60px !important; }
  .pr-xl-60,
  .px-xl-60 {
    padding-right: 60px !important; }
  .pb-xl-60,
  .py-xl-60 {
    padding-bottom: 60px !important; }
  .pl-xl-60,
  .px-xl-60 {
    padding-left: 60px !important; }
  .p-xl-65 {
    padding: 65px !important; }
  .pt-xl-65,
  .py-xl-65 {
    padding-top: 65px !important; }
  .pr-xl-65,
  .px-xl-65 {
    padding-right: 65px !important; }
  .pb-xl-65,
  .py-xl-65 {
    padding-bottom: 65px !important; }
  .pl-xl-65,
  .px-xl-65 {
    padding-left: 65px !important; }
  .p-xl-70 {
    padding: 70px !important; }
  .pt-xl-70,
  .py-xl-70 {
    padding-top: 70px !important; }
  .pr-xl-70,
  .px-xl-70 {
    padding-right: 70px !important; }
  .pb-xl-70,
  .py-xl-70 {
    padding-bottom: 70px !important; }
  .pl-xl-70,
  .px-xl-70 {
    padding-left: 70px !important; }
  .p-xl-75 {
    padding: 75px !important; }
  .pt-xl-75,
  .py-xl-75 {
    padding-top: 75px !important; }
  .pr-xl-75,
  .px-xl-75 {
    padding-right: 75px !important; }
  .pb-xl-75,
  .py-xl-75 {
    padding-bottom: 75px !important; }
  .pl-xl-75,
  .px-xl-75 {
    padding-left: 75px !important; }
  .p-xl-80 {
    padding: 80px !important; }
  .pt-xl-80,
  .py-xl-80 {
    padding-top: 80px !important; }
  .pr-xl-80,
  .px-xl-80 {
    padding-right: 80px !important; }
  .pb-xl-80,
  .py-xl-80 {
    padding-bottom: 80px !important; }
  .pl-xl-80,
  .px-xl-80 {
    padding-left: 80px !important; }
  .p-xl-85 {
    padding: 85px !important; }
  .pt-xl-85,
  .py-xl-85 {
    padding-top: 85px !important; }
  .pr-xl-85,
  .px-xl-85 {
    padding-right: 85px !important; }
  .pb-xl-85,
  .py-xl-85 {
    padding-bottom: 85px !important; }
  .pl-xl-85,
  .px-xl-85 {
    padding-left: 85px !important; }
  .p-xl-90 {
    padding: 90px !important; }
  .pt-xl-90,
  .py-xl-90 {
    padding-top: 90px !important; }
  .pr-xl-90,
  .px-xl-90 {
    padding-right: 90px !important; }
  .pb-xl-90,
  .py-xl-90 {
    padding-bottom: 90px !important; }
  .pl-xl-90,
  .px-xl-90 {
    padding-left: 90px !important; }
  .p-xl-95 {
    padding: 95px !important; }
  .pt-xl-95,
  .py-xl-95 {
    padding-top: 95px !important; }
  .pr-xl-95,
  .px-xl-95 {
    padding-right: 95px !important; }
  .pb-xl-95,
  .py-xl-95 {
    padding-bottom: 95px !important; }
  .pl-xl-95,
  .px-xl-95 {
    padding-left: 95px !important; }
  .p-xl-100 {
    padding: 100px !important; }
  .pt-xl-100,
  .py-xl-100 {
    padding-top: 100px !important; }
  .pr-xl-100,
  .px-xl-100 {
    padding-right: 100px !important; }
  .pb-xl-100,
  .py-xl-100 {
    padding-bottom: 100px !important; }
  .pl-xl-100,
  .px-xl-100 {
    padding-left: 100px !important; }
  .p-xl-110 {
    padding: 110px !important; }
  .pt-xl-110,
  .py-xl-110 {
    padding-top: 110px !important; }
  .pr-xl-110,
  .px-xl-110 {
    padding-right: 110px !important; }
  .pb-xl-110,
  .py-xl-110 {
    padding-bottom: 110px !important; }
  .pl-xl-110,
  .px-xl-110 {
    padding-left: 110px !important; }
  .p-xl-120 {
    padding: 120px !important; }
  .pt-xl-120,
  .py-xl-120 {
    padding-top: 120px !important; }
  .pr-xl-120,
  .px-xl-120 {
    padding-right: 120px !important; }
  .pb-xl-120,
  .py-xl-120 {
    padding-bottom: 120px !important; }
  .pl-xl-120,
  .px-xl-120 {
    padding-left: 120px !important; }
  .p-xl-130 {
    padding: 130px !important; }
  .pt-xl-130,
  .py-xl-130 {
    padding-top: 130px !important; }
  .pr-xl-130,
  .px-xl-130 {
    padding-right: 130px !important; }
  .pb-xl-130,
  .py-xl-130 {
    padding-bottom: 130px !important; }
  .pl-xl-130,
  .px-xl-130 {
    padding-left: 130px !important; }
  .p-xl-140 {
    padding: 140px !important; }
  .pt-xl-140,
  .py-xl-140 {
    padding-top: 140px !important; }
  .pr-xl-140,
  .px-xl-140 {
    padding-right: 140px !important; }
  .pb-xl-140,
  .py-xl-140 {
    padding-bottom: 140px !important; }
  .pl-xl-140,
  .px-xl-140 {
    padding-left: 140px !important; }
  .p-xl-150 {
    padding: 150px !important; }
  .pt-xl-150,
  .py-xl-150 {
    padding-top: 150px !important; }
  .pr-xl-150,
  .px-xl-150 {
    padding-right: 150px !important; }
  .pb-xl-150,
  .py-xl-150 {
    padding-bottom: 150px !important; }
  .pl-xl-150,
  .px-xl-150 {
    padding-left: 150px !important; }
  .p-xl-160 {
    padding: 160px !important; }
  .pt-xl-160,
  .py-xl-160 {
    padding-top: 160px !important; }
  .pr-xl-160,
  .px-xl-160 {
    padding-right: 160px !important; }
  .pb-xl-160,
  .py-xl-160 {
    padding-bottom: 160px !important; }
  .pl-xl-160,
  .px-xl-160 {
    padding-left: 160px !important; }
  .p-xl-170 {
    padding: 170px !important; }
  .pt-xl-170,
  .py-xl-170 {
    padding-top: 170px !important; }
  .pr-xl-170,
  .px-xl-170 {
    padding-right: 170px !important; }
  .pb-xl-170,
  .py-xl-170 {
    padding-bottom: 170px !important; }
  .pl-xl-170,
  .px-xl-170 {
    padding-left: 170px !important; }
  .p-xl-180 {
    padding: 180px !important; }
  .pt-xl-180,
  .py-xl-180 {
    padding-top: 180px !important; }
  .pr-xl-180,
  .px-xl-180 {
    padding-right: 180px !important; }
  .pb-xl-180,
  .py-xl-180 {
    padding-bottom: 180px !important; }
  .pl-xl-180,
  .px-xl-180 {
    padding-left: 180px !important; }
  .p-xl-190 {
    padding: 190px !important; }
  .pt-xl-190,
  .py-xl-190 {
    padding-top: 190px !important; }
  .pr-xl-190,
  .px-xl-190 {
    padding-right: 190px !important; }
  .pb-xl-190,
  .py-xl-190 {
    padding-bottom: 190px !important; }
  .pl-xl-190,
  .px-xl-190 {
    padding-left: 190px !important; }
  .p-xl-200 {
    padding: 200px !important; }
  .pt-xl-200,
  .py-xl-200 {
    padding-top: 200px !important; }
  .pr-xl-200,
  .px-xl-200 {
    padding-right: 200px !important; }
  .pb-xl-200,
  .py-xl-200 {
    padding-bottom: 200px !important; }
  .pl-xl-200,
  .px-xl-200 {
    padding-left: 200px !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #484896 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #373774 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important; }

.text-success {
  color: #4E6E32 !important; }

a.text-success:hover, a.text-success:focus {
  color: #354b22 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important; }

.text-danger {
  color: #962A30 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #6e1f23 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-blue {
  color: #484896 !important; }

a.text-blue:hover, a.text-blue:focus {
  color: #373774 !important; }

.text-indigo {
  color: #6610f2 !important; }

a.text-indigo:hover, a.text-indigo:focus {
  color: #510bc4 !important; }

.text-purple {
  color: #6f42c1 !important; }

a.text-purple:hover, a.text-purple:focus {
  color: #59339d !important; }

.text-pink {
  color: #e83e8c !important; }

a.text-pink:hover, a.text-pink:focus {
  color: #d91a72 !important; }

.text-red {
  color: #962A30 !important; }

a.text-red:hover, a.text-red:focus {
  color: #6e1f23 !important; }

.text-orange {
  color: #fd7e14 !important; }

a.text-orange:hover, a.text-orange:focus {
  color: #dc6502 !important; }

.text-yellow {
  color: #ffc107 !important; }

a.text-yellow:hover, a.text-yellow:focus {
  color: #d39e00 !important; }

.text-green {
  color: #4E6E32 !important; }

a.text-green:hover, a.text-green:focus {
  color: #354b22 !important; }

.text-teal {
  color: #20c997 !important; }

a.text-teal:hover, a.text-teal:focus {
  color: #199d76 !important; }

.text-cyan {
  color: #17a2b8 !important; }

a.text-cyan:hover, a.text-cyan:focus {
  color: #117a8b !important; }

.text-white {
  color: #ffffff !important; }

a.text-white:hover, a.text-white:focus {
  color: #e6e6e6 !important; }

.text-gray {
  color: #F3F2F3 !important; }

a.text-gray:hover, a.text-gray:focus {
  color: #dbd7db !important; }

.text-gray-dark {
  color: #343a40 !important; }

a.text-gray-dark:hover, a.text-gray-dark:focus {
  color: #1d2124 !important; }

.text-black {
  color: #000000 !important; }

a.text-black:hover, a.text-black:focus {
  color: black !important; }

.text-green-light {
  color: #C7D1BD !important; }

a.text-green-light:hover, a.text-green-light:focus {
  color: #aebc9f !important; }

.text-green-dark {
  color: #3E5B22 !important; }

a.text-green-dark:hover, a.text-green-dark:focus {
  color: #253614 !important; }

.text-muted {
  color: #6c757d !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.fs-10 {
  font-size: 10px !important; }

.fs-12 {
  font-size: 12px !important; }

.fs-14 {
  font-size: 14px !important; }

.fs-16 {
  font-size: 16px !important; }

.fs-18 {
  font-size: 18px !important; }

.fs-20 {
  font-size: 20px !important; }

.fs-22 {
  font-size: 22px !important; }

.fs-24 {
  font-size: 24px !important; }

.fs-26 {
  font-size: 26px !important; }

.fs-28 {
  font-size: 28px !important; }

.fs-30 {
  font-size: 30px !important; }

.fs-32 {
  font-size: 32px !important; }

.fs-34 {
  font-size: 34px !important; }

.fs-36 {
  font-size: 36px !important; }

.fs-38 {
  font-size: 38px !important; }

.fs-40 {
  font-size: 40px !important; }

.fs-42 {
  font-size: 42px !important; }

.fs-44 {
  font-size: 44px !important; }

.fs-46 {
  font-size: 46px !important; }

.fs-48 {
  font-size: 48px !important; }

.fs-50 {
  font-size: 50px !important; }

.fs-52 {
  font-size: 52px !important; }

.fs-54 {
  font-size: 54px !important; }

.fs-56 {
  font-size: 56px !important; }

.fs-58 {
  font-size: 58px !important; }

.fs-60 {
  font-size: 60px !important; }

.fs-62 {
  font-size: 62px !important; }

.fs-64 {
  font-size: 64px !important; }

.fs-66 {
  font-size: 66px !important; }

.fs-68 {
  font-size: 68px !important; }

.fs-70 {
  font-size: 70px !important; }

.fs-72 {
  font-size: 72px !important; }

.fs-74 {
  font-size: 74px !important; }

.fs-76 {
  font-size: 76px !important; }

.fs-78 {
  font-size: 78px !important; }

.fs-80 {
  font-size: 80px !important; }

.fs-100 {
  font-size: 100px !important; }

.fs-110 {
  font-size: 110px !important; }

.fs-120 {
  font-size: 120px !important; }

.fs-130 {
  font-size: 130px !important; }

.fs-140 {
  font-size: 140px !important; }

.fs-150 {
  font-size: 150px !important; }

.fs-160 {
  font-size: 160px !important; }

.fs-170 {
  font-size: 170px !important; }

.fs-180 {
  font-size: 180px !important; }

.fs-190 {
  font-size: 190px !important; }

.fs-200 {
  font-size: 200px !important; }

@media (min-width: 576px) {
  .fs-sm-10 {
    font-size: 10px !important; }
  .fs-sm-12 {
    font-size: 12px !important; }
  .fs-sm-14 {
    font-size: 14px !important; }
  .fs-sm-16 {
    font-size: 16px !important; }
  .fs-sm-18 {
    font-size: 18px !important; }
  .fs-sm-20 {
    font-size: 20px !important; }
  .fs-sm-22 {
    font-size: 22px !important; }
  .fs-sm-24 {
    font-size: 24px !important; }
  .fs-sm-26 {
    font-size: 26px !important; }
  .fs-sm-28 {
    font-size: 28px !important; }
  .fs-sm-30 {
    font-size: 30px !important; }
  .fs-sm-32 {
    font-size: 32px !important; }
  .fs-sm-34 {
    font-size: 34px !important; }
  .fs-sm-36 {
    font-size: 36px !important; }
  .fs-sm-38 {
    font-size: 38px !important; }
  .fs-sm-40 {
    font-size: 40px !important; }
  .fs-sm-42 {
    font-size: 42px !important; }
  .fs-sm-44 {
    font-size: 44px !important; }
  .fs-sm-46 {
    font-size: 46px !important; }
  .fs-sm-48 {
    font-size: 48px !important; }
  .fs-sm-50 {
    font-size: 50px !important; }
  .fs-sm-52 {
    font-size: 52px !important; }
  .fs-sm-54 {
    font-size: 54px !important; }
  .fs-sm-56 {
    font-size: 56px !important; }
  .fs-sm-58 {
    font-size: 58px !important; }
  .fs-sm-60 {
    font-size: 60px !important; }
  .fs-sm-62 {
    font-size: 62px !important; }
  .fs-sm-64 {
    font-size: 64px !important; }
  .fs-sm-66 {
    font-size: 66px !important; }
  .fs-sm-68 {
    font-size: 68px !important; }
  .fs-sm-70 {
    font-size: 70px !important; }
  .fs-sm-72 {
    font-size: 72px !important; }
  .fs-sm-74 {
    font-size: 74px !important; }
  .fs-sm-76 {
    font-size: 76px !important; }
  .fs-sm-78 {
    font-size: 78px !important; }
  .fs-sm-80 {
    font-size: 80px !important; }
  .fs-sm-100 {
    font-size: 100px !important; }
  .fs-sm-110 {
    font-size: 110px !important; }
  .fs-sm-120 {
    font-size: 120px !important; }
  .fs-sm-130 {
    font-size: 130px !important; }
  .fs-sm-140 {
    font-size: 140px !important; }
  .fs-sm-150 {
    font-size: 150px !important; }
  .fs-sm-160 {
    font-size: 160px !important; }
  .fs-sm-170 {
    font-size: 170px !important; }
  .fs-sm-180 {
    font-size: 180px !important; }
  .fs-sm-190 {
    font-size: 190px !important; }
  .fs-sm-200 {
    font-size: 200px !important; } }

@media (min-width: 768px) {
  .fs-md-10 {
    font-size: 10px !important; }
  .fs-md-12 {
    font-size: 12px !important; }
  .fs-md-14 {
    font-size: 14px !important; }
  .fs-md-16 {
    font-size: 16px !important; }
  .fs-md-18 {
    font-size: 18px !important; }
  .fs-md-20 {
    font-size: 20px !important; }
  .fs-md-22 {
    font-size: 22px !important; }
  .fs-md-24 {
    font-size: 24px !important; }
  .fs-md-26 {
    font-size: 26px !important; }
  .fs-md-28 {
    font-size: 28px !important; }
  .fs-md-30 {
    font-size: 30px !important; }
  .fs-md-32 {
    font-size: 32px !important; }
  .fs-md-34 {
    font-size: 34px !important; }
  .fs-md-36 {
    font-size: 36px !important; }
  .fs-md-38 {
    font-size: 38px !important; }
  .fs-md-40 {
    font-size: 40px !important; }
  .fs-md-42 {
    font-size: 42px !important; }
  .fs-md-44 {
    font-size: 44px !important; }
  .fs-md-46 {
    font-size: 46px !important; }
  .fs-md-48 {
    font-size: 48px !important; }
  .fs-md-50 {
    font-size: 50px !important; }
  .fs-md-52 {
    font-size: 52px !important; }
  .fs-md-54 {
    font-size: 54px !important; }
  .fs-md-56 {
    font-size: 56px !important; }
  .fs-md-58 {
    font-size: 58px !important; }
  .fs-md-60 {
    font-size: 60px !important; }
  .fs-md-62 {
    font-size: 62px !important; }
  .fs-md-64 {
    font-size: 64px !important; }
  .fs-md-66 {
    font-size: 66px !important; }
  .fs-md-68 {
    font-size: 68px !important; }
  .fs-md-70 {
    font-size: 70px !important; }
  .fs-md-72 {
    font-size: 72px !important; }
  .fs-md-74 {
    font-size: 74px !important; }
  .fs-md-76 {
    font-size: 76px !important; }
  .fs-md-78 {
    font-size: 78px !important; }
  .fs-md-80 {
    font-size: 80px !important; }
  .fs-md-100 {
    font-size: 100px !important; }
  .fs-md-110 {
    font-size: 110px !important; }
  .fs-md-120 {
    font-size: 120px !important; }
  .fs-md-130 {
    font-size: 130px !important; }
  .fs-md-140 {
    font-size: 140px !important; }
  .fs-md-150 {
    font-size: 150px !important; }
  .fs-md-160 {
    font-size: 160px !important; }
  .fs-md-170 {
    font-size: 170px !important; }
  .fs-md-180 {
    font-size: 180px !important; }
  .fs-md-190 {
    font-size: 190px !important; }
  .fs-md-200 {
    font-size: 200px !important; } }

@media (min-width: 992px) {
  .fs-lg-10 {
    font-size: 10px !important; }
  .fs-lg-12 {
    font-size: 12px !important; }
  .fs-lg-14 {
    font-size: 14px !important; }
  .fs-lg-16 {
    font-size: 16px !important; }
  .fs-lg-18 {
    font-size: 18px !important; }
  .fs-lg-20 {
    font-size: 20px !important; }
  .fs-lg-22 {
    font-size: 22px !important; }
  .fs-lg-24 {
    font-size: 24px !important; }
  .fs-lg-26 {
    font-size: 26px !important; }
  .fs-lg-28 {
    font-size: 28px !important; }
  .fs-lg-30 {
    font-size: 30px !important; }
  .fs-lg-32 {
    font-size: 32px !important; }
  .fs-lg-34 {
    font-size: 34px !important; }
  .fs-lg-36 {
    font-size: 36px !important; }
  .fs-lg-38 {
    font-size: 38px !important; }
  .fs-lg-40 {
    font-size: 40px !important; }
  .fs-lg-42 {
    font-size: 42px !important; }
  .fs-lg-44 {
    font-size: 44px !important; }
  .fs-lg-46 {
    font-size: 46px !important; }
  .fs-lg-48 {
    font-size: 48px !important; }
  .fs-lg-50 {
    font-size: 50px !important; }
  .fs-lg-52 {
    font-size: 52px !important; }
  .fs-lg-54 {
    font-size: 54px !important; }
  .fs-lg-56 {
    font-size: 56px !important; }
  .fs-lg-58 {
    font-size: 58px !important; }
  .fs-lg-60 {
    font-size: 60px !important; }
  .fs-lg-62 {
    font-size: 62px !important; }
  .fs-lg-64 {
    font-size: 64px !important; }
  .fs-lg-66 {
    font-size: 66px !important; }
  .fs-lg-68 {
    font-size: 68px !important; }
  .fs-lg-70 {
    font-size: 70px !important; }
  .fs-lg-72 {
    font-size: 72px !important; }
  .fs-lg-74 {
    font-size: 74px !important; }
  .fs-lg-76 {
    font-size: 76px !important; }
  .fs-lg-78 {
    font-size: 78px !important; }
  .fs-lg-80 {
    font-size: 80px !important; }
  .fs-lg-100 {
    font-size: 100px !important; }
  .fs-lg-110 {
    font-size: 110px !important; }
  .fs-lg-120 {
    font-size: 120px !important; }
  .fs-lg-130 {
    font-size: 130px !important; }
  .fs-lg-140 {
    font-size: 140px !important; }
  .fs-lg-150 {
    font-size: 150px !important; }
  .fs-lg-160 {
    font-size: 160px !important; }
  .fs-lg-170 {
    font-size: 170px !important; }
  .fs-lg-180 {
    font-size: 180px !important; }
  .fs-lg-190 {
    font-size: 190px !important; }
  .fs-lg-200 {
    font-size: 200px !important; } }

@media (min-width: 1200px) {
  .fs-xl-10 {
    font-size: 10px !important; }
  .fs-xl-12 {
    font-size: 12px !important; }
  .fs-xl-14 {
    font-size: 14px !important; }
  .fs-xl-16 {
    font-size: 16px !important; }
  .fs-xl-18 {
    font-size: 18px !important; }
  .fs-xl-20 {
    font-size: 20px !important; }
  .fs-xl-22 {
    font-size: 22px !important; }
  .fs-xl-24 {
    font-size: 24px !important; }
  .fs-xl-26 {
    font-size: 26px !important; }
  .fs-xl-28 {
    font-size: 28px !important; }
  .fs-xl-30 {
    font-size: 30px !important; }
  .fs-xl-32 {
    font-size: 32px !important; }
  .fs-xl-34 {
    font-size: 34px !important; }
  .fs-xl-36 {
    font-size: 36px !important; }
  .fs-xl-38 {
    font-size: 38px !important; }
  .fs-xl-40 {
    font-size: 40px !important; }
  .fs-xl-42 {
    font-size: 42px !important; }
  .fs-xl-44 {
    font-size: 44px !important; }
  .fs-xl-46 {
    font-size: 46px !important; }
  .fs-xl-48 {
    font-size: 48px !important; }
  .fs-xl-50 {
    font-size: 50px !important; }
  .fs-xl-52 {
    font-size: 52px !important; }
  .fs-xl-54 {
    font-size: 54px !important; }
  .fs-xl-56 {
    font-size: 56px !important; }
  .fs-xl-58 {
    font-size: 58px !important; }
  .fs-xl-60 {
    font-size: 60px !important; }
  .fs-xl-62 {
    font-size: 62px !important; }
  .fs-xl-64 {
    font-size: 64px !important; }
  .fs-xl-66 {
    font-size: 66px !important; }
  .fs-xl-68 {
    font-size: 68px !important; }
  .fs-xl-70 {
    font-size: 70px !important; }
  .fs-xl-72 {
    font-size: 72px !important; }
  .fs-xl-74 {
    font-size: 74px !important; }
  .fs-xl-76 {
    font-size: 76px !important; }
  .fs-xl-78 {
    font-size: 78px !important; }
  .fs-xl-80 {
    font-size: 80px !important; }
  .fs-xl-100 {
    font-size: 100px !important; }
  .fs-xl-110 {
    font-size: 110px !important; }
  .fs-xl-120 {
    font-size: 120px !important; }
  .fs-xl-130 {
    font-size: 130px !important; }
  .fs-xl-140 {
    font-size: 140px !important; }
  .fs-xl-150 {
    font-size: 150px !important; }
  .fs-xl-160 {
    font-size: 160px !important; }
  .fs-xl-170 {
    font-size: 170px !important; }
  .fs-xl-180 {
    font-size: 180px !important; }
  .fs-xl-190 {
    font-size: 190px !important; }
  .fs-xl-200 {
    font-size: 200px !important; } }

.ff-noto-sans-jp {
  font-family: 'Noto Sans Japanese', sans-serif; }

.w100 {
  font-weight: 100; }

.w200 {
  font-weight: 200; }

.w300 {
  font-weight: 300; }

.w400 {
  font-weight: 400; }

.w500 {
  font-weight: 500; }

.w700 {
  font-weight: 700; }

.w900 {
  font-weight: 900; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }
